import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { generatePath } from 'react-router';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import arrayAdapter from './array-adapter';
import facilityAdapter from './facility-adapter';
import routePlanAdapter from './route-plan-adapter';
import routeAdapter from './route-adapter';
import routeFrequencyAdapter from './route-frequency-adapter';
import routeChangelogAdapter from './route-changelog-adapter';
import recurrenceAdapter from './recurrence-adapter';

const TRANSPORT_PLANNER_API_TYPES = {
  vehiclesTypes: {
    path: '/vehicles/types',
    adapter: arrayAdapter
  },
  facilities: {
    path: '/facilities',
    adapter: facilityAdapter
  },
  transshipmentDestinations: {
    path: '/facilities/:facilityId/transshipmentDestinations',
    adapter: facilityAdapter
  },
  route: {
    path: '/route-plans/:routePlanId/routes',
    adapter: routeAdapter
  },
  calculate: {
    path: '/route-plans/:routePlanId/routes\\:calculate',
    adapter: routeAdapter
  },
  cancel: {
    path: '/route-plans/:routePlanId/routes/:routeId\\:cancel',
    adapter: routeAdapter
  },
  publish: {
    path: '/route-plans/:routePlanId\\:schedulePublication',
    adapter: routePlanAdapter
  },
  declare: {
    path: '/route-plans/:routePlanId\\:declare',
    adapter: routePlanAdapter
  },
  'pickup-plans': {
    path: '/route-plans',
    adapter: routePlanAdapter
  },
  'transfer-plans': {
    path: '/route-plans',
    adapter: routePlanAdapter
  },
  frequencies: {
    path: '/frequencies',
    adapter: routeFrequencyAdapter
  },
  changelog: {
    path: '/route-plans/routes/:routeId/changelogs',
    adapter: routeChangelogAdapter
  },
  similarRoutes: {
    path: '/route-plans/:routePlanId/routes/:routeId/similarRoutes',
    adapter: routeAdapter
  },
  recurrences: {
    path: '/recurrence-schedules',
    adapter: recurrenceAdapter
  }
};

const TRANSPORT_PLANNER_ENDPOINT = `${
  process.env.REACT_APP_TRANSPORT_PLANNER_URL
}/v1`;

const httpClient = async (url, options = {}) => {
  const {
    idToken: { jwtToken }
  } = await Auth.currentSession();

  const defaultHeaders = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${jwtToken}`
  });
  const headers = options.headers || defaultHeaders;
  return fetchUtils.fetchJson(url, { headers, ...options }).catch(error => {
    Sentry.captureException(error);
    if (!error.status) {
      return Promise.reject(new Error('ra.message.try_again'));
    }

    if (error.body?.details) {
      const errors = error.body.details[0].fieldViolations.map(
        value => value.description
      );
      return Promise.reject(new Error(errors));
    }

    return Promise.reject(new Error(error.message));
  });
};

function getUrl(resource, params = {}) {
  if (resource in TRANSPORT_PLANNER_API_TYPES) {
    return `${TRANSPORT_PLANNER_ENDPOINT}${generatePath(
      TRANSPORT_PLANNER_API_TYPES[resource].path,
      params.data
    )}`;
  }

  return `${TRANSPORT_PLANNER_ENDPOINT}/${resource}`;
}

function parseDataFromServer(resource, jsonData, resourceId) {
  const dataTemp = Object.values(jsonData);
  const rawData = dataTemp[dataTemp.length - 1];

  if (!rawData) {
    return { data: [], total: 0 };
  }

  const data = resourceId ? { ...rawData, id: resourceId } : rawData;
  const total = data.length;

  if (TRANSPORT_PLANNER_API_TYPES[resource]) {
    return {
      data: TRANSPORT_PLANNER_API_TYPES[resource].adapter.fromServer(data),
      total
    };
  }

  return {
    data,
    total
  };
}

const formatDataToServer = (resource, data) =>
  JSON.stringify(TRANSPORT_PLANNER_API_TYPES[resource].adapter.toServer(data));

export default {
  ...simpleRestProvider(TRANSPORT_PLANNER_ENDPOINT),

  // FUTURE TASK: This part will be refactored to use custom queries
  getOne: (resource, params) => {
    if (params.id.indexOf('%') > -1) {
      const ids = params.id.split('%');
      const editedParams = { data: { routePlanId: ids[0] } };
      const query = { includeLoadingOrder: true };
      const newUrl = `${getUrl(resource, editedParams)}/${ids[1]}?${stringify(
        query
      )}`;
      return httpClient(newUrl).then(({ json }) =>
        parseDataFromServer(resource, json, params.id)
      );
    }
    let url = `${getUrl(resource)}/${params.id}`;
    if (['pickup-plans', 'transfer-plans'].includes(resource))
      url = `${getUrl(resource)}/${params.id}?includeRoutes=false`;

    return httpClient(url).then(({ json }) =>
      parseDataFromServer(resource, json)
    );
  },

  getList: (resource, params) => {
    const query = {
      ...params.filter
    };
    const url = `${getUrl(resource)}?${stringify(query)}`;
    return httpClient(url).then(({ json }) =>
      parseDataFromServer(resource, json)
    );
  },

  getMany: (resource, params) => {
    const query = {
      ids: params.ids
    };
    const url = `${getUrl(resource)}?${stringify(query)}`;
    return httpClient(url).then(({ json }) =>
      parseDataFromServer(resource, json)
    );
  },
  update: (resource, params) => {
    const url = `${getUrl(resource, params)}/${params.data.id}`;
    return httpClient(url, {
      method: 'PUT',
      body: formatDataToServer(resource, params.data)
    }).then(({ json }) => ({
      ...parseDataFromServer(resource, json),
      id: json.id
    }));
  },
  create: (resource, params) => {
    const url = getUrl(resource, params);
    return httpClient(url, {
      method: 'POST',
      body: formatDataToServer(resource, params.data)
    }).then(({ json }) => ({
      ...parseDataFromServer(resource, json),
      id: json.id
    }));
  },
  calculate: params => {
    const resource = 'calculate';
    const url = `${getUrl(resource, params)}`;

    return httpClient(url, {
      method: 'POST',
      body: formatDataToServer(resource, params.data)
    }).then(({ json }) => parseDataFromServer(resource, json));
  },
  cancel: (resource, params) => {
    const url = `${getUrl('cancel', params)}`;
    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data)
    }).then(() => ({ data: {} }));
  },
  archiveMany: (resource, params) => {
    const url = `${getUrl(resource, params)}`;
    const { archive, routePlanId, ...payload } = params.data;
    const action = archive ? 'archive' : 'unarchive';
    return httpClient(`${url}:${action}`, {
      method: 'POST',
      body: JSON.stringify(payload)
    }).then(() => ({ data: {} }));
  },
  publish: (resource, params) => {
    const url = `${getUrl('publish', params)}`;

    return httpClient(url, {
      method: 'POST'
    }).then(() => ({ data: {} }));
  },
  declare: (resource, params) => {
    const url = `${getUrl('declare', params)}`;

    return httpClient(url, {
      method: 'POST'
    }).then(() => ({ data: {} }));
  },
  deleteMany: (resource, params) => {
    const url = `${getUrl(resource, params)}`;
    const { routePlanId, ...payload } = params.data;

    return httpClient(url, {
      method: 'DELETE',
      body: JSON.stringify(payload)
    }).then(() => ({ data: payload.routeIds }));
  },
  duplicateMany: (resource, params) => {
    const url = `${getUrl(resource, params)}`;
    const { routePlanId, ...payload } = params.data;
    return httpClient(`${url}:duplicate`, {
      method: 'POST',
      body: JSON.stringify(payload)
    }).then(() => ({ data: {} }));
  },
  getRoutes: (resource, params) => {
    const query = {
      ...params.filter
    };
    const url = `${getUrl(resource, params)}?${stringify(query, {
      arrayFormat: 'comma',
      skipNull: true,
      skipEmptyString: true
    })}`;
    return httpClient(url).then(({ json }) => {
      return parseDataFromServer(resource, [json.routes]);
    });
  },
  routeChangelog: (resource, params) => {
    const url = getUrl(resource, params);
    return httpClient(url).then(({ json }) => {
      return parseDataFromServer(resource, [json.changelogs]);
    });
  },
  updateRouteSingleField: (resource, params) => {
    const url = `${getUrl(resource, params)}`;
    const { routePlanId, routeId, field, ...payload } = params.data;
    return httpClient(`${url}/${routeId}/${field}`, {
      method: 'PUT',
      body: JSON.stringify(payload)
    }).then(() => ({ data: {} }));
  },
  getTransshipmentDestinations: (resource, params) => {
    const url = getUrl(resource, params);
    return httpClient(url).then(({ json }) => {
      return parseDataFromServer(resource, json);
    });
  }
};
