/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import style from '../../style';

const RecurrenceList = props => {
  return (
    <div {...props} style={style.tabBoxContent} data-testid="recurrence-list" />
  );
};

export default RecurrenceList;
