/* eslint-disable react/jsx-props-no-spreading, react/forbid-prop-types */
import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Draggable } from 'react-beautiful-dnd';
import { FieldTitle } from 'ra-core';
import FormInput from 'ra-ui-materialui/lib/form/FormInput';

import { useStyles } from './styles';

const DraggableFormInput = ({
  children,
  id,
  index,
  member,
  onRemove,
  record,
  resource
}) => {
  const classes = useStyles();

  return (
    <Draggable draggableId={id} index={index}>
      {provided => (
        <li
          className={classes.line}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className={classes.lineMainContent}>
            <DragIndicatorIcon className={classes.index} />
            <section>
              <div>
                {Children.map(children, (inputTemplate, inputTemplateIndex) => {
                  const { source, ...inputProps } = inputTemplate.props;
                  return (
                    <FormInput
                      input={cloneElement(inputTemplate, {
                        source: `${member}.${inputTemplate.props.source}`,
                        index: inputTemplateIndex,
                        label: (
                          <FieldTitle
                            label={inputTemplate.props.label}
                            source={inputTemplate.props.source}
                            resource={resource}
                          />
                        ),
                        ...inputProps
                      })}
                      key={`${inputTemplate.props.source}-${id}`}
                      record={record}
                      resource={resource}
                      variant="outlined"
                    />
                  );
                })}
              </div>
            </section>
            <span className={classes.action}>
              <Button
                size="small"
                onClick={onRemove(index)}
                data-testid="draggrable-form-iterator-remove-button"
              >
                <CancelRoundedIcon className={classes.leftIcon} />
              </Button>
            </span>
          </div>
        </li>
      )}
    </Draggable>
  );
};

DraggableFormInput.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  member: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired
};

export default DraggableFormInput;
