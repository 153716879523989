import React from 'react';
import { useTranslate } from 'react-admin';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useChangelogStyles } from '../../styles';
import ItemChanged from './route-form-changelog-item-change';

const ItemsChangedList = ({
  changeMap,
  keyNames,
  translatePath,
  translateFieldsPath
}) => {
  const translate = useTranslate();
  const styles = useChangelogStyles();

  /*
    For each key, group all changes and show them as one ItemChanged.
    Some keys contains just one item, but others may contain many items
  */

  return keyNames.map(key => {
    const change = changeMap.filter(item => item.key === key);
    return (
      <Box data-testid="itemsChangedListKey">
        <span className={styles.detailType} data-testid="itemChangedTitle">
          {translate(`${translateFieldsPath}.${key}`)}
        </span>
        {change.map(item => (
          <ItemChanged change={item} translatePath={translatePath} />
        ))}
      </Box>
    );
  });
};

ItemsChangedList.propTypes = {
  changeMap: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      before: PropTypes.string,
      after: PropTypes.string,
      isTransfer: PropTypes.bool,
      isDateTimeLocalSpanNeeded: PropTypes.bool
    }).isRequired
  ).isRequired,
  keyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  translatePath: PropTypes.string.isRequired,
  translateFieldsPath: PropTypes.string.isRequired
};

export default ItemsChangedList;
