import { useTranslate } from 'react-admin';
import React, { useMemo } from 'react';
import { Box, Button, Toolbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { useRemoteConfig } from '@loggi/components/src/one/remote-config';
import ActionFilters from './action-filters';
import { bigAlertStyles, routePlanShowStyles } from '../styles';
import { defaultRouteFilters, recordPropType } from '../constants';
import {
  hasDeclarePermission,
  hasPublishPermission,
  hasWritePermission
} from '../../../../../config/auth-provider';
import ArchiveRouteButton from '../../../../atoms/archive-route-button';
import CloneRouteButton from '../../../../atoms/clone-route-button';
import DeleteRouteButton from '../../../../atoms/delete-route-button';
import PublishRoutePlanButton from '../../../../molecules/route-plan/publish-route-plan-button';
import CancelRouteButton from '../../../../atoms/cancel-route-button';
import { globalStyles } from '../../../../theme/transport-planner-theme';

const AddRouteButton = ({ record, resource }) => {
  const translate = useTranslate();
  const projectStyles = globalStyles();

  return (
    <Button
      to={{
        pathname: `/${resource}/${record.id}/routes/create`,
        search: `?source=${JSON.stringify({
          routePlanDate: record.formatRoutePlanDate('YYYY-MM-DD')
        })}&allowPickups=${record.allowPickups}&requiresJustification=${
          record.requiresJustification
        }`
      }}
      component={Link}
      color="primary"
      variant="outlined"
      size="medium"
      startIcon={<AddIcon />}
      className={projectStyles.addButtonWithIcon}
    >
      {translate('resources.route-plans.add_route')}
    </Button>
  );
};

AddRouteButton.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    formatRoutePlanDate: PropTypes.func,
    allowPickups: PropTypes.bool,
    requiresJustification: PropTypes.bool.isRequired
  }).isRequired,
  resource: PropTypes.string.isRequired
};

const ReviewAlert = ({ totalRoutesPendingReview, onClickReview }) => {
  const alertBoxClass = bigAlertStyles();
  const styles = routePlanShowStyles();
  const translate = useTranslate();

  return (
    <Alert classes={{ ...alertBoxClass }} severity="warning">
      <Typography bold className={styles.reviewAlert} component="div">
        <Box>
          {translate('ra.message.routes_to_be_reviewed', {
            smart_count: totalRoutesPendingReview
          })}
        </Box>
        <Box>&nbsp;</Box>
        <Box>{`${translate('ra.message.routes_had_changes', {
          smart_count: totalRoutesPendingReview
        })}`}</Box>
        <Box>&nbsp;</Box>
        <button className={styles.link} type="button" onClick={onClickReview}>
          {translate('ra.message.review', {
            smart_count: totalRoutesPendingReview
          })}
        </button>
      </Typography>
    </Alert>
  );
};

ReviewAlert.propTypes = {
  totalRoutesPendingReview: PropTypes.number.isRequired,
  onClickReview: PropTypes.func.isRequired
};

const CustomToolbar = ({
  changeFilter,
  clearFilters,
  filters,
  record,
  selectedRoute,
  resource,
  isArchivable,
  refetchData,
  userPermissions,
  closeFilter,
  onClickReview,
  routes
}) => {
  const styles = routePlanShowStyles();

  const { value: fsValue } = useRemoteConfig('enable_declare_scheduling');
  const enableDeclareScheduling = JSON.parse(fsValue);

  const { showPublishButton, isDeclareBehavior } = useMemo(() => {
    if (enableDeclareScheduling === true) {
      return {
        showPublishButton:
          (hasPublishPermission(userPermissions) &&
            record.declared &&
            record.hasChangesUnscheduledForPublication) ||
          (hasDeclarePermission(userPermissions) && !record.declared),
        isDeclareBehavior: !record.declared
      };
    }

    return {
      showPublishButton: hasPublishPermission(userPermissions),
      isDeclareBehavior: false
    };
  }, [record, userPermissions, enableDeclareScheduling]);

  return (
    <Toolbar disableGutters>
      <Box className={styles.routeShowToolbar}>
        {record.totalRoutesPendingReview > 0 && (
          <ReviewAlert
            totalRoutesPendingReview={record.totalRoutesPendingReview}
            onClickReview={onClickReview}
          />
        )}
        {record.editable && (
          <Box display="flex" flexDirection="row">
            {hasWritePermission(userPermissions) && (
              <Box>
                <AddRouteButton record={record} resource={resource} />
                <ArchiveRouteButton
                  selectedRoutes={selectedRoute}
                  routePlanId={record.id}
                  isArchivable={isArchivable}
                  archiveDisabled={
                    selectedRoute.some(
                      routeId => !routes.find(r => r.id === routeId).archivable
                    ) && isArchivable
                  }
                  onSuccess={refetchData}
                  showButtonIcon
                  requiresJustification={record.requiresJustification}
                />
                <CloneRouteButton
                  selectedRoutes={selectedRoute}
                  routePlanId={record.id}
                  active={record.active}
                  allowPickups={record.allowPickups}
                  requiresJustification={record.requiresJustification}
                  resource={resource}
                  onSuccess={refetchData}
                  routes={routes}
                  routePlanDate={record.formatRoutePlanDate('YYYY-MM-DD')}
                />
                {record.cancelable && (
                  <CancelRouteButton
                    disabled={
                      selectedRoute.length !== 1 ||
                      routes.some(
                        route =>
                          route.id === selectedRoute[0] && !route.cancelable
                      )
                    }
                    parentResource={resource}
                    routePlanId={record.id}
                    routeId={selectedRoute[0]}
                    onSuccess={refetchData}
                    label="resources.route-plans.cancel_route"
                  />
                )}
                {record.deletable && (
                  <DeleteRouteButton
                    selectedRoutes={selectedRoute}
                    routePlanId={record.id}
                    resource={resource}
                    onSuccess={refetchData}
                  />
                )}
              </Box>
            )}
            {showPublishButton && (
              <Box marginLeft="auto">
                <PublishRoutePlanButton
                  record={record}
                  onClickReview={onClickReview}
                  isDeclareBehavior={isDeclareBehavior}
                  onSuccess={refetchData}
                />
              </Box>
            )}
          </Box>
        )}
        <ActionFilters
          changeFilter={changeFilter}
          clearFilters={clearFilters}
          closeFilter={closeFilter}
          filters={filters}
          record={record}
        />
      </Box>
    </Toolbar>
  );
};

CustomToolbar.propTypes = {
  changeFilter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    arrivalWeekdayName: PropTypes.arrayOf(PropTypes.number).isRequired,
    origin: PropTypes.arrayOf(PropTypes.string),
    transfer: PropTypes.arrayOf(PropTypes.string),
    frequency: PropTypes.arrayOf(PropTypes.string),
    carrier: PropTypes.string,
    returnRoute: PropTypes.string,
    state: PropTypes.arrayOf(PropTypes.string)
  }),
  record: recordPropType.isRequired,
  selectedRoute: PropTypes.arrayOf(PropTypes.string).isRequired,
  resource: PropTypes.string.isRequired,
  isArchivable: PropTypes.bool.isRequired,
  refetchData: PropTypes.func.isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  closeFilter: PropTypes.func.isRequired,
  onClickReview: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      cancelable: PropTypes.bool.isRequired
    })
  ).isRequired
};

CustomToolbar.defaultProps = {
  filters: {
    defaultRouteFilters
  }
};

export default CustomToolbar;
