/* eslint-disable react/forbid-prop-types, react/jsx-props-no-spreading */
import React from 'react';
import { useQueryWithStore, Loading, Error } from 'react-admin';
import PropTypes from 'prop-types';
import AutocompleteInput from '../autocomplete-input';

const AutocompleteInputRemote = ({ reference, filterValues, ...props }) => {
  const payload = { filter: filterValues };
  const { loaded, error: queryError, data } = useQueryWithStore({
    type: 'getList',
    resource: reference,
    payload
  });

  if (!loaded) return <Loading />;
  if (queryError) return <Error />;

  return <AutocompleteInput options={data} reference={reference} {...props} />;
};

AutocompleteInputRemote.propTypes = {
  source: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  variant: PropTypes.string,
  optionText: PropTypes.func,
  optionValue: PropTypes.string,
  filterValues: PropTypes.object,
  defaultValue: PropTypes.string,
  onSelectedValue: PropTypes.func,
  suggestionLimit: PropTypes.number,
  validationError: PropTypes.string
};

AutocompleteInputRemote.defaultProps = {
  optionText: choice => choice.name,
  optionValue: 'id',
  filterValues: {},
  defaultValue: '',
  variant: 'outlined',
  suggestionLimit: undefined,
  validationError: 'ra.validation.required',
  onSelectedValue: undefined
};

export default AutocompleteInputRemote;
