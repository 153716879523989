import { convertColorProtoToRGB } from '../utils/color-format';

const convertFromServer = frequency => {
  return frequency
    ? {
        ...frequency,
        fontColor: convertColorProtoToRGB(frequency.fontColor),
        backgroundColor: convertColorProtoToRGB(frequency.backgroundColor)
      }
    : undefined;
};

export default {
  fromServer: data =>
    Array.isArray(data)
      ? [...new Set(data?.map(frequency => convertFromServer(frequency)))]
      : convertFromServer(data),
  toServer: frequency => (frequency ? { id: frequency.id } : null)
};
