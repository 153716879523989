import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const CurrencyFormatInput = props => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      getInputRef={inputRef}
      data-testid="currency-format-input"
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      isNumericString
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
      prefix="R$"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
};

CurrencyFormatInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CurrencyFormatInput;
