import React from 'react';
import moment from 'moment';
import { Box, Chip, Tooltip } from '@material-ui/core';
import StateDot from '../../../../../atoms/state-dot';

const getHeaderData = translate => {
  const renderCellWithTooltip = (label, tooltip) => (
    <Tooltip title={tooltip}>
      <span>{label}</span>
    </Tooltip>
  );

  const renderCellWithBreakLine = (firstLine, secondLine) => (
    <Box display="flex" flexDirection="column" lineHeight="18px">
      <Box>{firstLine}</Box>
      <Box fontSize={12}>{secondLine}</Box>
    </Box>
  );

  const renderCellWithTag = value =>
    value && (
      <Chip
        style={{
          backgroundColor: value.backgroundColor,
          color: value.fontColor
        }}
        label={value.name}
        size="small"
      />
    );

  return [
    {
      field: 'externalId',
      headerName: translate('resources.route-plans.fields.routeId'),
      headerAlign: 'left',
      flex: 0.75,
      align: 'left',
      renderCell: ({ row }) => {
        return row.externalId;
      }
    },
    {
      field: 'arrivalWeekdayName',
      headerName: translate('resources.route-plans.fields.originArrivalInfo'),
      headerAlign: 'left',
      flex: 0.75,
      align: 'left',
      renderCell: ({ row }) => {
        const { arrivalWeekdayName, originArrivalTimeHourFormatted } = row;
        return `${arrivalWeekdayName} ${originArrivalTimeHourFormatted}`;
      },
      sortComparator: (v1, v2, param1, param2) =>
        moment(param1.api.getCellValue(param1.id, 'arrivalTime')).diff(
          param2.api.getCellValue(param2.id, 'arrivalTime'),
          'minutes'
        )
    },
    {
      field: 'departureWeekdayName',
      headerName: translate('resources.route-plans.fields.originDepartureInfo'),
      headerAlign: 'left',
      flex: 0.75,
      align: 'left',
      renderCell: ({ row }) => {
        const { departureWeekdayName, originDepartureTimeHourFormatted } = row;
        return `${departureWeekdayName} ${originDepartureTimeHourFormatted}`;
      },
      sortComparator: (v1, v2, param1, param2) =>
        moment(param1.api.getCellValue(param1.id, 'departureTime')).diff(
          param2.api.getCellValue(param2.id, 'departureTime'),
          'minutes'
        )
    },
    {
      field: 'origin',
      headerName: translate('resources.route-plans.fields.origin'),
      headerAlign: 'left',
      flex: 0.6,
      align: 'left',
      renderCell: ({ value }) => {
        const originName = value.name;
        const routingCode = value.shortName;
        return renderCellWithTooltip(routingCode, originName);
      },
      sortComparator: (v1, v2, param1, param2) =>
        param1.api
          .getCellValue(param1.id, 'origin')
          .routingCode.localeCompare(
            param2.api.getCellValue(param2.id, 'origin').routingCode
          )
    },
    {
      field: 'transfers',
      headerName: translate('resources.route-plans.fields.transfers'),
      headerAlign: 'left',
      flex: 0.7,
      align: 'left',
      renderCell: ({ value }) => {
        const destinationNames = value
          .map(({ destination }) => destination.name)
          .join(' > ');
        const destinationCodes = value
          .map(({ destination }) => destination.shortName)
          .join(' > ');
        return renderCellWithTooltip(destinationCodes, destinationNames);
      },
      sortComparator: (v1, v2, param1, param2) => {
        const destinationNames1 = param1.api
          .getCellValue(param1.id, 'transfers')
          .map(({ destination }) => {
            return destination.routingCode;
          })
          .join(' > ');
        const destinationNames2 = param2.api
          .getCellValue(param2.id, 'transfers')
          .map(({ destination }) => destination.routingCode)
          .join(' > ');
        return destinationNames1.localeCompare(destinationNames2);
      }
    },
    {
      field: 'routeDurationHuman',
      hide: true,
      headerName: translate('resources.route-plans.fields.routeDuration'),
      headerAlign: 'left',
      flex: 0.8,
      align: 'left',
      sortComparator: (v1, v2, param1, param2) => {
        const time1 = parseInt(
          param1.api.getCellValue(param1.id, 'routeDuration').slice(0, -1),
          10
        );
        const time2 = parseInt(
          param2.api.getCellValue(param2.id, 'routeDuration').slice(0, -1),
          10
        );
        return time2 - time1;
      }
    },
    {
      field: 'vehicleType',
      headerName: translate('resources.route-plans.fields.vehicleType'),
      headerAlign: 'left',
      flex: 0.8,
      align: 'left',
      renderCell: ({ row }) => {
        return renderCellWithBreakLine(row.vehicleType, row.carrierName);
      }
    },
    {
      field: 'merchandiseValue',
      hide: true,
      headerName: translate('resources.route-plans.fields.merchandiseValue'),
      headerAlign: 'left',
      flex: 1,
      align: 'left',
      renderCell: ({ row }) => {
        return Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(row.merchandiseValue);
      },
      sortComparator: (v1, v2, param1, param2) => {
        const value1 = param1.api.getCellValue(param1.id, 'merchandiseValue');
        const value2 = param2.api.getCellValue(param2.id, 'merchandiseValue');
        return value2 - value1;
      }
    },
    {
      field: 'lastModifiedDateFormatted',
      hide: true,
      headerName: translate('resources.route-plans.fields.lastModifiedTime'),
      headerAlign: 'left',
      flex: 1,
      align: 'left',
      renderCell: ({ row }) => {
        return renderCellWithBreakLine(
          row.lastModifiedDateFormatted,
          row.lastModifiedByUser
        );
      },
      sortComparator: (v1, v2, param1, param2) =>
        moment(param1.api.getCellValue(param1.id, 'lastModifiedTime')).diff(
          param2.api.getCellValue(param2.id, 'lastModifiedTime'),
          'minutes'
        )
    },
    {
      field: 'state',
      headerName: translate('resources.route-plans.fields.state'),
      headerAlign: 'left',
      flex: 0.6,
      align: 'left',
      // eslint-disable-next-line react/prop-types
      renderCell: ({ value }) => <StateDot state={value} />
    },
    {
      field: 'frequency',
      headerName: translate('resources.route-plans.fields.frequency'),
      headerAlign: 'left',
      flex: 0.6,
      align: 'left',
      renderCell: ({ value }) => {
        return renderCellWithTag(value);
      }
    }
  ];
};

export default getHeaderData;
