module.exports = {
  ra: {
    action: {
      add_filter: 'Adicionar Filtro',
      add: 'Adicionar',
      back: 'Voltar',
      back_to_list: 'Voltar à programação',
      bulk_actions: '1 item selecionado |||| %{smart_count} itens selecionados',
      cancel: 'Cancelar',
      clear: 'Limpar',
      clear_input_value: 'Limpar campo',
      clone: 'Duplicar',
      confirm: 'Confirmar',
      create: 'Novo',
      delete: 'Deletar',
      edit: 'Editar',
      export: 'Exportar',
      keep: 'Manter',
      list: 'Lista de ',
      refresh: 'Atualizar',
      remove_filter: 'Cancelar filtro',
      remove: 'Excluir',
      save: 'Salvar',
      save_and_inclued: 'Salvar & incluir na programação',
      not_save_modifications: 'Não salvar as alterações e voltar',
      search: 'Buscar',
      show: 'Exibir',
      sort: 'Ordenar',
      undo: 'Desfazer',
      unselect: 'Desmarcar',
      expand: 'Expandir',
      archive: 'Arquivar',
      close: 'Fechar',
      open_menu: 'Abrir menu',
      close_menu: 'Fechar menu',
      change: 'Alterar',
      other_actions: 'Outras ações'
    },
    boolean: {
      true: 'Sim',
      false: 'Não',
      null: ''
    },
    page: {
      create: 'Novo %{name}',
      dashboard: 'Painel de Controle',
      edit: '%{name} #%{id}',
      error: 'Um erro ocorreu',
      list: 'Lista de %{name}',
      loading: 'Carregando',
      not_found: 'Não encontrado',
      show: '%{name} #%{id}',
      empty: 'Ainda não há nenhum registro em %{name}',
      invite: 'Gostaria de criar um novo?'
    },
    input: {
      file: {
        upload_several:
          'Arraste alguns arquivos para fazer o upload, ou clique para selecioná-los.',
        upload_single:
          'Arraste o arquivo para fazer o upload, ou clique para selecioná-lo.'
      },
      image: {
        upload_several:
          'Arraste algumas imagens para fazer o upload ou clique para selecioná-las',
        upload_single:
          'Arraste um arquivo para upload ou clique em selecionar arquivo.'
      },
      references: {
        all_missing: 'Não foi possível encontrar os dados das referencias.',
        many_missing:
          'Pelo menos uma das referências passadas não está mais disponível.',
        single_missing:
          'A referência passada aparenta não estar mais disponível.'
      },
      password: {
        toggle_visible: 'Esconder senha',
        toggle_hidden: 'Mostrar senha'
      }
    },
    message: {
      about: 'Sobre',
      are_you_sure: 'Tem certeza?',
      bulk_delete_content:
        'Você tem certeza que deseja excluir %{name}? |||| Você tem certeza que deseja excluir estes %{smart_count} itens?',
      bulk_delete_title:
        'Excluir %{name} |||| Excluir %{smart_count} %{name} itens',
      delete_content:
        'Apenas serão apagados planos de viagem planejados ou arquivados',
      delete_title:
        'Deseja realmente apagar esse Plano de Viagem? |||| Deseja realmente apagar %{smart_count} Planos de viagem?',
      archive_title:
        'Deseja realmente arquivar esse Plano de Viagem? |||| Deseja realmente arquivar %{smart_count} Planos de viagem?',
      unarchive_title:
        'Deseja realmente desarquivar esse Plano de Viagem? |||| Deseja realmente desarquivar %{smart_count} Planos de viagem?',
      details: 'Detalhes',
      error: 'Um erro ocorreu e a sua requisição não pôde ser completada.',
      invalid_form:
        'Este formulário não está valido. Certifique-se de corrigir os erros',
      loading: 'A página está carregando. Um momento, por favor',
      no: 'Não',
      not_found:
        'Foi digitada uma URL inválida, ou o link pode estar quebrado.',
      yes: 'Sim',
      unsaved_changes:
        'Algumas das suas mudanças não foram salvas, deseja realmente ignorá-las?',
      try_again: 'Tente novamente mais tarde',
      unpublished_changes: 'Existem alterações não publicadas',
      routes_to_be_reviewed:
        '%{smart_count} plano de viagem |||| %{smart_count} planos de viagem',
      routes_had_changes:
        'foi alterado na programação anterior. |||| foram alterados na programação anterior.',
      review: 'Revisar',
      loading_transshipment_list: 'Carregando lista de transbordos',
      not_able_to_load_transshipment_list:
        'Não foi possível carregar a lista de transbordos. Por favor, atualize a página.'
    },
    navigation: {
      no_results: 'Nenhum resultado encontrado',
      no_more_results:
        'A página numero %{page} está fora dos limites. Tente a página anterior.',
      page_out_of_boundaries: 'Página %{page} fora do limite',
      page_out_from_end: 'Não é possível ir após a última página',
      page_out_from_begin: 'Não é possível ir antes da primeira página',
      page_range_info: '%{offsetBegin}-%{offsetEnd} de %{total}',
      page_rows_per_page: 'Resultados por página:',
      next: 'Próximo',
      prev: 'Anterior',
      skip_nav: 'Ir para o conteúdo',
      back_to_recurrences_list: 'Voltar para Programações Recorrentes'
    },
    auth: {
      auth_check_error: 'Por favor, faça login para continuar',
      user_menu: 'Perfil',
      sign_in: 'Entrar',
      sign_in_error: 'Erro na autenticação, tente novamente.',
      logout: 'Sair'
    },
    notification: {
      updated:
        'Item atualizado com sucesso |||| %{smart_count} itens foram atualizados com sucesso',
      created: 'Item criado com sucesso',
      deleted:
        'Item removido com sucesso! |||| %{smart_count} itens foram removidos com sucesso',
      bad_item: 'Item incorreto',
      item_doesnt_exist: 'Esse item não existe mais',
      http_error: 'Erro na comunicação com servidor',
      data_provider_error: 'Erro interno do servidor. Entre em contato',
      i18n_error:
        'Não foi possível carregar as traduções para o idioma especificado',
      canceled: 'Ação cancelada',
      logged_out: 'Sua sessão foi encerrada. Por favor, reconecte',
      included_in_schedule: 'O plano de viagem foi incluído na programação'
    },
    validation: {
      required: 'Obrigatório',
      minLength: 'Deve ser ter no mínimo %{min} caracteres',
      maxLength: 'Deve ter no máximo %{max} caracteres',
      minValue: 'Deve ser %{min} ou maior',
      maxValue: 'Deve ser %{max} ou menor',
      number: 'Deve ser um número',
      email: 'Deve ser um email válido',
      oneOf: 'Deve ser uma das seguintes opções: %{options}',
      regex: 'Deve ter o formato específico (regexp): %{pattern}',
      selectReason: 'Opa, você precisa selecionar um motivo.',
      selectDay: 'Opa, você precisa selecionar um dia da semana.',
      selectValidTravelPlans:
        'Escolha um dia igual ou posterior à data do plano.',
      mandatory: 'Ops! Este campo é obrigatório'
    }
  },
  resources: {
    'route-plans': {
      notPublished: 'Não publicada',
      publishingPlan: 'Publicando programação',
      declared: 'Declarada',
      regional: 'Regional',
      fields: {
        name: 'Programação Semanal',
        id: 'ID da viagem',
        externalId: 'ID da Programação',
        activationDate: 'Data de ativação',
        region: 'Regional',
        activationState: 'Status',
        state: 'Status',
        totalRoutes: 'Planos de Viagem',
        // Single Route Plan
        routeId: 'ID',
        weekday: 'Dia da Semana',
        departureTime: 'Hr. Saída da Origem',
        origin: 'Origem',
        originArrivalInfo: 'Chegada',
        originDepartureInfo: 'Saída',
        transfers: 'Destinos',
        routeDuration: 'Tempo total',
        vehicleType: 'Veículo',
        carrierName: 'Transportadora',
        merchandiseValue: 'Valor mercadoria',
        lastModifiedTime: 'Última alteração',
        lastModifiedByUser: 'Alterado por',
        archived: 'Arquivado',
        active: 'Ativo',
        frequency: 'Frequência',
        returnPickupRoute: 'Devolução?',
        returnTransferRoute: 'Retorno?'
      },
      add_route: 'Plano de Viagem',
      archive_route: 'Arquivar',
      unarchive_route: 'Desarquivar',
      delete_route: 'Excluir',
      clone_route: 'Duplicar',
      cancel_route: 'Cancelar',
      publish_button: {
        publish_route_plan: 'Publicar',
        declare_route_plan: 'Declarar',
        declare_route_plan_tooltip: {
          content:
            'A programação deve ser declarada quando o planejamento estiver fechado. Você pode fazer a declaração até ',
          content_day: 'às 23:59, de sexta-feira.'
        }
      },
      notification: {
        published: 'Programação Semanal publicada com sucesso!'
      },
      schedule: 'Programação',
      week: 'Semana',
      filters: 'Filtros'
    },
    route: {
      name: 'Rota |||| Rotas',
      title: 'Detalhe do Plano de Viagem',
      toDefine: 'a definir',
      lastModifiedTime: 'Última alteração: ',
      created: ' Criado: ',
      editedAndCreated: '%{date} às %{time} por %{by} ',
      fields: {
        weekday: 'Dia da semana',
        origin: { id: 'Origem' },
        destination: 'Destino',
        loadingDuration: 'Tempo carregamento',
        releaseVehicleDuration: 'Tempo documentação',
        travelDuration: 'Tempo de trânsito',
        activationDate: 'Data de ativação',
        originArrivalTime: 'Chegada na origem',
        arrivalTimeFormatted: 'Chegada destino',
        unloadingDuration: 'Tempo descarregamento',
        departureTimeFormatted: 'Saída destino',
        transfers: 'Destinos',
        vehicleType: 'Veículo',
        carrierId: 'Transportadora',
        routeDurationHuman: 'Tempo total',
        routeDistanceInKm: 'Distância total',
        dock: 'Doca',
        loadingStartTime: 'Início carregamento',
        loadingEndTimeFormatted: 'Fim carregamento',
        merchandiseValue: 'Valor de mercadoria',
        originDepartureTimeFormatted: 'Saída da origem',
        frequency: { id: 'Frequência', title: 'Qual a frequência?' },
        returnTransferRoute: 'Retorno',
        cancelReasonId: 'Motivo',
        cancelReasonDescription: 'Descreva o motivo do cancelamento(opcional).',
        licensePlate: 'Placa do veículo',
        trailerLicensePlate: 'Placa adicional',
        minValueTransfer: 'O tempo mínimo é de %{min}'
      },
      notification: {
        cancelled: 'O plano de viagem foi cancelado'
      },
      new_destination: 'Novo destino',
      calculate_times: 'Calcular tempos',
      update_value_based_on_spreadsheet:
        'Atualizar valor conforme planilha Simulador de Coletas',
      calculationSuccess: 'Tempos da viagem atualizados',
      returnRouteDescription: 'Se sim, marque a caixa',
      cancel_route: 'Cancelar plano de viagem',
      header_span_text: {
        canceled_edit_disabled:
          'Este plano de viagem encontra-se cancelado e por esse motivo não poderá ser editado.',
        completed_edit_disabled:
          'Este plano de viagem encontra-se concluído e por esse motivo não poderá ser editado.',
        pending_review:
          'Este plano de viagem encontra-se em revisão porque foi alterado na programação anterior.',
        display_changelog: 'Ver histórico',
        limited_permissions: {
          main: 'Apenas tem permissões para alterar',
          and: 'e'
        }
      },
      complementary_information: 'Informação complementar',
      update_here: 'Atualize aqui',
      loading_order: {
        title: 'Ordem de carregamento',
        transfers_title: 'Transferências',
        transfers_content: ' aparecem entre parênteses.',
        separate_load_title: 'Cargas separadas',
        separate_load_content: ' são representadas por “#”.',
        mixed_load_title: 'Cargas misturadas',
        mixed_load_content: ' são representadas por ”+”.'
      },
      changelog: {
        title: 'Histórico',
        createdAtAndBy:
          'Plano de viagem criado em %{date} às %{time} por %{by}',
        dateAndTime: '%{date} às %{time}',
        changes: 'Alteração',
        reason: 'Motivo',
        action_id: {
          ROUTE_CHANGELOG_OPERATION_TYPE_UPDATE: 'editou',
          ROUTE_CHANGELOG_OPERATION_TYPE_CANCEL: 'cancelou',
          ROUTE_CHANGELOG_OPERATION_TYPE_ARCHIVE: 'arquivou',
          ROUTE_CHANGELOG_OPERATION_TYPE_UNARCHIVE: 'desarquivou',
          ROUTE_CHANGELOG_OPERATION_TYPE_DELETE: 'deletou'
        },
        action_route_complement: 'o plano',
        transfer_local_datetime: '%{datetime} em %{local}',
        key_names_plural: '%{items} e %{last_item}'
      },
      delete_route: 'Excluir plano de viagem',
      licensePlateSuccess: 'Placa do veículo atualizada',
      additionalInformation: {
        addObservations: 'Adicionar observações',
        addExtraInfo: 'Acrescente informações extras ou detalhes ...',
        observationsNote:
          "Este conteúdo aparecerá no campo de <i>observações</i> da planilha 'Plano de Produção'.",
        maxCharacters: 'de 140 caracteres'
      },
      transshipmentDestinations: {
        unavailable: 'Não recebe transferência para outras bases.',
        available: 'Recebe transferência para outras bases%{punctuation}'
      },
      merchandiseValueSuccess: 'Valor de Mercadoria atualizado',
      licensePlateTooltip:
        'Depois que a transportadora define a placa, não é possível mais editar no Transport Planner.',
      similarRoutesInfo: {
        title: 'Plano de Viagem',
        boxTitle: 'Nota:',
        boxContentLine1:
          'Existem agendamentos com chegada na origem e destinos semelhantes ao atual nos dias da semana marcados abaixo.',
        boxContentLine2:
          'Você pode conferir os agendamentos selecionando o dia correspondente.',
        selectorText: 'Selecionar dia da semana'
      }
    },
    'pickup-plans': {
      name: 'Embarcadores',
      listTitle: 'Programação de Embarcadores',
      backToRouterPlans: 'Voltar para as Programações de Embarcadores',
      fields: {
        returnRoute: 'Viagem de devolução?'
      }
    },
    'transfer-plans': {
      name: 'Transferência |||| Transferências',
      listTitle: 'Programação de Transferências',
      backToRouterPlans: 'Voltar para as Programações de Transferências',
      fields: {
        returnRoute: 'Viagem de retorno?'
      }
    },
    'pickup-recurrences': {
      name: 'Embarcadores'
    },
    'transfer-recurrences': {
      name: 'Transferências'
    },
    recurrence: {
      id: 'ID:',
      toDefine: 'a definir',
      recurrence: 'Recorrência',
      allDays: 'Todos os dias',
      noEndDate: 'Não há data final',
      fields: {
        routeDistanceInKm: 'Distância total',
        routeDurationHuman: 'Tempo de trânsito',
        startDateFormatted: 'Data de início',
        endDateFormatted: 'Data final'
      }
    }
  },
  state: {
    ROUTE_PLAN_ACTIVATION_STATE_DRAFT: 'Próxima',
    ROUTE_PLAN_ACTIVATION_STATE_ACTIVE: 'Ativa',
    ROUTE_PLAN_ACTIVATION_STATE_INACTIVE: 'Inativa',
    ROUTE_STATE_ARCHIVED: 'Arquivado',
    ROUTE_STATE_PLANNED: 'Planejado',
    ROUTE_STATE_PUBLISHED: 'Enviado Infolog',
    ROUTE_STATE_ACTIVE: 'Ativo',
    ROUTE_STATE_CANCELLED: 'Cancelado',
    ROUTE_STATE_COMPLETED: 'Concluído',
    ROUTE_STATE_PENDING_REVIEW: 'Para revisão'
  },
  dialogs: {
    reason_dialog: {
      reason_label: 'Motivo',
      description_label: 'Descreva o motivo.',
      cancel_route: {
        title: 'Cancelar plano de viagem.',
        content:
          'Ao fazer isso, o plano de viagem será cancelado e não será considerado para execução.',
        reason_title:
          'Se deseja continuar, selecione o motivo do cancelamento do plano de viagem:',
        action: 'Cancelar plano de viagem',
        sub_action: 'Manter'
      },
      edit_route: {
        title: 'Qual o motivo da alteração?',
        content: 'Você está alterando um plano de viagem que já foi declarado.',
        reason_title: 'Para continuar, informe o motivo:',
        action: 'Confirmar',
        sub_action: 'Cancelar'
      },
      create_route: {
        title: 'Novo plano de viagem.',
        content:
          'Ao adicionar um novo plano de viagem à programação semanal ativa, está a alterar o que inicialmente foi declarado para execução.',
        reason_title:
          'Para prosseguir, indique o motivo da criação do novo plano de viagem:',
        action: 'Confirmar',
        sub_action: 'Cancelar'
      },
      mass_duplication_reason: {
        title: 'Novos planos de viagem.',
        content:
          'Ao adicionar novos plano de viagem à programação semanal ativa, está a alterar o que inicialmente foi declarado para execução.',
        reason_title:
          'Para prosseguir, indique o motivo da criação dos novos planos de viagem:',
        action: 'Confirmar',
        sub_action: 'Cancelar'
      },
      archive_route: {
        title: 'Ao arquivar, o status mudará para "Arquivado".',
        content:
          'Com isso, você vai alterar o planejamento semanal declarado anteriormente.',
        reason_title: 'Para continuar, indique o motivo:',
        action: 'Arquivar',
        sub_action: 'Voltar'
      },
      unarchive_route: {
        title: 'Ao desarquivar, o status mudará para "Planejado".',
        content:
          'Com isso, você vai alterar o planejamento semanal declarado anteriormente.',
        reason_title: 'Para continuar, indique o motivo:',
        action: 'Desarquivar',
        sub_action: 'Voltar'
      },
      reason_mandatory:
        'Para salvar, você precisa informar qual foi o problema. 😉'
    },
    mass_duplication: {
      title: 'Duplicar Plano de Viagem',
      content: 'Escolha o dia da semana para onde pretende duplicar os planos.',
      select_label: 'Dia da semana',
      action: 'Duplicar',
      sub_action: 'Cancelar',
      warning: 'Atenção',
      warning_description:
        'Não é possível duplicar planos de viagem para dias anteriores à data do plano'
    },
    plans_to_review: {
      title: 'Opa! Parece que você não revisou todos os planos de viagem.',
      content: 'Vimos que ainda há planos de viagem com ',
      content_status: 'status "Para Revisão".',
      description: {
        start: 'Se você publicar, esses planos vão se tornar ',
        active: 'ativos',
        end: ' e terão informações da programação original (sem as alterações).'
      },
      action: 'Revisar planos',
      sub_action_publish: 'Publicar planos',
      sub_action_declare: 'Declarar programação'
    },
    awareness: {
      action: 'Entendi',
      do_not_display_future: 'Não mostrar esta mensagem no futuro',
      review_route: {
        title: 'Status "Para revisão"',
        content:
          'O status “Para revisão” identifica os planos que tiveram alterações na programação anterior.',
        description:
          'Estes planos devem ser revisados, isto é, incluidos ou excluidos da programação antes de a programação se tornar ativa (%{date} às %{time}).'
      },
      changes_done: {
        title: 'Você fez alterações no plano de viagem.',
        first_info: {
          start: 'Ao clicar em voltar, as modificações',
          end: 'serão perdidas.'
        },
        second_info: {
          start: 'Você gostaria de',
          end: 'as alterações feitas?'
        }
      }
    },
    publish_dialog: {
      publish_route_plan: {
        confirmation_title: 'Publicar %{name}',
        confirmation_content:
          'Você tem certeza que deseja publicar a Programação Semanal %{id}?'
      },
      declare_route_plan: {
        confirmation_title: 'Declarar programação',
        confirmation_content: {
          start:
            'Ao declarar, você define o plano de viagem que vai ser executado na próxima semana. ',
          middle: 'Lembrando que você pode declarar até ',
          day: 'às 23:59, de sexta-feira.',
          end:
            'Após declarada, todas as alterações precisarão ser justificadas.'
        },
        action: 'Declarar programação'
      }
    },
    input_dialog: {
      action: 'Salvar',
      sub_action: 'Cancelar',
      licensePlate: {
        title: 'Editar placa do veículo',
        validation_pattern: '^[A-z0-9]{7}$',
        review_valid_to_continue:
          'Opa. Parece que essa placa não existe. Revise para seguir.',
        insert_valid_to_continue: 'Para continuar, insira uma placa válida.'
      },
      merchandiseValue: {
        title: 'Editar valor da mercadoria',
        validation_pattern: '^(?=.*[1-9])[0-9]*[.,]?[0-9]{1,2}$',
        review_valid_to_continue:
          'Opa. Parece que esse não é um valor válido. Revise para seguir.',
        insert_valid_to_continue: 'Para continuar, insira um valor válido.',
        tooltip:
          'Ao alterar o valor manualmente, ele será aplicado nos próximos planos de viagem.',
        calculate_merchandise: 'Calcular mercadoria'
      }
    },
    transshipment_destinations: {
      title: 'Informe as bases com carga para transferência',
      content: 'Comece adicionando a carga que deve ir ao fundo do veículo.',
      label: 'Selecione opção',
      type: 'Tipo de carga',
      add_button: 'Nova base'
    }
  },
  last_modified_time_info: {
    at: 'às',
    modified: 'Atualizado'
  },
  pages: {
    title: 'Transport Planner',
    createRoute: 'Criação de Plano de Viagem',
    editRoute: 'Edição de Plano de Viagem',
    createRecurrence: 'Nova programação recorrente',
    planningList: {
      schedulesList: {
        name: 'Planos de Viagem',
        tooltipHelperText:
          'Planos de viagem são planejamentos de rotas com informações sobre origem, destinos e transporte. Cada linha da tabela abaixo representa um plano de viagem.'
      },
      recurrencesList: {
        name: 'Programações Recorrentes',
        tooltipHelperText:
          'Programações recorrentes são agrupamentos de planos de viagem que contêm as mesmas informações de origem, destino e transporte, mas que ocorrem em dias diferentes. Cada linha representa uma programação recorrente.'
      }
    }
  },
  input_with_dialog: {
    licensePlate: {
      placeholder_text: 'A definir',
      placeholder_dialog: 'ex: AAA1A11 / AAA1111'
    },
    merchandiseValue: {
      placeholder_text: 100000,
      placeholder_dialog: 'Insira apenas números'
    }
  }
};
