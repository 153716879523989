import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useTranslate,
  Button,
  Confirm,
  useMutation,
  useNotify
} from 'react-admin';
import ActionDelete from '@material-ui/icons/Delete';

const DeleteRouteButton = ({
  selectedRoutes,
  onSuccess,
  routePlanId,
  label,
  variant,
  size,
  classes
}) => {
  const [open, setOpen] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const routeIds = selectedRoutes;
  const payload = {
    data: { routeIds, routePlanId }
  };

  const [deleteMany] = useMutation(
    { type: 'deleteMany', resource: 'route', payload },
    {
      onSuccess: () => {
        setOpen(false);
        notify('ra.notification.deleted', 'info', { smart_count: 1 });
        onSuccess();
      },
      onFailure: () => {
        setOpen(false);
        notify('ra.notification.http_error');
      }
    }
  );

  const confirmDialogTitle = translate('ra.message.delete_title', {
    smart_count: selectedRoutes.length
  });

  return (
    <>
      <Button
        label={label || 'resources.route-plans.delete_route'}
        disabled={selectedRoutes.length <= 0}
        onClick={() => setOpen(true)}
        startIcon={<ActionDelete />}
        variant={variant}
        size={size}
        className={classes}
      />

      <Confirm
        isOpen={open}
        title={confirmDialogTitle}
        content="ra.message.delete_content"
        onConfirm={deleteMany}
        onClose={() => setOpen(false)}
        CancelIcon={() => {
          return <div />;
        }}
        ConfirmIcon={() => {
          return <div />;
        }}
      />
    </>
  );
};

DeleteRouteButton.propTypes = {
  selectedRoutes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSuccess: PropTypes.func.isRequired,
  routePlanId: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  classes: PropTypes.string
};

DeleteRouteButton.defaultProps = {
  routePlanId: undefined,
  label: undefined,
  variant: undefined,
  size: 'small',
  classes: undefined
};

export default DeleteRouteButton;
