/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { required } from 'react-admin';
import PropTypes from 'prop-types';
import SelectInputRemote from '../select-input-remote';
import useStyles from './styles';

const SelectFrequencyInput = ({ source, allowPickups, ...props }) => {
  const classes = useStyles();

  const defaultValueFunction = frequencies =>
    frequencies.find(frequency => frequency.default)?.id;

  return (
    <div className={classes.selectFrequencyContainer}>
      <SelectInputRemote
        reference="frequencies"
        source={source}
        filterValues={{ allowPickups }}
        defaultValueFunction={defaultValueFunction}
        validate={!allowPickups ? required() : undefined}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </div>
  );
};

SelectFrequencyInput.propTypes = {
  source: PropTypes.string.isRequired,
  allowPickups: PropTypes.bool.isRequired
};

export default SelectFrequencyInput;
