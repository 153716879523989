import flatMap from 'lodash/flatMap';
import { convertUtcToBrz, formatDateProto } from '../utils/date-format';
import routeAdapter from './route-adapter';

const convertFromServer = plan => {
  const routes = plan.routes ? routeAdapter.fromServer(plan.routes) : [];
  const declared = plan.declaredTime !== undefined;

  return {
    ...plan,
    allowPickups: plan.allowPickups || false,
    routes,
    editable: plan.activationState !== 'ROUTE_PLAN_ACTIVATION_STATE_INACTIVE',
    active: plan.activationState === 'ROUTE_PLAN_ACTIVATION_STATE_ACTIVE',
    origins: [...new Set(routes.map(route => route.origin.longName))],
    carriers: [...new Set(routes.map(route => route.carrierName))],
    weekdays: [...new Set(routes.map(route => route.departureWeekdayName))],
    transfers: [
      ...new Set(
        flatMap(routes, route =>
          route.transfers.map(transfer => transfer.destination.longName)
        )
      )
    ],
    state: [...new Set(routes.map(route => route.state))],
    returnRoute: [...new Set(routes.map(route => route.returnRoute))],
    formatRoutePlanDate: format => formatDateProto(plan.activationDate, format),
    lastPublicationScheduleTimeHuman: plan.lastPublicationScheduleTime
      ? convertUtcToBrz(plan.lastPublicationScheduleTime, 'DD/MM/YYYY HH:mm')
      : null,
    hasChangesUnscheduledForPublication:
      plan.hasChangesUnscheduledForPublication || false,
    totalRoutesPendingReview: plan.totalRoutesPendingReview || 0,
    requiresJustification: plan.requiresJustification || false,
    showDeclaredLabel:
      declared && plan.activationState === 'ROUTE_PLAN_ACTIVATION_STATE_DRAFT',
    declared,
    deletable: plan.deletable || false,
    cancelable: plan.cancelable || false
  };
};

export default {
  fromServer: data => {
    if (Array.isArray(data)) {
      return data.map(plan => convertFromServer(plan));
    }

    return convertFromServer(data);
  }
};
