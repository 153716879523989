import { Cached as CachedIcon } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';

import lastModifiedTimeInfo from './styles';
import { convertDateTimeToDistance } from '../../../utils/date-format';

const LastModifiedTimeInfo = ({ modifiedTime }) => {
  const translate = useTranslate();
  const styles = lastModifiedTimeInfo();

  const durationUntilNow = useMemo(
    () =>
      convertDateTimeToDistance(
        modifiedTime,
        translate('last_modified_time_info.at')
      ),
    [modifiedTime, translate]
  );

  return (
    <Typography
      component="span"
      variant="body2"
      className={styles.lastModifiedTime}
      id="last-modified-time"
    >
      <CachedIcon />
      {translate('last_modified_time_info.modified')}
      <Typography
        component="strong"
        variant="inherit"
        id="last-modified-time-hour"
      >
        {durationUntilNow}
      </Typography>
    </Typography>
  );
};

LastModifiedTimeInfo.propTypes = {
  modifiedTime: PropTypes.instanceOf(Date).isRequired
};

export default LastModifiedTimeInfo;
