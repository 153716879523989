import { makeStyles } from '@material-ui/styles';
import { colors } from '@loggi/mar';

const loadingOrderInfoStyle = makeStyles(() => ({
  inlineBlock: {
    display: 'inline-flex',
    marginRight: '1rem',
    '&  .Mui-disabled.Mui-disabled': {
      color: 'inherit'
    }
  },
  groupTitleDividerSmallerText: {
    width: 'auto',
    fontWeight: 700,
    color: colors.smoke[900],
    marginRight: '5px',
    fontSize: 16
  },
  groupTitleDividerSmallerTextGrey: {
    width: 'auto',
    fontWeight: 700,
    color: colors.smoke[700],
    marginRight: '5px',
    fontSize: 14,
    marginTop: '16px'
  },
  boxStyle: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export default loadingOrderInfoStyle;
