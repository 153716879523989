const style = {
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingBottom: '0.5rem',
    width: '100%'
  },
  dateInput: {
    width: '9.75rem',
    marginRight: '1rem'
  },
  noEndCheckbox: {
    paddingBottom: '1.125rem'
  }
};

export default style;
