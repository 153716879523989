import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useNotify, useTranslate } from 'react-admin';
import { Box, CircularProgress, Drawer, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import ChangelogAccordion from './route-form-changelog-accordion';
import { useChangelogStyles } from '../../styles';
import {
  BRZ_DATE_FORMAT,
  BRZ_TIME_FORMAT,
  convertUtcToBrz
} from '../../../../../../utils/date-format';

const ChangelogSidebar = ({
  isOpen,
  onClickCloseChangelog,
  record,
  routeId
}) => {
  const styles = useChangelogStyles();
  const translate = useTranslate();

  const notify = useNotify();
  const [mutate, { loading }] = useMutation();
  const [changelog, setChangelog] = useState({ data: {}, total: 0 });

  const translatePath = 'resources.route.changelog';

  const payload = useMemo(() => {
    return { data: { routeId } };
  }, [routeId]);

  useEffect(() => {
    if (isOpen) {
      mutate(
        { type: 'routeChangelog', resource: 'changelog', payload },
        {
          onSuccess: response => {
            setChangelog(response);
          },
          onFailure: () => {
            notify('ra.notification.http_error');
          }
        }
      );
    }
  }, [isOpen, mutate, notify, payload]);

  // Set only the selected panel as expanded
  const [expandedPanel, setExpandedPanel] = useState('');
  const handleOnChange = panel => (_event, expanded) => {
    setExpandedPanel(expanded ? panel : '');
  };

  const onClickClose = () => {
    setExpandedPanel('');
    onClickCloseChangelog();
  };

  return (
    <Drawer open={isOpen} anchor="right">
      <Box className={styles.root}>
        <Box
          className={styles.closeArea}
          onClick={onClickClose}
          data-testid="changelogSidebarClose"
        >
          <CloseIcon className={styles.closeIcon} />
        </Box>
        <Typography component="span">
          <span className={styles.title}>
            {translate(`${translatePath}.title`)}
          </span>
          <span className={styles.createdAtAndBy}>
            {translate(`${translatePath}.createdAtAndBy`, {
              date: convertUtcToBrz(record.createdTime, BRZ_DATE_FORMAT),
              time: convertUtcToBrz(record.createdTime, BRZ_TIME_FORMAT),
              by: record.createdByUser
            })}
          </span>
        </Typography>

        {loading && (
          <Box className={styles.loading}>
            <CircularProgress data-testid="changelogSidebarProgress" />
          </Box>
        )}
        {// Ony show the changelog data after the data is retreived and the loading is dismissed
        !loading && (
          <Box
            className={styles.extensionBox}
            data-testid="changelogSidebarAccordion"
          >
            {changelog.total > 0 &&
              changelog.data.map((changelogData, index) => (
                <ChangelogAccordion
                  changelogItem={changelogData}
                  changelogPanelId={`changelog_panel_id_${index}`}
                  expanded={expandedPanel}
                  onChange={handleOnChange}
                />
              ))}
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

ChangelogSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClickCloseChangelog: PropTypes.func.isRequired,
  record: {
    createdTime: PropTypes.string.isRequired,
    createdByUser: PropTypes.string.isRequired
  }.isRequired,
  routeId: PropTypes.string.isRequired
};

export default ChangelogSidebar;
