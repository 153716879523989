/* eslint-disable react/forbid-prop-types */
import { useTranslate } from 'react-admin';
import React from 'react';
import { Box } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PropTypes from 'prop-types';
import useStyles from './styles';

import { globalStyles } from '../../theme/transport-planner-theme';

const StateDot = ({ state, boxClassName, boxStyles }) => {
  const styles = globalStyles();
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      className={`${boxClassName} state-dot`}
      style={boxStyles}
    >
      <FiberManualRecordIcon
        className={`${classes.stateDot} ${styles[state]}`}
      />
      <span>{translate(`state.${state}`)}</span>
    </Box>
  );
};

StateDot.propTypes = {
  state: PropTypes.string.isRequired,
  boxClassName: PropTypes.string,
  boxStyles: PropTypes.object
};

StateDot.defaultProps = {
  boxClassName: '',
  boxStyles: {}
};

export default StateDot;
