import React from 'react';
import { useTranslate } from 'react-admin';
import { Divider, Typography } from '@material-ui/core';
import { Map, WatchLater } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { scheduleInfoBoxStyle as style } from '../style';

const ScheduleInfoBox = ({ routeDistanceInKm, routeDurationHuman }) => {
  const translate = useTranslate();

  return (
    <div style={style.box}>
      <div style={style.panel}>
        <Map style={style.icon} fontSize="small" />
        <Typography style={style.info}>{`${routeDistanceInKm} Km`}</Typography>
        <Typography style={style.caption}>
          {translate('resources.recurrence.fields.routeDistanceInKm')}
        </Typography>
      </div>

      <Divider orientation="vertical" flexItem style={style.divider} />

      <div style={style.panel}>
        <WatchLater style={style.icon} fontSize="small" />
        <Typography component="caption" style={style.info}>
          {routeDurationHuman}
        </Typography>
        <Typography component="caption" style={style.caption}>
          {translate('resources.recurrence.fields.routeDurationHuman')}
        </Typography>
      </div>
    </div>
  );
};

ScheduleInfoBox.propTypes = {
  routeDistanceInKm: PropTypes.number,
  routeDurationHuman: PropTypes.string
};

ScheduleInfoBox.defaultProps = {
  routeDistanceInKm: '--',
  routeDurationHuman: '-h -min'
};

export default ScheduleInfoBox;
