import { makeStyles } from '@material-ui/styles';
import { colors } from '@loggi/mar';

const inputWithDialogStyles = makeStyles({
  root: {
    display: 'inline-flex',
    width: '240px',
    minWidth: 'fit-content',
    maxHeight: '74px'
  },
  button: {
    marginTop: '16px',
    maxHeight: '40px',
    minWidth: '100px',

    '& svg': {
      fontSize: '20px',
      marginRight: '8px'
    }
  },
  infoIcon: {
    marginTop: '16px',
    marginLeft: '12px',
    cursor: 'pointer'
  },
  input: {
    alignItems: 'center',

    '& >label': {
      color: `${colors.root[500]}!important`,
      marginTop: '3px'
    },

    '& input': {
      backgroundColor: colors.root[0],
      color: `${colors.root[900]}!important`,

      '& .placeholder': {
        color: `${colors.root[500]}!important`
      }
    }
  },
  dialog: {
    '> div': {
      width: '600px!important'
    }
  },
  dialogInput: {
    maxWidth: '236px'
  },
  placeholder: {
    '& input': {
      backgroundColor: colors.root[0],
      color: `${colors.root[500]}!important`
    }
  }
});

export default inputWithDialogStyles;
