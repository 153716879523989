import { makeStyles } from '@material-ui/styles';
import { colors } from '@loggi/mar';

const dropdownInputStyles = makeStyles(() => ({
  button: {
    backgroundColor: colors.smoke[10],
    color: colors.blue[500],
    border: '1px solid rgba(0, 186, 255, 0.5)',
    width: 'fit-content',
    height: 'fit-content'
  },
  modal: {
    position: 'absolute',
    borderRadius: '10px',
    boxShadow: '0 6px 12px 0 rgba(139, 159, 183, 0.2);',
    backgroundColor: colors.root[0]
  },
  list: {
    listStyleType: 'none',
    padding: '0'
  },
  archive: {
    borderRadius: '0',
    color: colors.root[900],
    fontWeight: 'normal',
    width: '100%',
    justifyContent: 'flex-start'
  },
  cancel: {
    color: colors.red[700],
    fontWeight: 'normal',
    width: '100%',
    justifyContent: 'flex-start'
  },
  delete: {
    color: colors.red[700],
    fontWeight: 'normal',
    width: '100%',
    justifyContent: 'flex-start'
  },
  icon: {
    fontSize: '30px'
  }
}));

export default dropdownInputStyles;
