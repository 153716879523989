import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextInput, useTranslate, useMutation, useNotify } from 'react-admin';
import { Edit, Info } from '@material-ui/icons';
import { Box, Button, Tooltip, Typography } from '@material-ui/core';

import inputWithDialogStyles from './styles';
import InputDialog from './input-dialog';
import {
  convertDecimalToMoneyProto,
  convertMoneyProtoToDecimal
} from '../../../utils/currency-format';
import portugueseMessages from '../../../config/portuguese-messages';

const InputWithDialog = ({
  routeId,
  routePlanId,
  name,
  defaultValue,
  placeholderValue,
  placeholderDialog,
  hideEditButton,
  hiddenEditButtonToolTip,
  resource,
  transformDataToUpperCase,
  useConvertBetweenDecimalAndMoneyProto,
  usePlaceholderDialogOrValue,
  validationPattern,
  reference,
  inputComponentType,
  ...props
}) => {
  const styles = inputWithDialogStyles();
  const translate = useTranslate();
  const [dialogOpen, setDialogOpen] = useState(false);

  const [mutate, { loading }] = useMutation();
  const notify = useNotify();

  const [value, setValue] = useState(defaultValue || placeholderValue);

  const handleValueChange = newValue => {
    const parsedInput = () => {
      if (useConvertBetweenDecimalAndMoneyProto)
        return convertMoneyProtoToDecimal(newValue);
      return newValue;
    };

    setValue(parsedInput());
  };

  const onConfirm = input => {
    const parsedInput = () => {
      if (transformDataToUpperCase) return input.toUpperCase();
      if (useConvertBetweenDecimalAndMoneyProto)
        return convertDecimalToMoneyProto(input);
      return input;
    };

    const payload = {
      data: {
        routePlanId,
        routeId,
        field: resource,
        [resource]: parsedInput()
      }
    };

    return mutate(
      {
        type: 'updateRouteSingleField',
        resource: 'route',
        payload
      },
      {
        onSuccess: () => {
          setDialogOpen(false);
          handleValueChange(parsedInput());
          notify(`resources.route.${resource}Success`, 'success');
        },
        onFailure: error => {
          setDialogOpen(false);
          notify(error.message, 'error');
        }
      }
    );
  };

  const getDialogDefaultValue = useMemo(() => {
    if (usePlaceholderDialogOrValue)
      return value ===
        portugueseMessages.input_with_dialog[resource].placeholder_text
        ? placeholderDialog
        : value;
    return defaultValue || placeholderDialog;
  }, [
    resource,
    defaultValue,
    placeholderDialog,
    value,
    usePlaceholderDialogOrValue
  ]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className={styles.root}>
      <TextInput
        label={name}
        name={resource}
        InputProps={{
          disableUnderline: true,
          value,
          inputComponent: inputComponentType
        }}
        InputLabelProps={{ shrink: true }}
        disabled
        variant="standard"
        className={`${styles.input} ${
          !value?.toString() ? styles.placeholder : ''
        }`}
        data-testid={`${resource}-input-dialog__input`}
        inputRef={reference}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {!hideEditButton && (
        <Button
          color="primary"
          onClick={() => setDialogOpen(true)}
          variant="text"
          size="small"
          className={styles.button}
          data-testid={`${resource}-input-dialog__button`}
        >
          <Edit />
          {`${translate('ra.action.edit')}`}
        </Button>
      )}

      {hideEditButton && hiddenEditButtonToolTip && (
        <Tooltip
          title={
            <Typography component="div">
              <Box>
                {translate(`resources.route.${hiddenEditButtonToolTip}`)}
              </Box>
            </Typography>
          }
          placement="top-start"
        >
          <Info
            className={styles.infoIcon}
            data-testid="licensePlate-infoIcon"
          />
        </Tooltip>
      )}

      <InputDialog
        name={name}
        defaultValue={getDialogDefaultValue}
        disabled={loading}
        resource={resource}
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={onConfirm}
        validationPattern={validationPattern}
        inputComponentType={inputComponentType}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </div>
  );
};

export default InputWithDialog;

InputWithDialog.propTypes = {
  defaultValue: PropTypes.string,
  placeholderValue: PropTypes.string,
  placeholderDialog: PropTypes.string,
  name: PropTypes.string,
  hideEditButton: PropTypes.bool,
  hiddenEditButtonToolTip: PropTypes.string,
  resource: PropTypes.string,
  routeId: PropTypes.string.isRequired,
  routePlanId: PropTypes.string.isRequired,
  transformDataToUpperCase: PropTypes.bool,
  useConvertBetweenDecimalAndMoneyProto: PropTypes.bool,
  usePlaceholderDialogOrValue: PropTypes.bool,
  validationPattern: PropTypes.string.isRequired,
  reference: PropTypes.element.isRequired,
  inputComponentType: PropTypes.element.isRequired
};

InputWithDialog.defaultProps = {
  defaultValue: undefined,
  placeholderValue: undefined,
  placeholderDialog: undefined,
  name: undefined,
  hideEditButton: false,
  hiddenEditButtonToolTip: undefined,
  resource: undefined,
  transformDataToUpperCase: false,
  useConvertBetweenDecimalAndMoneyProto: false,
  usePlaceholderDialogOrValue: false
};
