import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import get from 'lodash/get';
import { useForm } from 'react-final-form';
import { useStyles } from './styles';

const TransshipmentDestinationText = ({
  hasTransshipment,
  source,
  onClick
}) => {
  const translate = useTranslate();
  const style = useStyles();
  const form = useForm();

  const transshipmentTranslationPath =
    'resources.route.transshipmentDestinations';
  const loadingOrder = get(form.getState().values, source);

  return (
    <Box className={style.text}>
      {hasTransshipment || loadingOrder ? (
        <>
          <Typography>
            {translate(`${transshipmentTranslationPath}.available`, {
              punctuation: loadingOrder ? ':' : '.'
            })}
            &nbsp;
            {loadingOrder?.map(item => item.facility.shortName).join(', ')}
            {hasTransshipment && (
              <button
                className={`${style.link} ${style.bold}`}
                type="button"
                onClick={onClick}
              >
                {' '}
                &nbsp;&nbsp;
                {translate(`ra.action.edit`)}
              </button>
            )}
          </Typography>
        </>
      ) : (
        <Typography>
          {translate(`${transshipmentTranslationPath}.unavailable`)}
        </Typography>
      )}
    </Box>
  );
};

TransshipmentDestinationText.propTypes = {
  hasTransshipment: PropTypes.bool.isRequired,
  source: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default TransshipmentDestinationText;
