/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Tab, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';
import HeaderToolbar from '../../../molecules/planning-list/header-toolbar';
import TabSchedules from '../schedule';
import RecurrenceList from '../recurrence/list';
import TabTitle from '../../../atoms/tab-title-with-tooltip';
import { REGIONS } from '../../../../constants';
import style from '../style';

const PlanningList = props => {
  const translate = useTranslate();

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedRegional, setSelectedRegional] = useState(REGIONS[0].id);

  const handleChange = (_, newValue) => setSelectedTab(newValue);

  return (
    <div style={style.main}>
      <HeaderToolbar
        name={props.resource}
        onChangeFilter={setSelectedRegional}
        defaultFilter={selectedRegional}
      />
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab
          label={
            <TabTitle
              title={translate('pages.planningList.schedulesList.name')}
              tooltipText={translate(
                'pages.planningList.schedulesList.tooltipHelperText'
              )}
              selected={selectedTab === 0}
            />
          }
          style={style.tab}
        />
        <Tab
          label={
            <TabTitle
              title={translate('pages.planningList.recurrencesList.name')}
              tooltipText={translate(
                'pages.planningList.recurrencesList.tooltipHelperText'
              )}
              selected={selectedTab === 1}
            />
          }
          style={style.tab}
        />
      </Tabs>
      {selectedTab === 0 && (
        <TabSchedules {...props} listFilter={selectedRegional} />
      )}
      {selectedTab === 1 && (
        <RecurrenceList {...props} listFilter={selectedRegional} />
      )}
    </div>
  );
};

PlanningList.propTypes = {
  options: PropTypes.shape({
    allowPickups: PropTypes.bool.isRequired
  }).isRequired,
  resource: PropTypes.string.isRequired
};

export default PlanningList;
