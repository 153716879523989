import {
  BRZ_DATE_FORMAT,
  convertUtcToBrz,
  formatDateProto,
  LOCAL_DATE_FORMAT
} from '../utils/date-format';
import routeAdapter from './route-adapter';
import { recurrenceStates, weekdays } from '../constants';

const convertFromServer = recurrence => {
  return {
    ...recurrence,
    active:
      recurrence.state === recurrenceStates.RECURRENCE_SCHEDULE_STATE_ACTIVE ||
      false,
    planned:
      recurrence.state === recurrenceStates.RECURRENCE_SCHEDULE_STATE_PLANNED ||
      false,
    ended:
      recurrence.state === recurrenceStates.RECURRENCE_SCHEDULE_STATE_ENDED ||
      false,
    archived: recurrence.archived || false,
    pendingReview: recurrence.pendingReview || false,
    allowPickups: recurrence.allowPickups || false,
    routeDetails: routeAdapter.fromServer(recurrence.routeDetails),
    startDateFormatted: formatDateProto(
      recurrence.startDate,
      LOCAL_DATE_FORMAT
    ),
    endDateFormatted: formatDateProto(recurrence.endDate, LOCAL_DATE_FORMAT),
    createdDateFormatted: convertUtcToBrz(
      recurrence.createdTime,
      BRZ_DATE_FORMAT
    ),
    lastModifiedDateFormatted: convertUtcToBrz(
      recurrence.lastModifiedTime,
      BRZ_DATE_FORMAT
    ),
    recurrenceWeekdays: recurrence.recurrenceDays.map(recurrenceDay =>
      weekdays.find(day => day.googleId === recurrenceDay)
    )
  };
};

export default {
  fromServer: data => {
    if (Array.isArray(data)) {
      return data.map(plan => convertFromServer(plan));
    }
    return convertFromServer(data);
  }
};
