/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useTranslate, DateInput, required } from 'react-admin';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import moment from 'moment';
import {
  addDaysToDateTime,
  LOCAL_DATE_FORMAT
} from '../../../../utils/date-format';
import style from './style';

const StartEndDaySelector = props => {
  const translate = useTranslate();
  const form = useForm();

  const { disabled } = props;

  const [noEndDateChecked, setNoEndDateChecked] = useState(
    !form.getState().values.endDateFormatted
  );

  const onCheckNoEndDate = () => {
    setNoEndDateChecked(!noEndDateChecked);
    form.change('endDateFormatted', undefined);
  };

  const defaultStartDateValue = addDaysToDateTime(
    moment(),
    1,
    LOCAL_DATE_FORMAT
  );

  return (
    <div style={style.root}>
      <DateInput
        {...props}
        source="startDateFormatted"
        defaultValue={defaultStartDateValue}
        disabled={disabled}
        inputProps={{
          min: defaultStartDateValue
        }}
        style={style.dateInput}
        validate={required()}
        data-testid="recurrence-input-start-date"
      />
      <DateInput
        {...props}
        source="endDateFormatted"
        disabled={disabled || noEndDateChecked}
        inputProps={{
          min: addDaysToDateTime(
            form.getState().values.startDateFormatted,
            1,
            LOCAL_DATE_FORMAT
          )
        }}
        style={style.dateInput}
        validate={!noEndDateChecked ? required() : null}
        data-testid="recurrence-input-end-date"
      />
      <FormControlLabel
        style={style.noEndCheckbox}
        disabled={disabled}
        control={
          <Checkbox
            name="noEndDate"
            checked={noEndDateChecked}
            data-testid="recurrence-checkbox-no-end-date"
          />
        }
        label={translate('resources.recurrence.noEndDate')}
        onClick={onCheckNoEndDate}
      />
    </div>
  );
};

StartEndDaySelector.propTypes = {
  disabled: PropTypes.bool.isRequired
};

export default StartEndDaySelector;
