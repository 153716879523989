import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useNotify } from 'ra-core';
import PropTypes from 'prop-types';
import {
  Show,
  SimpleShowLayout,
  useShowController,
  useTranslate
} from 'react-admin';
import { Box } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useHistory } from 'react-router-dom';

import CustomColumnToolbar from './custom-column-toolbar';
import CustomToolbar from './custom-toolbar';
import defaultFiltersValues from './functions/default-filters-values';
import getHeaderData from './functions/get-header-data';
import filterRoutes from './functions/filter-routes-array';
import columnCustomization from './functions/column-customization';
import { defaultRouteFilters } from '../constants';
import RoutePlanShowHeader from './route-plan-show-header';
import { routePlanShowStyles } from '../styles';
import { weekdays } from '../../../../../constants';

const RoutePlanShow = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const history = useHistory();
  const { record, refetch } = useShowController(props);
  const [routes, setRoutes] = useState([]);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState([]);
  const [isArchivable, setIsArchivable] = useState(true);
  const [gridColumns, setGridColumns] = useState([]);

  // Default list filters
  const startingDay =
    record && record.active
      ? weekdays.find(day => day.momentId === new Date().getDay())
      : weekdays.find(day => day.momentId === 0);

  const getSessionFilters = () => {
    // Get the filters stored for this specific routePlan
    const sessionStorageFilters = sessionStorage.getItem(
      `${props.id.toString()}_${process.env.REACT_APP_STORAGE_VERSION}`
    );

    return sessionStorageFilters ? JSON.parse(sessionStorageFilters) : null;
  };

  const [filters, setFilters] = useState(
    getSessionFilters() || defaultRouteFilters
  );

  const classes = routePlanShowStyles();
  const [mutate, { loading }] = useMutation();
  const notify = useNotify();

  // eslint-disable-next-line react/prop-types
  const { resource } = props;

  const archiveRouteMap = useMemo(() => {
    return new Map(routes.map(({ id, archived }) => [id, archived]));
  }, [routes]);

  // Set grid columns on page load based on preferences
  useEffect(() => {
    if ('customColumns' in localStorage) {
      const customColumns = JSON.parse(localStorage.getItem('customColumns'));
      const customColumnsObject = Object.assign(...customColumns);

      const newDefaultColumns = getHeaderData(translate).map(col => {
        const newColumn = col;
        if (newColumn.field in customColumnsObject) {
          newColumn.hide = customColumnsObject[newColumn.field];
        }
        return newColumn;
      });

      setGridColumns(newDefaultColumns);
    } else {
      setGridColumns(getHeaderData(translate));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Maintain filters sync with session storage
  useEffect(() => {
    // This method must be created because we can not access directly to props within a hook
    sessionStorage.setItem(
      `${props.id.toString()}_${process.env.REACT_APP_STORAGE_VERSION}`,
      JSON.stringify(filters)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    const archivedDiff = new Set(
      selectedRoute.map(routeId => archiveRouteMap.get(routeId))
    );

    if (archivedDiff.size !== 1) {
      setIsArchivable(true);
    } else {
      setIsArchivable(!archivedDiff.values().next().value);
    }
  }, [selectedRoute, archiveRouteMap]);

  // Filtering the complete routes array with all filters except Day ('Chegada Origem')
  // and updating filteredRoutes, which reflects on the grid changing
  useEffect(() => {
    setFilteredRoutes(filterRoutes({ routes, filters }));
  }, [filters, routes]);

  const changeFilter = (name, value) => {
    setFilters(prevState => ({ ...prevState, [name]: value }));
  };

  // Making a backend request to get routes based on the day filter
  const fetchRoutesByFilter = (fullFetch = false, customFilters = null) => {
    const filterParsed = {
      arrivalTimes: []
    };

    if (!fullFetch) {
      if (customFilters) {
        Object.keys(customFilters).forEach(key => {
          if (key === 'arrivalWeekdayName')
            filterParsed.arrivalTimes = customFilters[key];
        });
      } else {
        Object.keys(filters).forEach(key => {
          if (key === 'arrivalWeekdayName')
            filterParsed.arrivalTimes = filters[key];
        });
      }
    }

    const payload = {
      data: { routePlanId: props.id.toString() },
      filter: { ...filterParsed }
    };

    mutate(
      { type: 'getRoutes', resource: 'route', payload },
      {
        onSuccess: response => {
          setSelectedRoute([]);
          setRoutes(response.data);
          setFilteredRoutes(response.data);
          // Store in session the result of the applied filters
          changeFilter('filteredResultCount', filteredRoutes.length);
        },
        onFailure: () => {
          notify('ra.notification.http_error');
        }
      }
    );
  };

  // Initializing the grid making the first route request when loading component
  useEffect(() => {
    // This request is made when the component is created
    if (record) {
      // Change filters if necessary
      const filtersModified = defaultFiltersValues({
        filters,
        record,
        startingDay
      });
      // Update Filters State
      setFilters(filtersModified);
      // Since the filter state is not updated at this point we need to use custom filters
      fetchRoutesByFilter(false, filtersModified);
    }
  }, [record?.totalRoutesPendingReview]); // eslint-disable-line react-hooks/exhaustive-deps

  // Required to ensure that the component won't be rendered until 'record' is loaded.
  if (!record) return null;

  const refetchData = () => {
    refetch();
    fetchRoutesByFilter();
  };

  // Making a request when closing all filters that have this function as props
  // currently only the 'Chegada Origem' implements this function
  const onCloseFilter = () => {
    fetchRoutesByFilter();
  };

  // This will be called when the users wants to see 'Para Revisão' plans
  const onClickReview = () => {
    changeFilter('arrivalWeekdayName', []);
    changeFilter('state', ['ROUTE_STATE_PENDING_REVIEW']);
    fetchRoutesByFilter(true);
  };

  const clearFilters = () => {
    const filtersCleared = defaultRouteFilters;
    filtersCleared.arrivalWeekdayName = [startingDay.id];
    setFilters(filtersCleared);
    fetchRoutesByFilter(false, filtersCleared);
  };

  return (
    <Show
      title={record.region}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <SimpleShowLayout>
        <RoutePlanShowHeader record={record} resource={resource} />
        <CustomToolbar
          changeFilter={changeFilter}
          clearFilters={clearFilters}
          filters={filters}
          record={record}
          selectedRoute={selectedRoute}
          resource={resource}
          isArchivable={isArchivable}
          refetchData={refetchData}
          userPermissions={permissions}
          closeFilter={onCloseFilter}
          onClickReview={onClickReview}
          routes={routes}
        />
        <div className={classes.root}>
          <Box height="calc(100vh - 280px)">
            <Box border="0 !important" clone>
              <DataGrid
                className={classes.routeShowGrid}
                loading={loading}
                columns={gridColumns}
                onColumnVisibilityChange={params => columnCustomization(params)}
                rows={filteredRoutes}
                onRowClick={({ id: rowId, row: { state } }) => {
                  history.push(
                    `routes/${rowId}?allowPickups=${
                      record.allowPickups
                    }&requiresJustification=${
                      record.requiresJustification
                    }&pendingReview=${state === 'ROUTE_STATE_PENDING_REVIEW'}`
                  );
                }}
                checkboxSelection
                components={{
                  Toolbar: CustomColumnToolbar
                }}
                disableColumnMenu
                disableSelectionOnClick
                selectionModel={selectedRoute}
                rowsPerPageOptions={[]}
                getRowClassName={params =>
                  params.row.state === 'ROUTE_STATE_ARCHIVED' ||
                  params.row.state === 'ROUTE_STATE_CANCELLED'
                    ? 'Archived'
                    : ''
                }
                onSelectionModelChange={newSelection => {
                  setSelectedRoute(newSelection.selectionModel);
                }}
                sortingOrder={['desc', 'asc']}
              />
            </Box>
          </Box>
        </div>
      </SimpleShowLayout>
    </Show>
  );
};

RoutePlanShow.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string.isRequired
};

RoutePlanShow.defaultProps = {
  permissions: []
};

export default RoutePlanShow;
