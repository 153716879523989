import { makeStyles } from '@material-ui/styles';
import { colors } from '@loggi/mar';

const additionalInformationStyle = makeStyles(() => ({
  container: {
    marginTop: '20px',
    width: '100%'
  },
  observationsButton: {
    border: 0,
    display: 'inline-flex',
    marginRight: '1rem',
    padding: '0.2rem',
    '&  .Mui-disabled.Mui-disabled': {
      color: 'inherit'
    },
    '&:hover': {
      border: 0
    },
    fontSize: 14
  },
  textareaContainer: {
    maxWidth: 1220
  },
  textarea: {
    paddingTop: 0,
    backgroundColor: colors.smoke['10'],
    width: '100%',
    height: '124px',
    fontSize: '14px',
    fontFamily: 'Open Sans',
    border: '1px solid',
    borderColor: colors.smoke['400'],
    '&:focus': {
      borderColor: colors.blue['500'],
      outline: 'none',
      borderWidth: '2px'
    },
    '& .MuiFilledInput-root': {
      backgroundColor: colors.smoke['10'],
      paddingTop: '12px'
    },
    '& .MuiInputBase-root': {
      backgroundColor: colors.smoke['10'],
      paddingTop: '12px'
    }
  },
  containerTextareaNote: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  textareaNote: {
    color: colors.smoke['700'],
    width: 'fit-content',
    fontSize: '12px'
  },
  textInput: {
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset'
    },
    '&.Mui-focused': {
      backgroundColor: 'unset'
    },
    '&.Mui-disabled': {
      backgroundColor: 'unset'
    }
  }
}));

export default additionalInformationStyle;
