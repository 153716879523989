import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const useStyles = makeStyles(
  () => ({
    dialog: {
      '& .description-input': {
        marginTop: '0px',
        marginBottom: '22px!important'
      }
    },
    destructive: {
      '&:not([disabled])': {
        background: `${colors.red['700']}!important`
      }
    },
    warningBox: {
      padding: '16px 16px',
      borderRadius: '8px',
      background: colors.yellow[50],
      display: 'inline-flex',
      alignItems: 'top',
      justifyContent: 'space-between'
    },
    warningTitle: {
      color: '#665622',
      marginLeft: '16px'
    },
    warningLabel: {
      fontWeight: 'bold'
    },
    warningIcon: {
      '& > svg': {
        fontSize: '18px'
      },
      color: colors.yellow[500]
    },
    warningDescription: {
      display: 'inline-flex',
      color: '#665622'
    },
    checkbox: {
      color: colors.smoke[700]
    }
  }),
  { name: 'RaButton' }
);

export default useStyles;
