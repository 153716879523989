import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  useTranslate,
  required,
  ArrayInput,
  FormWithRedirect,
  SaveButton
} from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core/';
import { useForm } from 'react-final-form';
import get from 'lodash/get';
import AutocompleteInput from '../../../atoms/autocomplete-input';
import DraggableFormIterator from '../../../atoms/draggle-form-iterator/DraggableFormIterator';
import { useStyles } from './styles';
import { loadingType } from '../../../../constants';

const TransshipmentDestinationDialog = ({
  open,
  destinations,
  onClose,
  source
}) => {
  const translate = useTranslate();
  const style = useStyles();
  const form = useForm();

  const loadingOrder = get(form.getState().values, source);
  const dialogTranslationPath = 'dialogs.transshipment_destinations';
  const actionTranslationPath = 'ra.action';

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      classes={{ paper: style.dialog }}
      maxWidth
    >
      <DialogTitle id="form-dialog-title">
        {translate(`${dialogTranslationPath}.title`)}
      </DialogTitle>
      <FormWithRedirect
        resource="facility"
        save={data => {
          form.change(
            source,
            data.loadingOrder.map(item => ({
              ...item,
              facility: destinations.find(
                destination => destination.id === item.facility.id
              )
            }))
          );

          onClose();
        }}
        record={{ loadingOrder }}
        render={({ handleSubmitWithRedirect }) => (
          <>
            <DialogContent>
              <DialogContentText>
                {translate(`${dialogTranslationPath}.content`)}
              </DialogContentText>

              <ArrayInput source="loadingOrder" label="" resource="facility">
                <DraggableFormIterator
                  textAddNewItem={`${dialogTranslationPath}.add_button`}
                >
                  {/* Destination */}
                  <AutocompleteInput
                    source="facility.id"
                    label={`${dialogTranslationPath}.label`}
                    options={destinations}
                    optionText={choice => choice.longName}
                    shouldRenderSuggestions={value => value.trim().length >= 2}
                    validate={required()}
                    formClassName={style.inlineBlock}
                    className={style.autocomplete}
                  />

                  {/* Type */}
                  <AutocompleteInput
                    source="mixed"
                    label={`${dialogTranslationPath}.type`}
                    options={loadingType}
                    defaultValue // Carga misturada
                    optionText={choice => choice.name}
                    optionValue="mixed"
                    shouldRenderSuggestions={value => value.trim().length >= 2}
                    validate={required()}
                    formClassName={style.inlineBlock}
                    className={style.autocomplete}
                  />
                </DraggableFormIterator>
              </ArrayInput>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onClose}
                size="medium"
                label={`${actionTranslationPath}.cancel`}
              />
              <SaveButton
                variant="contained"
                size="medium"
                color="primary"
                label={`${actionTranslationPath}.save`}
                icon={<div />}
                handleSubmitWithRedirect={handleSubmitWithRedirect}
              />
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  );
};

TransshipmentDestinationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  source: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default TransshipmentDestinationDialog;
