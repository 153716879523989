import React from 'react';
import { useTranslate } from 'react-admin';
import {
  Avatar,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ExpandMore } from '@material-ui/icons';
import { useChangelogStyles } from '../../styles';
import ItemsChangedList from './route-form-changelog-items-changed-list';

const ChangelogAccordion = ({
  changelogItem,
  changelogPanelId,
  expanded,
  onChange
}) => {
  const styles = useChangelogStyles();
  const translate = useTranslate();

  const translatePath = 'resources.route.changelog';
  const translateFieldsPath = 'resources.route.fields';

  const {
    modifiedByUserNickname,
    changedKeysNames,
    operation,
    modifiedDateHuman,
    modifiedTimeHuman,
    changeMap,
    changeReason,
    changeReasonDescription
  } = changelogItem;

  const user = modifiedByUserNickname.split('.');

  const getEditedFieldNames = () => {
    const translatedKeyNames = [];
    changedKeysNames.forEach(keyName => {
      translatedKeyNames.push(
        translate(`${translateFieldsPath}.${keyName}`).toLowerCase()
      );
    });

    if (translatedKeyNames.length === 1) return translatedKeyNames[0];

    return translate(`${translatePath}.key_names_plural`, {
      items: translatedKeyNames.slice(0, -1).join(', '),
      last_item: translatedKeyNames.slice(-1)
    });
  };

  return (
    <Accordion
      onChange={onChange(changelogPanelId)}
      expanded={expanded === changelogPanelId}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box className={styles.panelSummary}>
          <Avatar
            className={
              expanded === changelogPanelId
                ? `${styles.nameIcon} ${styles.nameIconColor}`
                : styles.nameIcon
            }
          >
            {`${user[0].charAt(0).toUpperCase()}${user[1]
              .charAt(0)
              .toUpperCase()}`}
          </Avatar>

          <Typography
            component="span"
            className={styles.summaryTitle}
            data-testid="changelogAccordionNickname"
          >
            {modifiedByUserNickname}&nbsp;
            <Box component="strong" data-testid="changelogAccordionOperation">
              {translate(`${translatePath}.action_id.${operation}`, {
                reason: changeReason
              })}
            </Box>
            &nbsp;
            {// Add the edited fields after update text
            operation === 'ROUTE_CHANGELOG_OPERATION_TYPE_UPDATE' &&
            changedKeysNames.length > 0
              ? getEditedFieldNames()
              : translate(`${translatePath}.action_route_complement`)}
            <Box className={styles.summaryDate}>
              {translate(`${translatePath}.dateAndTime`, {
                date: modifiedDateHuman,
                time: modifiedTimeHuman
              })}
            </Box>
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={styles.panelDetails}>
          {changeMap && changeMap.length > 0 && (
            <Typography component="span">
              <Box>
                <span className={styles.detailTitle}>
                  {translate(`${translatePath}.changes`)}
                </span>
                <ItemsChangedList
                  changeMap={changeMap}
                  keyNames={changedKeysNames}
                  translatePath={translatePath}
                  translateFieldsPath={translateFieldsPath}
                />
              </Box>
            </Typography>
          )}

          {changeReason && (
            <Typography component="span">
              <span className={styles.detailTitle}>
                {translate(`${translatePath}.reason`)}
              </span>
              <span
                className={styles.changeReason}
                data-testid="changelogAccordionReason"
              >{`"${changeReason}"`}</span>
              <span
                className={styles.changeReasonDescription}
                data-testid="changelogAccordionReasonDescription"
              >
                {changeReasonDescription
                  ? `"${changeReasonDescription}"`
                  : null}
              </span>
            </Typography>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

ChangelogAccordion.propTypes = {
  changelogItem: PropTypes.shape({
    routeId: PropTypes.string.isRequired,
    modifiedByUserNickname: PropTypes.string.isRequired,
    operation: PropTypes.string.isRequired,
    modifiedDateHuman: PropTypes.string.isRequired,
    modifiedTimeHuman: PropTypes.string.isRequired,
    changeReason: PropTypes.string.isRequired,
    changeReasonDescription: PropTypes.string,
    changeMap: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        before: PropTypes.string,
        after: PropTypes.string
      })
    ),
    changedKeysNames: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  changelogPanelId: PropTypes.string.isRequired,
  expanded: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ChangelogAccordion;
