import {
  BRZ_DATETIME_FORMAT,
  BRZ_TIME_FORMAT,
  BRZ_DATE_FORMAT,
  convertDurationToHumanString,
  convertDurationToTimeString,
  convertTimeStringToDuration,
  convertUtcToBrz,
  convertBrzToUtc
} from '../utils/date-format';
import facilityAdapter from './facility-adapter';
import routeFrequencyAdapter from './route-frequency-adapter';
import {
  convertDecimalToMoneyProto,
  convertMoneyProtoToDecimal
} from '../utils/currency-format';
import { weekdays } from '../constants';

const convertFromServer = route => {
  const weekday = convertUtcToBrz(route.arrivalTime, 'd');
  const arrivalDay = weekdays.find(day => weekday === day.momentId.toString());

  return {
    ...route,
    weekday,
    arrivalWeekdayName: convertUtcToBrz(route.arrivalTime, 'ddd').replace(
      /\b[a-z]/,
      match => match.toUpperCase()
    ),
    arrivalWeekdayId: arrivalDay.id,
    originArrivalTimeHourFormatted: convertUtcToBrz(
      route.arrivalTime,
      BRZ_TIME_FORMAT
    ),
    departureWeekdayName: convertUtcToBrz(route.departureTime, 'ddd').replace(
      /\b[a-z]/,
      match => match.toUpperCase()
    ),
    originDepartureTimeHourFormatted: convertUtcToBrz(
      route.departureTime,
      BRZ_TIME_FORMAT
    ),
    routeDurationHuman: convertDurationToHumanString(route.routeDuration),
    lastModifiedDateFormatted: convertUtcToBrz(
      route.lastModifiedTime,
      BRZ_DATE_FORMAT
    ),
    hasChangesToBePublished:
      route.publicationState === 'ROUTE_PUBLICATION_STATE_DRAFT' &&
      !route.archived &&
      route.state !== 'ROUTE_STATE_CANCELLED',
    originArrivalTime: convertUtcToBrz(route.arrivalTime, 'YYYY-MM-DDTHH:mm'),
    loadingDuration: convertDurationToTimeString(route.loadingDuration),
    departureTime: convertUtcToBrz(route.departureTime, 'YYYY-MM-DDTHH:mm'),
    loadingStartTime: convertUtcToBrz(
      route.loadingStartTime,
      'YYYY-MM-DDTHH:mm'
    ),
    loadingEndTimeFormatted: convertUtcToBrz(
      route.loadingEndTime,
      BRZ_DATETIME_FORMAT
    ),
    originDepartureTimeFormatted: convertUtcToBrz(
      route.departureTime,
      BRZ_DATETIME_FORMAT
    ),
    releaseVehicleDuration: convertDurationToTimeString(
      route.releaseVehicleDuration
    ),
    merchandiseValue: convertMoneyProtoToDecimal(route.merchandiseValue),
    origin: facilityAdapter.fromServer(route.origin),
    transfers: route.transfers.map(transfer => ({
      ...transfer,
      arrivalTimeFormatted: convertUtcToBrz(
        transfer.arrivalTime,
        BRZ_DATETIME_FORMAT
      ),
      departureTimeFormatted: convertUtcToBrz(
        transfer.departureTime,
        BRZ_DATETIME_FORMAT
      ),
      travelDuration: convertDurationToTimeString(transfer.travelDuration),
      unloadingDuration: convertDurationToTimeString(
        transfer.unloadingDuration
      ),
      destination: facilityAdapter.fromServer(transfer.destination)
    })),
    frequency: routeFrequencyAdapter.fromServer(route.frequency),
    editable: route.editable || false,
    cancelable: route.cancelable || false,
    deletable: route.deletable || false,
    archivable: route.archivable || false,
    pendingReview: route.state === 'ROUTE_STATE_PENDING_REVIEW' || false,
    arrivalWeekdayFullName: arrivalDay.name
  };
};

export default {
  fromServer: data => {
    if (Array.isArray(data)) {
      return data.map(plan => convertFromServer(plan));
    }

    return convertFromServer(data);
  },
  toServer: route => {
    const {
      loadingEndTime,
      frequency,
      updateReasonId,
      updateReasonDescription,
      ...toServerRoute
    } = route;

    return {
      route: {
        ...toServerRoute,
        origin: facilityAdapter.toServer(route.origin),
        loadingDuration: convertTimeStringToDuration(route.loadingDuration),
        arrivalTime: convertBrzToUtc(route.originArrivalTime),
        loadingStartTime: route.loadingStartTime
          ? convertBrzToUtc(route.loadingStartTime)
          : null,
        merchandiseValue: convertDecimalToMoneyProto(route.merchandiseValue),
        departureTime: route.departureTime
          ? convertBrzToUtc(route.departureTime)
          : null,
        releaseVehicleDuration: convertTimeStringToDuration(
          route.releaseVehicleDuration
        ),
        transfers: route.transfers.map(transfer => ({
          ...transfer,
          destination: facilityAdapter.toServer(transfer.destination),
          travelDuration: convertTimeStringToDuration(transfer.travelDuration),
          unloadingDuration: convertTimeStringToDuration(
            transfer.unloadingDuration
          )
        })),
        frequency: routeFrequencyAdapter.toServer(frequency)
      },
      updateReasonId,
      updateReasonDescription
    };
  }
};
