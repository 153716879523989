import PropTypes from 'prop-types';

export const defaultRouteFilters = {
  origin: [],
  transfer: [],
  carrier: '',
  state: [
    'ROUTE_STATE_PLANNED',
    'ROUTE_STATE_PUBLISHED',
    'ROUTE_STATE_ACTIVE',
    'ROUTE_STATE_COMPLETED',
    'ROUTE_STATE_PENDING_REVIEW'
  ],
  vehicleType: '',
  returnRoute: '',
  frequency: []
};

export const recordPropType = PropTypes.shape({
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      carrierName: PropTypes.string.isRequired,
      departureTimeHour: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      lastModifiedByUser: PropTypes.string.isRequired,
      lastModifiedDateFormatted: PropTypes.string.isRequired,
      loadingDuration: PropTypes.string.isRequired,
      origin: PropTypes.shape({
        routingCode: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired,
      routeDistanceInKm: PropTypes.number.isRequired,
      routeDurationHuman: PropTypes.string.isRequired,
      transfers: PropTypes.arrayOf(
        PropTypes.shape({
          arrivalTime: PropTypes.string.isRequired,
          departureTime: PropTypes.string.isRequired,
          destination: PropTypes.shape({
            id: PropTypes.number.isRequired,
            routingCode: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
          }),
          distanceInKm: PropTypes.number.isRequired,
          travelDuration: PropTypes.string.isRequired,
          unloadingDuration: PropTypes.string.isRequired
        })
      ).isRequired,
      vehicleType: PropTypes.string.isRequired,
      archived: PropTypes.bool.isRequired,
      returnRoute: PropTypes.bool.isRequired,
      frequency: PropTypes.string.isRequired
    })
  ),
  weekdays: PropTypes.arrayOf(PropTypes.string).isRequired,
  origins: PropTypes.arrayOf(PropTypes.string).isRequired,
  carriers: PropTypes.arrayOf(PropTypes.string).isRequired,
  transfers: PropTypes.arrayOf(PropTypes.string).isRequired,
  state: PropTypes.arrayOf(PropTypes.string).isRequired,
  returnRoute: PropTypes.arrayOf(PropTypes.bool).isRequired,
  allowPickups: PropTypes.bool.isRequired
});

export default { defaultRouteFilters };
