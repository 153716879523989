import React from 'react';
import PropTypes from 'prop-types';
import { Button, useTranslate } from 'react-admin';
import { Box, Tooltip, Typography } from '@material-ui/core';

const ActionButton = ({ text, disabled, onClick, requiresTooltip }) => {
  const translate = useTranslate();

  if (requiresTooltip)
    return (
      <Tooltip
        title={
          <Typography component="div">
            <Box>
              {translate(text.tooltip.content)}
              <Box component="strong">
                {translate(text.tooltip.content_day)}
              </Box>
            </Box>
          </Typography>
        }
        placement="top-start"
      >
        <Box>
          <Button
            label={text.label}
            disabled={disabled}
            onClick={() => onClick(true)}
            variant="contained"
            size="large"
          />
        </Box>
      </Tooltip>
    );

  return (
    <Button
      label={text.label}
      disabled={disabled}
      onClick={() => onClick(true)}
      variant="contained"
      size="medium"
    />
  );
};

ActionButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  text: PropTypes.shape({
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.shape({
      content: PropTypes.string.isRequired,
      content_day: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  requiresTooltip: PropTypes.bool.isRequired
};

export default ActionButton;
