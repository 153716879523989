import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { AutocompleteInput } from 'react-admin';

const DockInput = props => {
  const { source, docks, ...otherProps } = props;
  const form = useForm();
  const { values } = form.getState();

  // If the form has been changed and the dock list no longer has the dock, clear the dock field.
  if (form.getState().dirty) {
    if (!docks || !docks.includes(values[source])) {
      form.change('dock', '');
    }
  }

  const choices = docks ? docks.map(item => ({ id: item, name: item })) : [];

  return (
    <AutocompleteInput
      source={source}
      choices={choices}
      disabled={choices.length === 0}
      allowEmpty
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  );
};

DockInput.propTypes = {
  docks: PropTypes.arrayOf(PropTypes.string).isRequired,
  source: PropTypes.string.isRequired
};

export default DockInput;
