import { makeStyles } from '@material-ui/styles';
import { colors } from '@loggi/mar';

export const routePlanShowStyles = makeStyles(() => ({
  root: {
    '& .Archived': {
      backgroundColor: colors.smoke['50'],
      color: colors.smoke['700']
    }
  },
  routeShowGrid: {
    fontSize: '12px'
  },
  routeShowToolbar: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& >div': {
      marginBottom: '24px'
    },

    '& span': {
      fontSize: '14px'
    }
  },
  addRouteButton: {
    fontSize: '14px',
    fontWeight: '600',

    '& svg': {
      height: '25px',
      width: '25px'
    }
  },
  allFiltersGrid: {
    rowGap: '16px',
    display: 'flex',
    flexDirection: 'column'
  },
  mainFiltersGrid: {
    columnGap: '8px',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    minWidth: '650px',
    width: '100%',

    '& >*': {
      flex: '1'
    }
  },
  clearFiltersButton: {
    marginRight: '4px'
  },
  moreFiltersBox: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      padding: '8px 20px'
    }
  },
  selectedFiltersButton: {
    backgroundColor: colors.blue['50']
  },
  moreFiltersButton: {
    marginRight: '4px'
  },
  additionalAutocompleteInput: {
    width: 'fit-content'
  },
  reviewAlert: {
    display: 'inline-flex',

    '& >:first-child': {
      fontWeight: 'bold'
    }
  },
  link: {
    background: 'none!important',
    border: 'none',
    color: colors.blue['500'],
    font: 'inherit',
    fontWeight: 'bold',
    padding: '0!important',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));

export const collapseStyles = makeStyles(() => ({
  container: {
    backgroundColor: colors.smoke[50],
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    padding: '8px'
  },

  wrapperInner: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px'
  }
}));

export const multiAutocompleteStyle = makeStyles(() => ({
  inputRoot: {
    width: 'fit-content',
    paddingRight: '150px!important',
    minWidth: '20%'
  },

  input: {
    minWidth: '150px'
  }
}));

export const alertStyles = makeStyles({
  root: {
    width: '260px',
    padding: '8px 16px',
    borderRadius: '8px'
  },
  icon: {
    '& > svg': {
      fontSize: '18px'
    }
  },
  message: {
    fontSize: '12px',
    fontFamily: 'Open Sans'
  }
});

export const bigAlertStyles = makeStyles({
  root: {
    alignItems: 'center',
    height: 'fit-content',
    marginBottom: '24px',
    marginTop: '24px',
    maxWidth: '80%',
    minWidth: '380px',
    padding: '0px 20px',
    width: 'fit-content',

    message: {
      fontSize: '16px'
    }
  }
});

export const chipStyles = makeStyles({
  declared: {
    margin: '0px 8px 0px 8px',
    color: '#757D8C',
    backgroundColor: '#E7EDF4'
  }
});

export const routePlanHeaderStyles = makeStyles({
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  weekStateBox: {
    marginBottom: '20px',

    '& h2': {
      margin: '0px'
    },

    '& div': {
      marginBottom: '3px'
    },

    '& .state-dot': {
      margin: '10px 0px'
    }
  }
});
