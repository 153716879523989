import React, { useState } from 'react';
import { Button, useMutation } from 'react-admin';
import { useHistory } from 'react-router-dom';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PropTypes from 'prop-types';
import MassDuplicationDialog from './mass-duplication-dialog';
import { weekdays } from '../../../constants';

const CloneRouteButton = ({
  selectedRoutes,
  resource,
  onSuccess,
  routes,
  classes,
  routePlanDate,
  active,
  routePlanId,
  allowPickups,
  requiresJustification,
  ...props
}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [{ loading }] = useMutation();

  const SUNDAY = weekdays[6].id;
  const SATURDAY = weekdays[5].id;

  const startWeekday = active ? new Date().getDay() : SUNDAY;
  const multipleRoutesSelected = selectedRoutes.length > 1;
  const disableButtonMultipleRoutes =
    multipleRoutesSelected && startWeekday === SATURDAY;

  // Opens the detail page
  const cloneSingle = () => {
    const routeId = selectedRoutes[0];
    const route = routes.filter(r => r.id === routeId)[0];
    delete route.createdByUser;
    const source = JSON.stringify({
      routePlanDate,
      ...route
    });

    history.push({
      pathname: `/${resource}/${routePlanId}/routes/create`,
      search: `?source=${source}&allowPickups=${allowPickups}&requiresJustification=${requiresJustification}`
    });
  };

  return (
    <>
      <Button
        label="resources.route-plans.clone_route"
        disabled={selectedRoutes.length === 0 || disableButtonMultipleRoutes}
        onClick={() =>
          !multipleRoutesSelected ? cloneSingle() : setOpen(true)
        }
        startIcon={<FileCopyIcon />}
        className={classes}
      />

      <MassDuplicationDialog
        disabled={loading}
        isOpen={open}
        onClose={() => setOpen(false)}
        onSuccess={onSuccess}
        startWeekday={startWeekday}
        selectedRoutes={selectedRoutes}
        routes={routes}
        routePlanId={routePlanId}
        allowPickups={allowPickups}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </>
  );
};

CloneRouteButton.propTypes = {
  selectedRoutes: PropTypes.arrayOf(PropTypes.string).isRequired,
  routePlanId: PropTypes.string.isRequired,
  allowPickups: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  requiresJustification: PropTypes.bool.isRequired,
  resource: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ).isRequired,
  classes: PropTypes.string,
  routePlanDate: PropTypes.string.isRequired
};

CloneRouteButton.defaultProps = {
  classes: undefined,
  onSuccess: undefined
};

export default CloneRouteButton;
