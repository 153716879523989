import React from 'react';
import { useTranslate } from 'react-admin';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ArrowForward, FiberManualRecord } from '@material-ui/icons';
import { useChangelogStyles } from '../../styles';

const ItemChanged = ({ change, translatePath }) => {
  const styles = useChangelogStyles();
  const translate = useTranslate();

  const { before, after, isTransfer, isDateTimeLocalSpanNeeded } = change;

  // If the field requires date and time data to be shown, these values will be separated by a |
  const afterSpanText = isDateTimeLocalSpanNeeded
    ? translate(`${translatePath}.transfer_local_datetime`, {
        datetime: after.split('|')[0],
        local: after.split('|')[1]
      })
    : after;

  /*
      Add a dot before the text if the item changed is a Transfer
      and return the component stylized according to if the change was
      to remove the item, to add the item or to replace the item
  */

  if (before && !after) {
    return (
      <Box className={styles.detailChange}>
        {isTransfer && <FiberManualRecord className={styles.dot} />}
        <span
          className={styles.detailChangeBefore}
          data-testid="itemChangedBefore"
        >
          {before}
        </span>
      </Box>
    );
  }

  if (!before && after) {
    return (
      <Box className={styles.detailChange}>
        {isTransfer && <FiberManualRecord className={styles.dot} />}
        <span data-testid="itemChangedAfter">{afterSpanText}</span>
      </Box>
    );
  }

  return (
    <Box className={styles.detailChange}>
      {isTransfer && <FiberManualRecord className={styles.dot} />}
      <span
        className={styles.detailChangeBefore}
        data-testid="itemChangedBefore"
      >
        {before}
      </span>
      <ArrowForward className={styles.arrowForward} />
      <span data-testid="itemChangedAfter">{afterSpanText}</span>
    </Box>
  );
};

ItemChanged.propTypes = {
  change: PropTypes.shape({
    key: PropTypes.string.isRequired,
    before: PropTypes.string,
    after: PropTypes.string,
    isTransfer: PropTypes.bool,
    isDateTimeLocalSpanNeeded: PropTypes.bool
  }).isRequired,
  translatePath: PropTypes.string.isRequired
};

export default ItemChanged;
