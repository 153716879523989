import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import autocompleteInputStyles from './styles';

const FilterAutocompleteInput = ({
  defaultIdValue,
  label,
  name,
  options,
  onChange,
  multiple,
  optionTextProp,
  classes,
  ...props
}) => {
  const findOptions = useCallback(
    selectedValue => {
      if (multiple) {
        return (
          options.filter(selectOption =>
            selectedValue?.includes(selectOption.id)
          ) || []
        );
      }

      const option = options.find(
        selectOption => selectOption.id === selectedValue
      );
      return option || '';
    },
    [multiple, options]
  );

  const [value, setValue] = useState(
    defaultIdValue === '' || !defaultIdValue ? '' : findOptions(defaultIdValue)
  );
  const [inputValue, setInputValue] = useState(
    multiple || !value?.[optionTextProp] ? '' : value?.[optionTextProp]
  );

  useEffect(() => {
    if (!multiple) {
      const defaultObject = findOptions(defaultIdValue);
      setValue(defaultObject);
      setInputValue(defaultObject?.[optionTextProp] || '');
      return;
    }
    const defaultObjects = options.filter(option =>
      defaultIdValue.includes(option.id)
    );
    setValue(defaultObjects);
  }, [defaultIdValue, findOptions, multiple, optionTextProp, options]);

  const onInputChange = (_, newInputValue) => {
    setInputValue(newInputValue || '');
  };

  const onChangeOption = (_, newValue) => {
    if (!multiple) {
      setValue(newValue || '');
      onChange(name, newValue?.id);
      setInputValue(newValue?.[optionTextProp] || '');
    } else {
      setValue(newValue || []);
      onChange(name, newValue.map(selectOption => selectOption.id));
      setInputValue('');
    }
  };

  // the purpose of this class is to solve the warning that appears when the value === ''
  const optionBackgroundColorClass = autocompleteInputStyles();

  const showOptionBackgroundColorClass = useMemo(
    () => (!multiple && value === '' ? optionBackgroundColorClass : {}),
    [multiple, value, optionBackgroundColorClass]
  );

  return (
    <Autocomplete
      size="small"
      inputValue={inputValue}
      onInputChange={onInputChange}
      options={options.sort()}
      value={value}
      onChange={onChangeOption}
      filterSelectedOptions={multiple}
      fullWidth
      getOptionLabel={option => option?.[optionTextProp] || ''}
      getOptionSelected={(option, selectValue) =>
        selectValue === '' || option.id === selectValue?.id
      }
      renderOption={option => option[optionTextProp]}
      multiple={multiple}
      renderInput={params => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} label={label} variant="outlined" name={name} />
      )}
      classes={{ ...classes, ...showOptionBackgroundColorClass }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

FilterAutocompleteInput.propTypes = {
  defaultIdValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  closeFilter: PropTypes.func,
  optionTextProp: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string)
};

FilterAutocompleteInput.defaultProps = {
  defaultIdValue: '',
  multiple: false,
  optionTextProp: 'name',
  closeFilter: undefined,
  classes: {}
};

export default FilterAutocompleteInput;
