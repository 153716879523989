import { Loading } from 'react-admin';
import PropTypes from 'prop-types';
import { Backdrop, withStyles } from '@material-ui/core';
import React from 'react';

const CustomBackdrop = withStyles(() => ({
  root: {
    zIndex: '1500'
  }
}))(Backdrop);

const BackdropLoading = ({ open, message }) => (
  <CustomBackdrop open={open}>
    <Loading loadingPrimary="" loadingSecondary={message} />
  </CustomBackdrop>
);

BackdropLoading.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
};

export default BackdropLoading;
