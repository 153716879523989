import { makeStyles } from '@material-ui/styles';
import { colors } from '@loggi/mar';

import theme from '../../theme/transport-planner-theme';

export const useStyles = makeStyles({
  root: {
    padding: 0,
    marginBottom: 0,
    '& > li:last-child': {
      borderBottom: 'none'
    },
    marginBlockStart: '0px'
  },
  line: {
    backgroundColor: colors.smoke[50],
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    listStyleType: 'none',
    width: 'fit-content',
    [theme.breakpoints.down('xs')]: { display: 'block' },

    '& >:first-child': {
      marginTop: '0px'
    },

    '&.fade-enter': {
      opacity: 0.01,
      transform: 'translateX(100vw)'
    },
    '&.fade-enter-active': {
      opacity: 1,
      transform: 'translateX(0)',
      transition: 'all 500ms ease-in'
    },
    '&.fade-exit': {
      opacity: 1,
      transform: 'translateX(0)'
    },
    '&.fade-exit-active': {
      opacity: 0.01,
      transform: 'translateX(100vw)',
      transition: 'all 500ms ease-in'
    }
  },
  lineMainContent: {
    display: 'flex',

    '& >section': {
      marginTop: '16px'
    }
  },
  index: {
    fill: colors.smoke[500],
    margin: 'auto 0!important',
    width: '3em',
    [theme.breakpoints.down('sm')]: { display: 'none' }
  },
  actionsToolbar: {
    columnGap: '16px',
    display: 'inline-flex',
    marginTop: '16px'
  },
  action: {
    margin: 'auto 0',
    paddingTop: '0.5em'
  },
  addAction: {
    height: '100%',
    fontSize: '14px',
    fontWeight: '600',

    '& svg': {
      height: '25px',
      width: '25px'
    }
  },
  leftIcon: {
    fill: colors.smoke[500],
    marginRight: theme.spacing(1)
  }
});

export default useStyles;
