import React, { useEffect, useState } from 'react';
import { useMutation, useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { Box } from '@material-ui/core';
import { useRemoteConfig } from '@loggi/components/src/one/remote-config';
import AutocompleteInput from '../../../atoms/autocomplete-input';
import { useStyles } from './styles';
import TransshipmentDestinationDialog from './transshipment-destination-dialog';
import TransshipmentDestinationText from './transshipment-destination-text';

const TransferDestinationInput = ({
  facilities,
  allowPickups,
  source,
  ...props
}) => {
  const [value, setValue] = useState({});
  const notify = useNotify();
  const style = useStyles();
  const form = useForm();

  const { value: fsValue } = useRemoteConfig('enable_loading_order');
  const enableLoadingOrder = JSON.parse(fsValue) && !allowPickups && value.id;

  const [transshipmentDestinations, setTransshipmentDestinations] = useState(
    []
  );
  const [openDialog, setOpenDialog] = useState(false);

  const [fetchTransshipmentDestinations] = useMutation(
    {
      type: 'getTransshipmentDestinations',
      resource: 'transshipmentDestinations',
      payload: { data: { facilityId: value.id } }
    },
    {
      onSuccess: ({ data }) => {
        setTransshipmentDestinations(data);
      },
      onFailure: () =>
        notify('ra.message.not_able_to_load_transshipment_list', 'warning')
    }
  );

  const onSelectedValue = selectedValue => {
    setValue(selectedValue);
    if (enableLoadingOrder) {
      form.change(`${source}.loadingOrder`, selectedValue.loadingOrder);
    }
  };

  useEffect(() => {
    if (enableLoadingOrder) {
      fetchTransshipmentDestinations();
    }
  }, [fetchTransshipmentDestinations, enableLoadingOrder]);

  return (
    <>
      <Box className={style.root}>
        <AutocompleteInput
          onSelectedValue={onSelectedValue}
          options={facilities}
          allowPickups={allowPickups}
          source={`${source}.id`}
          {...props} // eslint-disable-line react/jsx-props-no-spreading
        />
        {enableLoadingOrder && (
          <>
            <TransshipmentDestinationText
              hasTransshipment={transshipmentDestinations?.length > 0}
              source={`${source}.loadingOrder`}
              onClick={() => setOpenDialog(true)}
            />
            <TransshipmentDestinationDialog
              open={openDialog}
              destinations={transshipmentDestinations}
              onClose={() => setOpenDialog(false)}
              source={`${source}.loadingOrder`}
            />
          </>
        )}
      </Box>
    </>
  );
};

TransferDestinationInput.propTypes = {
  facilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  allowPickups: PropTypes.bool.isRequired,
  source: PropTypes.string.isRequired
};

export default TransferDestinationInput;
