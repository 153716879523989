export const ERRORS = {
  MULTIPLE_SUMMARIES:
    'Template component must receive only one child of TemplateSummary type',
  NO_SUMMARY: 'Template component must receive a TemplateSummary as child',
  UNSUPPORTED_CHILD: 'An unsupported child was used in Template'
};

export default {
  ERRORS
};
