/* eslint-disable react/forbid-prop-types, react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useInput, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useForm } from 'react-final-form';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { FieldTitle } from 'ra-core';

const AutocompleteInput = ({
  source,
  options,
  optionText,
  optionValue,
  filterValues,
  onSelectedValue,
  defaultValue,
  suggestionLimit,
  label,
  resource,
  validationError,
  onOpen,
  ...props
}) => {
  const {
    input,
    meta: { error, invalid, touched },
    isRequired
  } = useInput({
    source,
    resource,
    label,
    ...props
  });

  const [value, setValue] = useState(null);
  const form = useForm();
  const translate = useTranslate();

  const onChangeOption = (_, newValue) => {
    setValue(newValue);
    form.change(source, newValue[optionValue]);
    if (onSelectedValue) {
      onSelectedValue(newValue);
    }
  };

  useEffect(() => {
    const selectedValue = get(form.getState().values, source) ?? defaultValue;
    if (selectedValue !== null) {
      const selectedLocation = options.find(
        f => f[optionValue] === selectedValue
      );
      if (selectedLocation) {
        onChangeOption(null, selectedLocation);
      } else if (options.length > 0) {
        // reset form field if value is not present on options
        form.change(source, null);
      }
    }
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  const filterOptions = createFilterOptions({
    limit: suggestionLimit
  });

  return (
    <Autocomplete
      {...input}
      size="small"
      disableClearable
      options={options}
      getOptionLabel={optionText}
      getOptionSelected={(option, currentValue) =>
        option[optionValue] === currentValue[optionValue]
      }
      filterOptions={filterOptions}
      {...props}
      value={value}
      onChange={onChangeOption}
      renderOption={optionText}
      onOpen={() => {
        if (onOpen) onOpen();
      }}
      renderInput={params => (
        <TextField
          variant={props.variant}
          label={
            <FieldTitle label={label} source={source} resource={resource} />
          }
          error={!!(invalid && error && touched)}
          helperText={touched && error ? translate(validationError) : ''}
          required={isRequired}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off' // disable autocomplete and autofill
          }}
          {...params}
        />
      )}
    />
  );
};

AutocompleteInput.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
  optionText: PropTypes.func,
  optionValue: PropTypes.string,
  filterValues: PropTypes.object,
  defaultValue: PropTypes.string,
  onSelectedValue: PropTypes.func,
  suggestionLimit: PropTypes.number,
  validationError: PropTypes.string,
  onOpen: PropTypes.func
};

AutocompleteInput.defaultProps = {
  optionText: choice => choice.name,
  optionValue: 'id',
  filterValues: {},
  options: [],
  defaultValue: '',
  variant: 'outlined',
  suggestionLimit: undefined,
  validationError: 'ra.validation.required',
  onSelectedValue: undefined,
  onOpen: undefined
};

export default AutocompleteInput;
