// Update or add columns preference in localStorage
const columnCustomization = params => {
  const column = params.colDef;
  if ('customColumns' in localStorage) {
    let updated = false;
    const customColumns = JSON.parse(localStorage.getItem('customColumns'));
    localStorage.removeItem('customColumns');

    const customColumnsToUpdate = customColumns.map(col => {
      const colToUpdate = col;
      if (colToUpdate[`${column.field}`]) {
        colToUpdate[`${column.field}`] = column.hide;
        updated = true;
      }
      return colToUpdate;
    });

    if (!updated) {
      customColumnsToUpdate.push({
        [column.field]: column.hide
      });
    }

    localStorage.setItem(
      'customColumns',
      JSON.stringify(customColumnsToUpdate)
    );
  } else {
    const customColumns = [];
    customColumns.push({ [column.field]: column.hide });
    localStorage.setItem('customColumns', JSON.stringify(customColumns));
  }
};

export default columnCustomization;
