/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormDataConsumer,
  FormWithRedirect,
  required,
  SaveButton,
  TextInput,
  useTranslate
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './styles';
import AutocompleteInputRemote from '../autocomplete-input-remote';
import { dialogButtonMakeStyles } from '../../theme/transport-planner-theme';

// As SaveButton can only be used inside of a Edit or a Create in other situations
// we would need to use a normal Button
const SaveButtonComponent = ({
  isInsideRouteForm,
  handleSubmitWithRedirect,
  ...buttonProps
}) => {
  return isInsideRouteForm ? (
    <SaveButton
      handleSubmitWithRedirect={handleSubmitWithRedirect}
      {...buttonProps}
    />
  ) : (
    <Button onClick={handleSubmitWithRedirect} {...buttonProps} />
  );
};

SaveButtonComponent.propTypes = {
  isInsideRouteForm: PropTypes.bool.isRequired,
  handleSubmitWithRedirect: PropTypes.func.isRequired
};

const ReasonDialog = ({
  isOpen,
  onClose,
  onConfirm,
  contentKey,
  reference,
  sourceReasonId,
  sourceReasonDescription,
  type,
  filterValues,
  // this props is for when <ReasonDialog /> is used outside of <Create/> or <Edit/>
  isInsideRouteForm,
  ...props
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const buttonStyles = dialogButtonMakeStyles();
  const translationPath = `dialogs.reason_dialog.${contentKey}`;
  const [reason, setReason] = useState({});
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(!reason.requiresDescription);
  }, [reason]);

  const valueChanged = value => {
    setReason(value);
  };

  const onClickClose = () => {
    setReason({});
    onClose();
  };

  const validate = !isValid
    ? required(translate('dialogs.reason_dialog.reason_mandatory'))
    : undefined;

  return (
    <>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {translate(`${translationPath}.title`)}
        </DialogTitle>

        <FormWithRedirect
          resource="route"
          save={onConfirm}
          render={({ handleSubmitWithRedirect }) => (
            <>
              <DialogContent className={classes.dialog}>
                <DialogContentText>
                  {translate(`${translationPath}.content`)}
                </DialogContentText>
                <DialogContentText>
                  {translate(`${translationPath}.reason_title`)}
                </DialogContentText>

                <AutocompleteInputRemote
                  label={translate(`dialogs.reason_dialog.reason_label`)}
                  source={sourceReasonId}
                  reference={reference}
                  validate={required()}
                  validationError="ra.validation.selectReason"
                  variant="outlined"
                  fullWidth
                  onSelectedValue={valueChanged}
                  filterValues={filterValues}
                  groupBy={value => value.category}
                  {...props}
                />

                <FormDataConsumer>
                  {({ formData }) =>
                    formData[sourceReasonId] && (
                      <TextInput
                        label={translate(
                          `dialogs.reason_dialog.description_label`
                        )}
                        source={sourceReasonDescription}
                        multiline
                        key={`${reason.id}.${isValid}`}
                        variant="outlined"
                        rows={5}
                        className="description-input"
                        {...props}
                        fullWidth
                        validate={validate}
                      />
                    )
                  }
                </FormDataConsumer>
              </DialogContent>
              <DialogActions>
                <Button
                  className={buttonStyles}
                  onClick={onClickClose}
                  size="medium"
                  label={`${translationPath}.sub_action`}
                  data-testid="cancel_button"
                />
                <SaveButtonComponent
                  className={`${buttonStyles} ${classes[type]} ${type}`}
                  variant="contained"
                  size="medium"
                  color="primary"
                  label={`${translationPath}.action`}
                  icon={<div />}
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  isInsideRouteForm={isInsideRouteForm}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

ReasonDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentKey: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  sourceReasonId: PropTypes.string,
  sourceReasonDescription: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  filterValues: PropTypes.shape({
    allowPickups: PropTypes.bool
  }),
  isInsideRouteForm: PropTypes.bool
};

ReasonDialog.defaultProps = {
  sourceReasonId: 'reasonId',
  sourceReasonDescription: 'reasonDescription',
  disabled: false,
  type: 'primary',
  filterValues: undefined,
  isInsideRouteForm: true
};

export default ReasonDialog;
