/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  FormControlLabel,
  ButtonGroup
} from '@material-ui/core';
import { weekdays } from '../../../../constants';
import style from './style';

const WeekdayButtonGroup = props => {
  const translate = useTranslate();
  const form = useForm();

  const { disabled } = props;
  const orderedWeekdays = [...weekdays].sort((a, b) => a.id - b.id);

  const [selectedDays, setSelectedDays] = useState(
    form.getState().values.recurrenceWeekdays || []
  );
  const [allDaysChecked, setAllDaysChecked] = useState(
    !!form.getState().values.recurrenceWeekdays
  );

  const onClickDayButton = day => {
    if (
      selectedDays.find(selectedDay => selectedDay.googleId === day.googleId)
    ) {
      setSelectedDays(
        selectedDays.filter(
          selectedDay => selectedDay.googleId !== day.googleId
        )
      );
    } else {
      setSelectedDays(selectedDays.concat(day));
    }
  };

  const onCheckAllDays = e => {
    setSelectedDays(e.target.checked ? weekdays : []);
  };

  const getButtonStyle = isSelected => {
    const buttonBaseStyle = style.baseDayButton;
    if (isSelected && disabled)
      return { ...buttonBaseStyle, ...style.dayButtonSelectedDisabled };
    if (isSelected) return { ...buttonBaseStyle, ...style.dayButtonSelected };
    return { ...buttonBaseStyle, ...style.dayButtonNotSelected };
  };

  useEffect(() => {
    setAllDaysChecked(selectedDays.length === weekdays.length);
    form.change('recurrenceWeekdays', selectedDays.map(day => day.googleId));
  }, [selectedDays]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={style.selectDayArea}>
      <ButtonGroup variant="string" style={style.dayGroupButton}>
        {orderedWeekdays.map(day => (
          <Button
            style={getButtonStyle(
              selectedDays.find(
                selectedDay => selectedDay.googleId === day.googleId
              )
            )}
            onClick={() => onClickDayButton(day)}
            disabled={disabled}
            data-testid={`recurrence-button-day-${day.googleId}`}
          >
            {day.firstLetter}
          </Button>
        ))}
      </ButtonGroup>
      <FormControlLabel
        control={
          <Checkbox
            name="allDays"
            checked={allDaysChecked}
            data-testid="recurrence-checkbox-all-days"
          />
        }
        label={translate('resources.recurrence.allDays')}
        onClick={onCheckAllDays}
        disabled={disabled}
      />
    </div>
  );
};

WeekdayButtonGroup.propTypes = {
  disabled: PropTypes.bool.isRequired
};

export default WeekdayButtonGroup;
