import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  stateDot: {
    marginRight: '8px',
    width: '12px',
    height: '12px'
  }
}));

export default useStyles;
