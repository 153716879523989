import { createMuiTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import mar, { colors } from '@loggi/mar';
import { ptBR } from '@material-ui/data-grid';
import { pxToRem } from '@loggi/mar/src/utils';
import { MuiButton } from '@loggi/mar/src/overrides';

export const globalStyles = makeStyles(theme => ({
  stateDotBox: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    marginBottom: '30px',

    '& svg': {
      transform: 'scale(1.25)'
    }
  },
  menuOptionsMaxWidth: {
    backgroundColor: colors.red[200],

    '& ul': {
      minWidth: '280px'
    }
  },
  addButtonWithIcon: {
    height: '40px',
    fontSize: '14px',
    fontWeight: '600',

    '& svg': {
      height: '25px',
      width: '25px'
    }
  },
  boxTextLinkWithIcon: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    background: 'none!important',
    border: 'none',
    fontWeight: 600,
    padding: '0!important',
    width: 140,
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: 16,
    cursor: 'pointer',
    verticalAlign: 'center',
    color: colors.blue[500],
    align: 'center',
    '& svg': {
      height: 20,
      width: 20,
      marginRight: 8
    },
    '&.disabled': {
      cursor: 'default',
      color: colors.smoke[400]
    }
  },
  ROUTE_STATE_ARCHIVED: {
    color: colors.smoke[300]
  },
  ROUTE_STATE_PLANNED: {
    color: theme.palette.primary.main
  },
  ROUTE_STATE_PUBLISHED: {
    color: theme.palette.warning.main
  },
  ROUTE_STATE_ACTIVE: {
    color: theme.palette.success.main
  },
  ROUTE_STATE_CANCELLED: {
    color: theme.palette.error.main
  },
  ROUTE_STATE_COMPLETED: {
    color: colors.root[900]
  },
  ROUTE_STATE_PENDING_REVIEW: {
    color: '#CF5CE1'
  },
  ROUTE_PLAN_ACTIVATION_STATE_ACTIVE: {
    color: colors.green['500']
  },
  ROUTE_PLAN_ACTIVATION_STATE_DRAFT: {
    color: colors.blue['500']
  },
  ROUTE_PLAN_ACTIVATION_STATE_INACTIVE: {
    color: colors.smoke['300']
  }
}));

const overrides = {
  ...mar.overrides,
  MuiDrawer: {
    root: {
      marginRight: '8px'
    }
  },
  RaMenuItemLink: {
    active: {
      borderRadius: '8px',
      background: 'white',
      '& svg': {
        color: 'black'
      },
      boxShadow: mar.shadows['11']
    },
    root: {
      height: '55px',
      marginBottom: '8px'
    }
  },
  RaCheckboxGroupInput: {
    label: {
      fontWeight: 'bold',
      fontSize: '20px',
      color: 'black'
    }
  },
  MuiChip: {
    root: {
      height: '38px'
    },
    label: {
      paddingLeft: '16px',
      paddingRight: '8px'
    },
    sizeSmall: {
      height: '24px'
    },
    labelSmall: {
      paddingLeft: '8px',
      paddingRight: '8px'
    }
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '14px',
      fontWeight: '400',
      maxWidth: '284px',
      backgroundColor: '#2E3545',

      '& p': {
        margin: '0px'
      }
    }
  },
  MuiSwitch: {
    switchBase: {
      // Controls default (unchecked) color for the thumb
      color: colors.smoke[700]
    },
    colorSecondary: {
      '&$checked': {
        // Controls checked color for the thumb
        color: colors.blue[700]
      }
    },
    track: {
      // Controls default (unchecked) color for the track
      opacity: 0.6,
      backgroundColor: colors.smoke[300],
      '$checked$checked + &': {
        // Controls checked color for the track
        opacity: 0.6,
        backgroundColor: colors.blue[300]
      }
    }
  },
  MuiGridPanelFooter: {
    root: {
      display: 'none'
    }
  },
  MuiDialog: {
    paper: {
      fontFamily: 'Open Sans',
      paddingTop: '8px',
      width: '600px',
      maxWidth: '90%'
    }
  },
  MuiDialogTitle: {
    root: {
      fontSize: '20px',
      fontWeight: '700',
      paddingBottom: '8px'
    }
  },
  MuiDialogContent: {
    root: {
      paddingBottom: '0',

      '& > *': {
        marginBottom: '24px!important'
      },
      '& > :last-child': {
        marginBottom: '40px!important'
      }
    }
  },
  MuiDialogActions: {
    root: {
      padding: '0 24px 24px '
    }
  },
  RaConfirm: {
    confirmPrimary: {
      background: colors.gradients.sanches,
      color: colors.root['0'],
      padding: `${pxToRem(14)} ${pxToRem(20)}`
    }
  },
  MuiAutocomplete: {
    paper: {
      minWidth: '280px',
      maxHeight: '300px'
    },
    listbox: {
      minWidth: '280px',
      maxHeight: '300px'
    }
  },
  MuiToolbar: {
    root: {
      // background: `${colors.root['0']}!important`,
      columnGap: '24px',
      justifyContent: 'flex-end'
    }
  },
  MuiInputBase: {
    root: {
      '&.Mui-disabled': {
        backgroundColor: colors.smoke[100],
        color: `${colors.smoke[700]}!important`
      }
    }
  },
  MuiFormLabel: {
    root: {
      '&.Mui-disabled': {
        color: `${colors.smoke[700]}!important`
      }
    }
  },
  MuiMenu: {
    list: {
      maxHeight: '300px'
    }
  },
  RaSaveButton: {
    icon: {
      display: 'none'
    }
  },
  RaLoading: {
    message: {
      backgroundColor: 'white',
      opacity: 2,
      padding: '32px',
      borderRadius: '8px'
    }
  },
  RaButton: {
    label: {
      paddingLeft: '0px'
    }
  },
  MuiTabs: {
    root: {
      background: colors.smoke[50],
      borderRadius: '0rem'
    }
  },
  MuiTab: {
    root: {
      background: colors.smoke[25],
      borderRadius: '0.25rem',
      '&.Mui-selected': {
        background: colors.smoke[10],
        border: `0.125rem solid ${colors.smoke[100]}`
      }
    }
  },
  MuiCard: {
    root: {
      borderRadius: '0.25rem'
    }
  },
  RaCreate: {
    root: {
      '&.recurrence-form': {
        margin: '3rem auto',
        borderRadius: '0.25rem',
        width: '90rem'
      }
    }
  }
};

export const selectMaxWidth = makeStyles({
  MuiMenu: {
    list: {
      minWidth: '280px'
    }
  }
});

export const dialogButtonMakeStyles = makeStyles({
  MuiButton: { ...MuiButton }
});

export const backButtonStyle = {
  root: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  icon: {
    border: `0.125rem solid ${colors.blue[500]}`,
    borderRadius: '100%',
    padding: '0.25rem',
    color: colors.blue[500],
    cursor: 'pointer'
  },
  text: {
    color: colors.smoke[700],
    fontSize: '0.875rem',
    marginLeft: '0.5rem'
  }
};

const finalTheme = {
  ...mar,
  overrides: {
    ...overrides
  }
};

export default createMuiTheme(finalTheme, ptBR);
