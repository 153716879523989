import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxGroupInput, useTranslate } from 'react-admin';

const ReturnRouteInput = props => {
  const translate = useTranslate();
  const { source, description, allowPickups, ...otherProps } = props;

  const descriptionKey = `resources.route.${description}`;
  const choices = [{ id: source, name: translate(descriptionKey) }];
  const labelKey = allowPickups
    ? `resources.pickup-plans.fields.${source}`
    : `resources.transfer-plans.fields.${source}`;

  const optionParser = value => {
    return !!value.length;
  };

  const optionFormater = value => {
    return value ? [source] : [];
  };

  return (
    <CheckboxGroupInput
      label={translate(labelKey)}
      source={source}
      choices={choices}
      parse={optionParser}
      format={optionFormater}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  );
};

ReturnRouteInput.propTypes = {
  source: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  allowPickups: PropTypes.bool.isRequired
};

export default ReturnRouteInput;
