import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Button, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { dialogButtonMakeStyles } from '../../../../theme/transport-planner-theme';
import useStyles from '../../../../atoms/reason-dialog/styles';
import {
  addDaysToDateTime,
  BRZ_DATE_FORMAT,
  BRZ_TIME_FORMAT,
  convertDateTimeToStringAtStartOfWeek
} from '../../../../../utils/date-format';

const RouteFormAwarenessDialog = ({
  isOpen,
  type,
  onActionClick,
  onClickClose
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const buttonStyles = dialogButtonMakeStyles();
  const translationPath = `dialogs.awareness.${type}`;

  const [checked, setChecked] = useState(false);

  const onClick = () => {
    onActionClick(checked);
  };

  return (
    <>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{translate(`${translationPath}.title`)}</Box>
            <Box>
              <IconButton
                id="awarenessDialogCloseButton"
                onClick={onClickClose}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText>
            {translate(`${translationPath}.content`)}
          </DialogContentText>
          <DialogContentText>
            {translate(`${translationPath}.description`, {
              date: convertDateTimeToStringAtStartOfWeek(
                addDaysToDateTime(moment(), 7),
                BRZ_DATE_FORMAT
              ),
              time: convertDateTimeToStringAtStartOfWeek(
                addDaysToDateTime(moment(), 7),
                BRZ_TIME_FORMAT
              )
            })}
          </DialogContentText>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="awarenessDialogCheckbox"
                  onChange={event => setChecked(event.target.checked)}
                />
              }
              label={
                <Typography component="span" className={classes.checkbox}>
                  {translate('dialogs.awareness.do_not_display_future')}
                </Typography>
              }
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            id="awarenessDialogActionButton"
            className={`${buttonStyles}}`}
            size="large"
            variant="contained"
            color="primary"
            label="dialogs.awareness.action"
            onClick={onClick}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

RouteFormAwarenessDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onActionClick: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired
};

RouteFormAwarenessDialog.defaultProps = {};

export default RouteFormAwarenessDialog;
