function rgbToHex(r, g, b) {
  /* eslint no-bitwise: ["error", { "allow": ["<<"] }] */
  const color = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  return `#${color}`;
}

export const hexToRgb = hex => {
  const color = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16))
    .join(', ');
  return `rgb(${color})`;
};

export const convertColorProtoToHex = proto => {
  let { red, green, blue } = proto;

  if (!proto) {
    return null;
  }

  red = parseInt(red * 255, 10);
  green = parseInt(green * 255, 10);
  blue = parseInt(blue * 255, 10);

  return rgbToHex(red, green, blue);
};

export const convertColorProtoToRGB = proto => {
  let { red, green, blue } = proto;

  if (!proto) {
    return null;
  }

  red = parseInt(red * 255, 10);
  green = parseInt(green * 255, 10);
  blue = parseInt(blue * 255, 10);

  return `rgb(${red}, ${green}, ${blue})`;
};

export default {
  convertColorProtoToHex,
  convertColorProtoToRGB
};
