import { Button, useTranslate } from 'react-admin';
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { FormControl, TextField } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';

import inputWithDialogStyles from './styles';
import { dialogButtonMakeStyles } from '../../theme/transport-planner-theme';

const InputDialog = ({
  isOpen,
  onClose,
  onConfirm,
  name,
  defaultValue,
  disabled,
  validationPattern,
  resource,
  inputComponentType,
  ...props
}) => {
  const translate = useTranslate();
  const buttonStyles = dialogButtonMakeStyles();
  const styles = inputWithDialogStyles();
  const translationPath = `dialogs.input_dialog`;
  const specificTranslationPath = `dialogs.input_dialog.${resource}`;

  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const handleChange = event => {
    setError(undefined);
    setInputValue(event.target.value);
  };

  const onSaveClick = () => {
    if (!inputValue) {
      setError(
        translate(`${specificTranslationPath}.insert_valid_to_continue`)
      );
      return;
    }
    if (validationPattern) {
      const pattern = new RegExp(validationPattern);
      if (!pattern.test(inputValue)) {
        setError(
          translate(`${specificTranslationPath}.review_valid_to_continue`)
        );
        return;
      }
    }
    onConfirm(inputValue);
  };

  const handleOnClose = () => {
    setError(undefined);
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="form-dialog-title"
        className={styles.dialog}
      >
        <DialogTitle id="form-dialog-title">
          {translate(`${specificTranslationPath}.title`)}
        </DialogTitle>
        <DialogContent>
          <FormControl size="small" variant="outlined" error={!!error}>
            <TextField
              className={styles.dialogInput}
              error={!!error}
              label={name}
              variant="outlined"
              value={inputValue}
              placeholder={defaultValue}
              disabled={disabled}
              onChange={handleChange}
              data-testid={`${resource}-input-dialog__dialog__input`}
              InputProps={{
                inputComponent: inputComponentType
              }}
              autoFocus
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className={`${buttonStyles}}`}
            onClick={handleOnClose}
            size="medium"
            label={`${translationPath}.sub_action`}
          />
          <Button
            className={`${buttonStyles}}`}
            variant="contained"
            size="medium"
            color="primary"
            label={translate(`${translationPath}.action`)}
            icon={<div />}
            onClick={onSaveClick}
            disabled={(error !== undefined && error !== '') || disabled}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

InputDialog.propTypes = {
  name: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  validationPattern: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  inputComponentType: PropTypes.element.isRequired
};

InputDialog.defaultProps = {
  defaultValue: undefined,
  disabled: false
};

export default InputDialog;
