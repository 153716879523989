/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Button,
  SaveButton,
  Toolbar,
  usePermissions,
  useTranslate
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { routeToolbar } from '../styles';
import { hasWritePermission } from '../../../../../config/auth-provider';
import RouteActionsMenu from '../../../../molecules/route/route-actions-menu';

const RouteFormFooterToolbar = ({
  isSaveDisabled,
  routePlanId,
  routeId,
  parentResource,
  requiresJustification,
  record,
  allowPickups,
  ...props
}) => {
  const { goBack } = useHistory();
  const translate = useTranslate();
  const routeState = record.state;

  const { permissions } = usePermissions();

  const ToolBarNormal = () => {
    const style = routeToolbar();

    return (
      <Toolbar className={style.toolbar} {...props}>
        {hasWritePermission(permissions) && routeId && (
          <RouteActionsMenu
            title="ra.action.other_actions"
            record={record}
            routePlanId={routePlanId}
            routeId={routeId}
            parentResource={parentResource}
            buttonSize="small"
            allowPickups={allowPickups}
            requiresJustification={requiresJustification}
          />
        )}

        <Button
          size="medium"
          variant="text"
          label={
            isSaveDisabled || !hasWritePermission(permissions)
              ? translate('ra.action.back_to_list')
              : translate('ra.action.cancel')
          }
          onClick={() => goBack()}
        />
        {hasWritePermission(permissions) && !isSaveDisabled && (
          <SaveButton className="save-button" />
        )}
      </Toolbar>
    );
  };

  const ToolBarPendingJustification = () => {
    const style = routeToolbar();

    return (
      <Toolbar className={style.toolbar} {...props}>
        {routeId && (
          <RouteActionsMenu
            title="ra.action.other_actions"
            record={record}
            routePlanId={routePlanId}
            routeId={routeId}
            parentResource={parentResource}
            buttonSize="medium"
            allowPickups={allowPickups}
            requiresJustification={requiresJustification}
          />
        )}
        <SaveButton
          className="save-button"
          label={translate('ra.action.save_and_inclued')}
        />
      </Toolbar>
    );
  };

  return routeState === 'ROUTE_STATE_PENDING_REVIEW' &&
    hasWritePermission(permissions) ? (
    <ToolBarPendingJustification />
  ) : (
    <ToolBarNormal />
  );
};

RouteFormFooterToolbar.propTypes = {
  record: {
    arrivalTime: PropTypes.string.isRequired,
    latestArrivalTime: PropTypes.string,
    weekday: PropTypes.number,
    originArrivalTime: PropTypes.string,
    routePlanDate: PropTypes.string,
    state: PropTypes.string,
    cancelable: PropTypes.bool.isRequired
  }.isRequired,
  requiresJustification: PropTypes.bool.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
  routePlanId: PropTypes.string.isRequired,
  routeId: PropTypes.string.isRequired,
  parentResource: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  allowPickups: PropTypes.bool.isRequired
};

export default RouteFormFooterToolbar;
