/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  useTranslate
} from 'react-admin';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import RoutePlanFilter from './route-plan-filter';
import { chipStyles } from '../styles';
import StateDot from '../../../../atoms/state-dot';
import { formatDateProto } from '../../../../../utils/date-format';
import { REGIONS } from '../../../../../constants';

const RoutePlanList = props => {
  const translate = useTranslate();
  const chipCellStyles = chipStyles();

  const {
    options: { allowPickups },
    resource
  } = props;
  const titleProperty = `resources.${resource}.listTitle`;
  const fieldsRoot = 'resources.route-plans.fields';

  const renderCellWithTag = useCallback(
    type => {
      return (
        <Chip
          className={chipCellStyles[type]}
          label={translate(`resources.route-plans.${type}`)}
          size="small"
        />
      );
    },
    [chipCellStyles, translate]
  );

  return (
    <List
      {...props}
      title={`${translate('ra.action.list', { smart_count: 1 })} 
        ${translate(titleProperty, { smart_count: 1 })}`}
      exporter={false}
      bulkActionButtons={false}
      pagination={false}
      filters={<RoutePlanFilter />}
      filterDefaultValues={{
        region: REGIONS[0].id,
        allowPickups,
        includeRoutes: false
      }}
    >
      <Datagrid rowClick="show">
        <TextField
          source="name"
          label={translate(`${fieldsRoot}.name`, { smart_count: 1 })}
          sortable={false}
        />
        <TextField
          source="externalId"
          label={translate(`${fieldsRoot}.externalId`, { smart_count: 1 })}
          sortable={false}
        />
        <FunctionField
          source="activationDate"
          label={translate(`${fieldsRoot}.activationDate`, { smart_count: 1 })}
          render={record =>
            formatDateProto(record.activationDate, 'DD/MM/YYYY')
          }
          sortable={false}
        />

        <TextField
          source="totalRoutes"
          label={translate(`${fieldsRoot}.totalRoutes`, { smart_count: 1 })}
          sortable={false}
        />

        <FunctionField
          source="activationState"
          label={translate(`${fieldsRoot}.activationState`, { smart_count: 1 })}
          render={record => <StateDot state={record.activationState} />}
          sortable={false}
        />

        <FunctionField
          render={record =>
            record.showDeclaredLabel && renderCellWithTag('declared')
          }
        />

        <ArrowForwardIosIcon fontSize="default" color="primary" />
      </Datagrid>
    </List>
  );
};

RoutePlanList.propTypes = {
  options: PropTypes.shape({
    allowPickups: PropTypes.bool
  }).isRequired,
  resource: PropTypes.string.isRequired
};

export default RoutePlanList;
