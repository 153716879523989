import 'firebase/remote-config';
import 'firebase/messaging';
import firebase from 'firebase/app';
import firebaseConfig from './firebaseconfig';

const firebaseInit = () => {
  firebase.initializeApp(firebaseConfig);

  const remoteConfig = firebase.remoteConfig();
  remoteConfig.settings = {
    fetchTimeoutMillis: 120_000
  };
  remoteConfig.defaultConfig = {
    enable_declare_scheduling: 'false',
    enable_loading_order: 'false',
    enable_inform_changes_dialog: 'false',
    enable_similar_routes_info: 'false'
  };
};

export default firebaseInit;
