const styles = {
  root: {
    display: 'inline-flex',
    width: '15rem',
    minWidth: 'fit-content',
    maxHeight: '4.625px'
  },
  button: {
    marginTop: '1px',
    maxHeight: '2.5px',
    minWidth: '6.25rem'
  },
  infoIcon: {
    marginTop: '1rem',
    marginLeft: '0.75rem',
    cursor: 'pointer'
  },
  dialog: {
    width: '37.5rem!important'
  },
  dialogInput: {
    maxWidth: '14.75rem'
  }
};

export default styles;
