import { makeStyles } from '@material-ui/styles';
import { colors } from '@loggi/mar';

const lastModifiedTimeInfo = makeStyles({
  lastModifiedTime: {
    alignItems: 'center',
    color: colors.root[500],
    columnGap: '4px',
    display: 'inline-flex',
    fontSize: '12px',

    '& >:first-child': {
      width: '14px',
      marginRight: '4px'
    }
  }
});

export default lastModifiedTimeInfo;
