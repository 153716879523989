import {
  SIGN_IN_FAILURE,
  SIGN_UP_FAILURE,
  useAmplifyAuth,
  USER_NOT_AUTHENTICATED_ERROR
} from '@loggi/authentication-lib';
import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import PasswordInput from '../../components/password-input/password-input.component';
import ScreenHeader from '../../components/screen-header.component';
import { errorHandler, showNotificationMessage } from '../../service';
import PASSWORD_MIN_LENGTH from '../constants';
import { SIGN_IN_ERRORS } from '../signin/constants';
import {
  CONTINUE_BUTTON_ID,
  EMAIL_INPUT_BUTTON_ID,
  LOADING_ID,
  NAME_INPUT_BUTTON_ID,
  SIGNUP_ERRORS
} from './constants';

const initialValues = {
  email: '',
  name: '',
  password: ''
};

const useSignupFormStyle = makeStyles(({ palette }) => ({
  progress: {
    color: palette.common.white
  }
}));

const SignupForm = () => {
  const {
    signUp,
    state: { error: stateError }
  } = useAmplifyAuth();
  const styles = useSignupFormStyle();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['signupForm', 'signIn']);
  const [loading, setLoading] = useState(false);

  const signupValidationSchema = Yup.object().shape({
    name: Yup.string().required(t('validations.requiredName')),
    email: Yup.string()
      .required(t('validations.requiredEmail'))
      .email(t('validations.invalidEmail')),
    password: Yup.string()
      .required(t('validations.requiredPassword'))
      .min(PASSWORD_MIN_LENGTH, t('validations.minLengthPassword'))
  });

  const signInErrorNotification = useCallback(
    signInError => {
      const { NotAuthorizedException } = SIGN_IN_ERRORS;

      if (signInError.code === NotAuthorizedException.code) {
        return NotAuthorizedException[signInError.message]
          ? t(
              `signIn:errorsMessages.NotAuthorizedException.${
                NotAuthorizedException[signInError.message]
              }`
            )
          : t('signIn:errorsMessages.NotAuthorizedException.fallbackMessage');
      }
      return t(
        `signIn:errorsMessages.${SIGN_IN_ERRORS[signInError.name].code}`
      );
    },
    [t]
  );

  const onSubmitSignUp = (formValues, { setSubmitting }) => {
    setLoading(true);
    const { email, name, password } = formValues;

    setSubmitting(false);

    signUp(email.toLowerCase(), name.trim(), password);
  };

  const getErrorFeedback = useCallback(
    error => {
      if (error.event === SIGN_UP_FAILURE) {
        const signupErrorMessage = error.message.message;
        const signUpErrorCode = error.message.code;
        return signupErrorMessage.includes(
          SIGNUP_ERRORS[signUpErrorCode].message
        )
          ? t('signupForm:errorsMessages.LoggiUserAlreadyExists')
          : t(
              `signupForm:errorsMessages.${
                SIGNUP_ERRORS[error.message.name].code
              }`
            );
      }

      return signInErrorNotification(error.message);
    },
    [signInErrorNotification, t]
  );

  const showDefaultErrorNotificationMessage = useCallback(
    error => {
      if (error.event === SIGN_IN_FAILURE) {
        return showNotificationMessage(
          t('signIn:errorsMessages.DefaultException'),
          'error',
          enqueueSnackbar
        );
      }

      return showNotificationMessage(
        t('signupForm:errorsMessages.DefaultException'),
        'error',
        enqueueSnackbar
      );
    },
    [enqueueSnackbar, t]
  );

  useEffect(() => {
    if (stateError && stateError !== USER_NOT_AUTHENTICATED_ERROR) {
      const isKnownError = Boolean(
        SIGNUP_ERRORS[(stateError.message?.name)] ||
          SIGN_IN_ERRORS[(stateError.message?.name)]
      );

      if (isKnownError) {
        const errorFeedback = getErrorFeedback(stateError);
        showNotificationMessage(errorFeedback, 'error', enqueueSnackbar);
      } else {
        errorHandler(stateError);
        showDefaultErrorNotificationMessage(stateError);
      }

      setLoading(false);
    }
  }, [
    stateError,
    getErrorFeedback,
    enqueueSnackbar,
    t,
    showDefaultErrorNotificationMessage
  ]);

  const formik = useFormik({
    initialValues,
    onSubmit: onSubmitSignUp,
    validationSchema: signupValidationSchema
  });
  const {
    handleSubmit,
    setFieldTouched,
    handleChange,
    handleBlur,
    values,
    errors,
    touched
  } = formik;

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <Box>
          <ScreenHeader
            title={t('headerContent.title')}
            subTitle={t('headerContent.subTitle')}
          />
        </Box>
      </OneTemplateSummary>
      <OneTemplateContent>
        <FormikProvider value={formik}>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
            data-testid="sign-up-form"
          >
            <Grid
              container
              spacing={2}
              direction="column"
              justify="space-around"
              alignItems="center"
            >
              <TextField
                id={NAME_INPUT_BUTTON_ID}
                margin="normal"
                name="name"
                onChange={handleChange}
                onBlur={() => setFieldTouched('name')}
                fullWidth
                label={t('inputsLabel.name')}
                variant="outlined"
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />

              <TextField
                id={EMAIL_INPUT_BUTTON_ID}
                margin="normal"
                onChange={handleChange}
                onBlur={() => setFieldTouched('email')}
                name="email"
                fullWidth
                label={t('inputsLabel.email')}
                variant="outlined"
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <PasswordInput
                handleChange={handleChange}
                password={values.password}
                handleBlur={handleBlur}
                error={touched.password && errors.password}
                label={t('inputsLabel.password')}
              />

              <Box pt={2} width="100%">
                <Button
                  color="primary"
                  disabled={loading}
                  data-testid={CONTINUE_BUTTON_ID}
                  fullWidth
                  variant="contained"
                  type="submit"
                >
                  {!loading && t('buttons.continue')}
                  {loading && (
                    <CircularProgress
                      data-testid={LOADING_ID}
                      className={styles.progress}
                      size={26}
                    />
                  )}
                </Button>
              </Box>
            </Grid>
          </form>
        </FormikProvider>
      </OneTemplateContent>
    </OneTemplate>
  );
};

export default SignupForm;
