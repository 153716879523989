/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { SimpleForm, useTranslate } from 'react-admin';
import { Divider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Header from './header';
import WeekdayButtonGroup from '../../../../molecules/recurrence/weekday-button-group';
import StartEndDaySelector from '../../../../molecules/recurrence/start-end-day-selector';
import style from './style';

const RecurrenceForm = props => {
  const translate = useTranslate();

  const { record, title } = props;

  return (
    <SimpleForm
      {...props}
      toolbar={false}
      style={style.formMain}
      data-testid="recurrence-form"
      variant="outlined"
    >
      <Header title={title} record={record} />
      <div data-testid="form-recurrence" style={style.recurrenceArea}>
        <Typography variant="h5" style={style.recurrenceTitle}>
          {translate('resources.recurrence.recurrence')}
        </Typography>
        <WeekdayButtonGroup {...props} />
        <StartEndDaySelector {...props} />
        <Divider />
      </div>
    </SimpleForm>
  );
};

RecurrenceForm.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    routeDistanceInKm: PropTypes.number,
    routeDurationHuman: PropTypes.string,
    endDateFormatted: PropTypes.string,
    startDateFormatted: PropTypes.string,
    recurrenceWeekdays: PropTypes.arrayOf(PropTypes.string)
  }),
  title: PropTypes.string.isRequired
};

RecurrenceForm.defaultProps = {
  record: {}
};

export default RecurrenceForm;
