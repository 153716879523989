import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { globalStyles } from '../../theme/transport-planner-theme';

const SelectOptions = ({
  defaultIdValue,
  label,
  name,
  options,
  onChange,
  multiple,
  closeFilter,
  optionTextProp
}) => {
  const projectStyles = globalStyles();
  const findOptions = useCallback(
    value => {
      if (multiple) {
        return (
          options.filter(selectOption => value?.includes(selectOption.id)) || []
        );
      }

      const selectedOption = options.find(
        selectOption => selectOption.id === value
      );
      return selectedOption || '';
    },
    [multiple, options]
  );

  const [option, setOption] = useState(
    defaultIdValue === '' ? '' : findOptions(defaultIdValue)
  );

  useEffect(() => {
    setOption(findOptions(defaultIdValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultIdValue, findOptions]);

  const onChangeOption = event => {
    const { value } = event.target;
    setOption(value);
    if (!multiple) onChange(name, value?.id);
    else {
      onChange(name, value.map(selectOption => selectOption.id));
    }
  };

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={option}
        data-testid={`test-id-${name}`}
        onChange={onChangeOption}
        multiple={multiple}
        renderValue={selected =>
          multiple
            ? selected.map(row => row[optionTextProp]).join(', ')
            : selected?.[optionTextProp]
        }
        MenuProps={{
          className: projectStyles.menuOptionsMaxWidth,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          // don't anchor the menu position with the selected option
          getContentAnchorEl: null
        }}
        onClose={() => {
          if (typeof closeFilter === 'function') closeFilter();
        }}
      >
        {!multiple && <MenuItem value="">Selecione</MenuItem>}
        {options.map(item => (
          <MenuItem key={item?.[optionTextProp]} value={item}>
            {multiple && <Checkbox checked={option?.includes(item)} />}
            <ListItemText primary={item?.[optionTextProp]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectOptions.propTypes = {
  defaultIdValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  closeFilter: PropTypes.func,
  optionTextProp: PropTypes.string
};

SelectOptions.defaultProps = {
  multiple: false,
  optionTextProp: 'name',
  closeFilter: undefined
};

export default SelectOptions;
