import { colors } from '@loggi/mar';

export const scheduleInfoBoxStyle = {
  box: {
    display: 'inline-flex',
    alignItems: 'center',
    background: colors.smoke[50],
    borderRadius: '0.5rem',
    padding: '0.25rem'
  },
  panel: {
    height: '5.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1rem 3.25rem'
  },
  divider: {
    margin: '0 1rem',
    alignSelf: 'center',
    color: colors.smoke[200],
    height: '4rem'
  },
  icon: {
    color: colors.smoke[500]
  },
  info: {
    fontWeight: 700,
    color: colors.smoke[900]
  },
  caption: {
    fontWeight: 700,
    fontSize: '0.75rem',
    color: colors.smoke[700]
  }
};

export default scheduleInfoBoxStyle;
