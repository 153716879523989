/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Create, useNotify } from 'react-admin';
import RecurrenceForm from '../form';

const RecurrenceCreate = props => {
  const history = useHistory();
  const notify = useNotify();

  const onSuccess = () => {
    notify('ra.notification.created', 'success', { smart_count: 1 });
    history.push(`/${props.resource}`);
  };

  return (
    <Create
      {...props}
      title="pages.title"
      basePath="/recurrence/create"
      resource="recurrence"
      className="recurrence-form"
      onSuccess={onSuccess}
    >
      <RecurrenceForm {...props} title="pages.createRecurrence" />
    </Create>
  );
};

RecurrenceCreate.propTypes = {
  resource: PropTypes.string.isRequired
};

export default RecurrenceCreate;
