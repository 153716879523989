import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import style from './style';

const TabTitle = ({ title, tooltipText, selected }) => {
  const selectedStyle = selected ? style.selected : style.notSelected;
  return (
    <div style={style.main}>
      <Typography style={{ ...style.title, ...selectedStyle }}>
        <strong>{title}</strong>
      </Typography>
      <Tooltip
        title={<Typography>{tooltipText}</Typography>}
        placement="bottom"
        style={style.tooltip}
        data-testid="tabTitleId"
      >
        <Info />
      </Tooltip>
    </div>
  );
};

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired
};

export default TabTitle;
