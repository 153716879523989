import moment from 'moment-timezone';
import formatDistance from 'date-fns/formatDistance';
import ptBR from 'date-fns/locale/pt-BR';

const TZ_BR_SP = 'America/Sao_Paulo';
export const BRZ_DATETIME_FORMAT = 'DD/MM/YYYY - HH:mm';
export const BRZ_DATE_FORMAT = 'DD/MM/YYYY';
export const BRZ_TIME_FORMAT = 'HH:mm';
export const LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const LOCAL_DATE_FORMAT = 'YYYY-MM-DD';

const parseDuration = durationString =>
  moment.duration(`PT${durationString.toUpperCase()}`);

const padLeftZero = (num, count) => String(num).padStart(count, '0');

export const isValidTimeString = timeString =>
  new RegExp(/^[0-9]?[0-9]:[0-5][0-9]$/).test(timeString);

export const isValidDuration = durationString =>
  new RegExp(/\d*s/).test(durationString);

export const convertDurationToTimeString = durationString => {
  if (!isValidDuration(durationString)) {
    return durationString || '';
  }

  const duration = parseDuration(durationString);
  return `${padLeftZero(Math.trunc(duration.asHours()), 2)}:${padLeftZero(
    duration.minutes(),
    2
  )}`;
};

export const convertDurationToHumanString = durationString => {
  if (!isValidDuration(durationString)) {
    return durationString || '';
  }

  const duration = parseDuration(durationString);
  return `${Math.trunc(duration.asHours())}h ${duration.minutes()}min`;
};

export const convertTimeStringToDuration = timeString => {
  if (timeString === '') {
    return null;
  }

  if (isValidTimeString(timeString)) {
    return `${moment.duration(timeString).asSeconds()}s`;
  }
  return timeString;
};

export const formatDateProto = (dateProto, format) => {
  if (!dateProto) {
    return null;
  }

  const jsDate = {
    year: dateProto.year,
    month: dateProto.month - 1, // months are zero-based in js.
    day: dateProto.day
  };

  return moment(jsDate).format(format);
};

export const isValidDateTime = (date, format = 'YYYY-MM-DD HH:mm:ss.SSS') =>
  moment(date, format).isValid();

export const isTimeEqualOrAfter = (time, min) => {
  const minTime = moment.duration(min).asSeconds();
  const currentDuration = moment.duration(time).asSeconds();

  return currentDuration >= minTime;
};

export const convertUtcToBrz = (date, format = null) => {
  if (!isValidDateTime(date)) return '';

  return moment
    .utc(date)
    .tz(TZ_BR_SP)
    .format(format);
};

export const convertDateTimeToStringAtStartOfWeek = (
  date,
  format = LOCAL_DATETIME_FORMAT
) =>
  moment(`${date}`)
    .day(0)
    .startOf('day')
    .format(format);

export const convertDateTimeToStringAtEndOfWeek = date =>
  moment(`${date}`)
    .day(6)
    .endOf('day')
    .format(LOCAL_DATETIME_FORMAT);

export const addDaysToDateTime = (date, days, format = LOCAL_DATETIME_FORMAT) =>
  moment(`${date}`)
    .add(days, 'd')
    .format(format);

export const convertBrzToUtc = date => {
  if (!isValidDateTime(date)) return '';

  return moment
    .tz(date, TZ_BR_SP)
    .utc()
    .format();
};

export const convertDateTimeToDistance = (
  startDate,
  translationOfAt,
  endDate = Date.now()
) => {
  const start = moment.utc(startDate).tz(TZ_BR_SP);
  const end = moment.utc(endDate).tz(TZ_BR_SP);

  const isSameDay = start.isSame(end, 'day');

  if (isSameDay) {
    if (end.diff(start, 'minutes') < 60) {
      return formatDistance(new Date(start), new Date(end), {
        addSuffix: true,
        locale: ptBR
      });
    }
  }

  return start.format(
    `${!isSameDay ? 'D/MM/YYYY ' : ''}[${translationOfAt}] HH:mm`
  );
};

export default {
  convertDurationToTimeString,
  convertDurationToHumanString,
  convertTimeStringToDuration,
  formatDateProto,
  isValidDuration,
  isValidTimeString,
  convertDateTimeToStringAtStartOfWeek,
  convertDateTimeToStringAtEndOfWeek,
  addDaysToDateTime,
  convertDateTimeToDistance,
  isTimeEqualOrAfter
};
