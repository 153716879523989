export const convertDecimalToMoneyProto = value => {
  if (!value) {
    return null;
  }

  const units = Math.trunc(value);
  // Number of nano (10^-9) units of the amount.
  const nanos = (value % 1).toFixed(2) * 10 ** 9;
  const currencyCode = 'BRL';

  return {
    units,
    nanos,
    currencyCode
  };
};

export const convertMoneyProtoToDecimal = proto => {
  if (!proto) {
    return null;
  }

  const unit = proto.units;
  const cents = proto.nanos ? `${proto.nanos}` : '00';
  return Number(`${unit}.${cents.substring(0, 2)}`);
};

export default {
  convertDecimalToMoneyProto,
  convertMoneyProtoToDecimal
};
