import { colors } from '@loggi/mar';
import { makeStyles } from '@material-ui/styles';

const routeFormStyles = makeStyles(theme => ({
  autocomplete: {
    '& > *': {
      marginTop: theme.spacing(1)
    }
  },
  container: {
    '& > *': {
      marginTop: theme.spacing(-1.5)
    }
  },
  routeFormBox: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '16px',
    marginTop: '32px'
  },
  routeFormHeader: {
    marginBottom: '60px',

    '& h1': {
      fontWeight: 'bold',
      width: 'fit-content'
    },

    '& >div': {
      display: 'inline-flex',
      alignItems: 'center',

      '& p': {
        borderRight: `solid 1px ${colors.smoke[200]}`,
        color: colors.smoke[700],
        fontFamily: 'Open Sans',
        fontSize: '14px',
        margin: '0px 16px 0px 0px',
        paddingRight: '16px'
      },

      '& .no-border': {
        borderRight: 'none'
      },

      '& .state-dot': {
        marginBottom: '0px'
      }
    }
  },
  inlineBlock: {
    display: 'inline-flex',
    marginRight: '1rem',
    '&  .Mui-disabled.Mui-disabled': {
      color: 'inherit'
    }
  },
  boxStyle: {
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '100%!important'
  },
  typographyFontSize: {
    fontSize: 12
  },
  typographyFontSizeHeaderBox: {
    fontSize: 16
  },
  typographyBold: {
    fontWeight: 600,
    fontSize: 12
  },
  alertCanceledPlan: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: '1rem',
    background: '#F5F8FA',
    borderRadius: '8px',
    padding: '16px',

    '& .icon': {
      marginRight: '8px',
      color: colors.smoke[500]
    },
    '& .message': {
      fontFamily: 'Open Sans',
      fontSize: '16px',
      lineHeight: '150%',
      color: '#3F444A',
      flex: 'none',
      order: '1',
      flexGrow: '1'
    }
  },
  groupTitleDivider: {
    width: 'auto',
    fontWeight: 700,
    color: colors.smoke[900],
    marginBottom: '16px'
  },
  groupTitleDividerNoMargin: {
    marginBottom: '0px'
  },
  groupDivider: {
    width: 'auto',
    margin: '56px auto 56px auto',
    alignSelf: 'center',
    color: colors.smoke[200]
  },
  groupDividerSmallerTopMargin: {
    margin: '38px auto 56px auto'
  }
}));

export const routeAlertStyles = makeStyles(() => ({
  inlineBlock: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: '1rem',
    background: '#F5F8FA',
    borderRadius: '8px',
    padding: '16px',
    whiteSpace: 'nowrap'
  },
  icon: {
    marginRight: '8px',
    color: colors.smoke[500]
  },
  message: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    lineHeight: '150%',
    color: '#3F444A',
    flex: 'none',
    order: '1',
    flexGrow: '1'
  },
  link: {
    background: 'none!important',
    border: 'none',
    color: colors.blue['500'],
    fontWeight: 'bold',
    padding: '0!important',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));

export const totalBoxStyles = makeStyles(() => ({
  box: {
    display: 'inline-flex',
    alignItems: 'center',
    background: colors.smoke[50],
    borderRadius: '8px',
    padding: '4px',
    marginRight: '44px'
  },
  boxItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '54px',
    marginLeft: '54px'
  },
  divider: {
    marginRight: '16px',
    marginLeft: '16px',
    alignSelf: 'center',
    color: colors.smoke[200],
    height: '64px'
  },
  icon: {
    marginTop: '20px',
    marginBottom: '8px',
    color: colors.smoke[500]
  },
  description: {
    fontWeight: '600',
    fontSize: '12px',
    color: colors.smoke[700],
    marginBottom: '20px',
    marginTop: '-4px',
    whiteSpace: 'nowrap'
  },
  mainContent: {
    all: 'unset',
    marginTop: '8px',
    pointerEvents: 'none',
    border: 'none',
    color: colors.smoke[50],
    '& div': {
      all: 'unset'
    },
    '& input': {
      all: 'unset',
      fontWeight: 'bold',
      backgroundColor: colors.smoke[50],
      color: colors.smoke[900],
      whiteSpace: 'nowrap',
      fontFamily: 'Open Sans',
      fontSize: '16px',
      textAlign: 'center',
      width: '130px'
    }
  },
  inputDisabled: {}
}));

export const useChangelogStyles = makeStyles({
  root: {
    width: 592,
    margin: '56px 32px'
  },
  closeArea: {
    width: 56
  },
  closeIcon: {
    color: colors.blue[500],
    cursor: 'pointer'
  },
  title: {
    display: 'block',
    fontWeight: 700,
    fontSize: 28,
    marginTop: 40
  },
  createdAtAndBy: {
    display: 'block',
    fontSize: 14,
    color: colors.smoke[700],
    fontWeight: 400,
    marginTop: 24
  },
  extensionBox: {
    marginTop: 40
  },
  panelSummary: {
    display: 'inline-flex',
    marginRight: 56
  },
  summaryTitle: {
    fontSize: 14,
    display: 'block',
    color: colors.smoke[900]
  },
  summaryDate: {
    fontSize: 12,
    display: 'block',
    color: colors.smoke[700]
  },
  panelDetails: {
    marginLeft: 56,
    marginBottom: 40,
    display: 'block'
  },
  nameIcon: {
    marginRight: 16
  },
  nameIconColor: {
    backgroundColor: '#EC75FF'
  },
  detailTitle: {
    fontWeight: 700,
    fontSize: 16,
    display: 'block',
    marginBottom: 8,
    marginTop: 24
  },
  changeReason: {
    fontWeight: 700,
    fontSize: 14,
    display: 'block'
  },
  changeReasonDescription: {
    fontWeight: 400,
    fontSize: 14,
    display: 'block'
  },
  detailType: {
    fontWeight: 700,
    fontSize: 14,
    display: 'block',
    marginTop: 16
  },
  detailChange: {
    fontSize: 14,
    alignItems: 'center',
    display: 'block',
    marginTop: 8
  },
  detailChangeBefore: {
    textDecoration: 'line-through'
  },
  arrowForward: {
    fontSize: 14,
    marginRight: 8,
    marginLeft: 8
  },
  loading: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dot: {
    marginRight: '8px',
    width: '8px',
    height: '8px',
    color: colors.smoke[900]
  }
});

export const routeToolbar = makeStyles(() => ({
  toolbar: {
    backgroundColor: colors.root[0],
    marginBottom: '16px',
    marginTop: '44px',
    columnGap: '24px'
  }
}));

export default routeFormStyles;
