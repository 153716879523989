import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { Box, Typography, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useFormState } from 'react-final-form';

import loadingOrderInfoStyle from './styles';
import loadingOrderImg from '../../../../../public/images/loading-order.png';
import CalculateTimesButton from '../calculate-times-button';

const LoadingOrderInfo = ({ source, disabled, convertValuesToServer }) => {
  const styles = loadingOrderInfoStyle();
  const translate = useTranslate();
  const loadingOrderSummary = useFormState().values[source];

  return (
    <>
      <Box className={styles.boxStyle}>
        <Typography
          component="subtitle1"
          variant="subtitle1"
          className={styles.groupTitleDividerSmallerText}
        >
          {translate('resources.route.loading_order.title')}
        </Typography>

        <Box className={styles.boxStyle}>
          <Tooltip
            placement="top-start"
            title={
              <p>
                <strong>
                  {translate('resources.route.loading_order.transfers_title')}
                </strong>
                {translate('resources.route.loading_order.transfers_content')}
                <br />
                <strong>
                  {translate(
                    'resources.route.loading_order.separate_load_title'
                  )}
                </strong>
                {translate(
                  'resources.route.loading_order.separate_load_content'
                )}
                <br />
                <strong>
                  {translate('resources.route.loading_order.mixed_load_title')}
                </strong>
                {translate('resources.route.loading_order.mixed_load_content')}
              </p>
            }
          >
            <InfoIcon fontSize="small" />
          </Tooltip>
        </Box>
      </Box>

      <Box className={styles.boxStyle}>
        {/* loading order */}
        <Typography
          component="subtitle1"
          variant="subtitle1"
          className={styles.groupTitleDividerSmallerTextGrey}
        >
          {loadingOrderSummary}
        </Typography>

        {/* Update button */}
        {/* Calculate Times Button */}
        <CalculateTimesButton
          source="routes"
          formClassName={styles.inlineBlock}
          transform={convertValuesToServer}
          disabled={disabled}
          label="update_here"
        />
      </Box>

      <img src={loadingOrderImg} alt="loadingOrderImage" />
    </>
  );
};

LoadingOrderInfo.propTypes = {
  source: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  convertValuesToServer: PropTypes.func.isRequired
};

export default LoadingOrderInfo;
