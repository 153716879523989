import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Confirm, useMutation, useNotify } from 'react-admin';
import PlansToReviewDialog from './plans-to-review-dialog';
import BackdropLoading from '../../../theme/backdrop-loading';
import DeclareTextContent from './declare-text-content';
import ActionButton from './action-button';

const PublishRoutePlanButton = ({
  record,
  isDeclareBehavior,
  onSuccess,
  onClickReview
}) => {
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openPlansToReviewDialog, setOpenPlansToReviewDialog] = useState(false);
  const [mutate, { loading }] = useMutation();
  const notify = useNotify();

  const translationPathDialogDeclare =
    'dialogs.publish_dialog.declare_route_plan';
  const translationPathDialogPublish =
    'dialogs.publish_dialog.publish_route_plan';
  const translationPathButtonDeclare =
    'resources.route-plans.publish_button.declare_route_plan';
  const translationPathButtonPublish =
    'resources.route-plans.publish_button.publish_route_plan';
  const translationPathDialogPlansToReview = 'dialogs.plans_to_review';

  const payload = {
    data: { routePlanId: record.id }
  };

  const closeOpenDialog = () => {
    setOpenConfirmDialog(false);
    setOpenPlansToReviewDialog(false);
  };

  const publishOrDeclareRoutePlans = () => {
    closeOpenDialog();
    setOpenLoadingDialog(true);
    mutate(
      {
        type: isDeclareBehavior ? 'declare' : 'publish',
        resource: 'route_plans',
        payload
      },
      {
        onSuccess: () => {
          setOpenLoadingDialog(false);
          notify('resources.route-plans.notification.published', 'info');
          onSuccess();
        },
        onFailure: () => {
          setOpenLoadingDialog(false);
          notify('ra.notification.http_error');
        }
      }
    );
  };

  /*
    The Publish button behavior changes accordingly to the Route Plan status (declared or not)
    and if it has plans pending review.

    To facilitate defining the Button action, text and other properties, and to set which dialog will open
    accordingly to the situation, this publishBehavior object is declared below.

    The use of switch(true) enforces the code to always go through one of the case options.
  */

  const publishBehavior = {
    actionButton: {
      text: {
        label: '',
        tooltip: {
          content: '',
          content_day: ''
        }
      },
      label: '',
      onClick: undefined,
      requiresTooltip: false
    },
    confirmDialog: {
      exist: false,
      title: '',
      content: '',
      onClose: undefined,
      onConfirm: undefined
    },
    reviewDialog: {
      exist: false,
      actionLabel: '',
      onConfirm: undefined
    }
  };

  const hasRoutesPendingReview = record.totalRoutesPendingReview !== 0;

  switch (true) {
    // Declare behavior and show Declare dialog
    case isDeclareBehavior && !hasRoutesPendingReview:
      publishBehavior.actionButton = {
        text: {
          label: translationPathButtonDeclare,
          tooltip: {
            content: `${translationPathButtonDeclare}_tooltip.content`,
            content_day: `${translationPathButtonDeclare}_tooltip.content_day`
          }
        },
        onClick: setOpenConfirmDialog,
        requiresTooltip: true
      };
      publishBehavior.confirmDialog = {
        exist: true,
        title: `${translationPathDialogDeclare}.confirmation_title`,
        content: (
          <DeclareTextContent translationPath={translationPathDialogDeclare} />
        ),
        actionButtonLabel: `${translationPathDialogDeclare}.action`,
        onClose: closeOpenDialog,
        onConfirm: publishOrDeclareRoutePlans
      };
      break;

    // Declare behavior and show both Routes Pending Review and Declare dialogs
    case isDeclareBehavior && hasRoutesPendingReview:
      publishBehavior.actionButton = {
        text: {
          label: translationPathButtonDeclare,
          tooltip: {
            content: `${translationPathButtonDeclare}_tooltip.content`,
            content_day: `${translationPathButtonDeclare}_tooltip.content_day`
          }
        },
        onClick: setOpenPlansToReviewDialog,
        requiresTooltip: true
      };
      publishBehavior.reviewDialog = {
        exist: true,
        actionLabel: `${translationPathDialogPlansToReview}.sub_action_declare`,
        onConfirm: () => {
          setOpenPlansToReviewDialog(false);
          setOpenConfirmDialog(true);
        }
      };
      publishBehavior.confirmDialog = {
        exist: true,
        title: `${translationPathDialogDeclare}.confirmation_title`,
        content: (
          <DeclareTextContent translationPath={translationPathDialogDeclare} />
        ),
        actionButtonLabel: `${translationPathDialogDeclare}.action`,
        onClose: closeOpenDialog,
        onConfirm: publishOrDeclareRoutePlans
      };
      break;

    // Publish behavior and show Routes Pending Review dialog
    // Remove this condition after Declare is deployed
    case !isDeclareBehavior && hasRoutesPendingReview:
      publishBehavior.actionButton = {
        text: {
          label: translationPathButtonPublish
        },
        onClick: setOpenPlansToReviewDialog,
        requiresTooltip: false
      };
      publishBehavior.reviewDialog = {
        exist: true,
        actionLabel: `${translationPathDialogPlansToReview}.sub_action_publish`,
        onConfirm: () => {
          closeOpenDialog();
          publishOrDeclareRoutePlans();
        }
      };
      break;

    default:
      // Default Publish behavior
      publishBehavior.actionButton = {
        text: {
          label: translationPathButtonPublish
        },
        onClick: setOpenConfirmDialog,
        requiresTooltip: false
      };
      publishBehavior.confirmDialog = {
        exist: true,
        title: `${translationPathDialogPublish}.confirmation_title`,
        content: `${translationPathDialogPublish}.confirmation_content`,
        onClose: closeOpenDialog,
        onConfirm: publishOrDeclareRoutePlans
      };
      break;
  }

  publishBehavior.actionButton.disabled =
    !record.hasChangesUnscheduledForPublication || !record.editable;

  return (
    <>
      <ActionButton
        disabled={publishBehavior.actionButton.disabled}
        text={publishBehavior.actionButton.text}
        onClick={publishBehavior.actionButton.onClick}
        requiresTooltip={publishBehavior.actionButton.requiresTooltip}
        translationPath={translationPathButtonDeclare}
      />

      {publishBehavior.reviewDialog.exist && (
        <PlansToReviewDialog
          isOpen={openPlansToReviewDialog}
          actionLabel={publishBehavior.reviewDialog.actionLabel}
          onClickAction={publishBehavior.reviewDialog.onConfirm}
          numberOfPlansToReview={record.totalRoutesPendingReview}
          onClickReview={() => {
            closeOpenDialog();
            onClickReview();
          }}
        />
      )}

      {publishBehavior.confirmDialog.exist && (
        <Confirm
          isOpen={openConfirmDialog}
          title={publishBehavior.confirmDialog.title}
          content={publishBehavior.confirmDialog.content}
          translateOptions={{
            id: record.externalId,
            name: record.name
          }}
          loading={loading}
          onConfirm={publishBehavior.confirmDialog.onConfirm}
          onClose={() => closeOpenDialog()}
          confirm={publishBehavior.confirmDialog.actionButtonLabel}
          CancelIcon={() => {
            return <div />;
          }}
          ConfirmIcon={() => {
            return <div />;
          }}
        />
      )}

      <BackdropLoading
        open={openLoadingDialog}
        message="resources.route-plans.publishingPlan"
      />
    </>
  );
};

PublishRoutePlanButton.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    externalId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hasChangesUnscheduledForPublication: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
    totalRoutesPendingReview: PropTypes.number.isRequired
  }).isRequired,
  onClickReview: PropTypes.func.isRequired,
  isDeclareBehavior: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default PublishRoutePlanButton;
