import { Auth } from 'aws-amplify';

export const Roles = {
  PUBLISHER: 'TRANSPORT_PLANNER_PUBLISHER',
  DECLARE: 'TRANSPORT_PLANNER_DECLARE',
  READER: 'TRANSPORT_PLANNER_READER',
  WRITER: 'TRANSPORT_PLANNER_WRITER',
  LICENSE_PLATE_WRITER: 'TRANSPORT_PLANNER_LICENSE_PLATE_WRITER',
  MERCHANDISE_VALUE_WRITER: 'TRANSPORT_PLANNER_MERCHANDISE_VALUE_WRITER',
  TRANSPORT_PLANNER_RECURRENCE_READER: 'TRANSPORT_PLANNER_RECURRENCE_READER'
};

export const isAuthorized = permissions => {
  const roles = Object.values(Roles);
  return !!permissions && roles.some(role => permissions.includes(role));
};

export const hasWritePermission = permissions =>
  !!permissions && permissions.includes(Roles.WRITER);

export const hasPublishPermission = permissions =>
  !!permissions && permissions.includes(Roles.PUBLISHER);

export const hasDeclarePermission = permissions =>
  !!permissions && permissions.includes(Roles.DECLARE);

export const hasLicensePlateWritePermission = permissions =>
  !!permissions &&
  (permissions.includes(Roles.LICENSE_PLATE_WRITER) ||
    permissions.includes(Roles.WRITER));

export const hasMerchandiseValueWritePermission = permissions =>
  !!permissions &&
  (permissions.includes(Roles.MERCHANDISE_VALUE_WRITER) ||
    permissions.includes(Roles.WRITER));

export const hasRecurrenceReaderPermission = permissions =>
  !!permissions &&
  permissions.includes(Roles.TRANSPORT_PLANNER_RECURRENCE_READER);

const checkAuth = async () => {
  const session = await Auth.currentSession();
  const isAuthenticated = session.isValid();
  const groups = session.accessToken.payload['cognito:groups'];
  return isAuthenticated && isAuthorized(groups)
    ? Promise.resolve(groups)
    : Promise.reject(new Error('ra.auth.sign_in_error'));
};

export default {
  login: async () => Auth.federatedSignIn({ provider: 'Google' }),
  logout: async () => Auth.signOut(),
  checkAuth,
  checkError: async error => Promise.resolve(error),
  getPermissions: () => {
    return checkAuth();
  },
  getIdentity: async () => {
    const session = await Auth.currentSession();
    return Promise.resolve({
      id: session.idToken.payload.email,
      fullName: session.idToken.payload.name
    });
  }
};
