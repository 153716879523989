import React from 'react';
import { Button, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';
import { dialogButtonMakeStyles } from '../../../theme/transport-planner-theme';

const PlansToReviewDialog = ({
  isOpen,
  numberOfPlansToReview,
  actionLabel,
  onClickAction,
  onClickReview
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const buttonStyles = dialogButtonMakeStyles();
  const translationPath = `dialogs.plans_to_review`;

  return (
    <>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        <DialogTitle>{translate(`${translationPath}.title`)}</DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText>
            <Typography component="span" className={classes.content}>
              <Box>
                {translate(`${translationPath}.content`)}
                <Box component="strong">
                  {translate(`${translationPath}.content_status`)}
                </Box>
              </Box>
              <Box>&nbsp;</Box>
              <Box>
                {translate(`${translationPath}.description.start`)}
                <Box component="strong">
                  {translate(`${translationPath}.description.active`)}
                </Box>
                {translate(`${translationPath}.description.end`)}
              </Box>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={`${buttonStyles}}`}
            size="medium"
            variant="contained"
            color="primary"
            label={`${translate(
              'dialogs.plans_to_review.action'
            )} (${numberOfPlansToReview})`}
            onClick={onClickReview}
          />
          <Button
            className={`${buttonStyles}}`}
            size="medium"
            label={actionLabel}
            onClick={onClickAction}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

PlansToReviewDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  numberOfPlansToReview: PropTypes.number.isRequired,
  actionLabel: PropTypes.string.isRequired,
  onClickAction: PropTypes.func.isRequired,
  onClickReview: PropTypes.func.isRequired
};

PlansToReviewDialog.defaultProps = {};

export default PlansToReviewDialog;
