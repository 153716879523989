import { colors } from '@loggi/mar';

const style = {
  main: {
    display: 'inline-flex'
  },
  selected: {
    color: colors.blue[500]
  },
  notSelected: {
    color: colors.smoke[600]
  },
  title: {
    paddingRight: '0.5rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  tooltip: {
    color: colors.smoke[700]
  }
};

export default style;
