import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  TextInput,
  useMutation,
  useNotify,
  useTranslate
} from 'react-admin';
import { Box, Button, Tooltip, Typography } from '@material-ui/core';

import { Edit, Info } from '@material-ui/icons';
import { useForm } from 'react-final-form';
import LicensePlatesInputDialog from './license-plates-input-dialog';

const LicensePlatesInput = ({
  name,
  routeId,
  routePlanId,
  placeholderValue,
  hideEditButton,
  hiddenEditButtonToolTip,
  source,
  placeholderDialog,
  transformDataToUpperCase,
  reference,
  ...props
}) => {
  const translate = useTranslate();
  const [mutate] = useMutation();
  const notify = useNotify();

  const { vehicle } = useForm().getState().values;

  const [dialogOpen, setDialogOpen] = useState(false);

  const [licensePlate, setLicensePlate] = useState(vehicle.licensePlate);
  const [additionalPlate, setAdditionalPlate] = useState(
    vehicle.trailerLicensePlate
  );

  const handleValueChange = (inputLicensePlate, inputAdditionalPlate) => {
    setLicensePlate(inputLicensePlate);
    setAdditionalPlate(inputAdditionalPlate);
  };

  const onConfirm = (inputLicensePlate, inputAdditionalPlate) => {
    const parsedLicensePlate = () => {
      if (transformDataToUpperCase && inputLicensePlate)
        return inputLicensePlate.toUpperCase();
      return inputLicensePlate;
    };

    const parsedAdditionalPlate = () => {
      if (transformDataToUpperCase && inputAdditionalPlate)
        return inputAdditionalPlate.toUpperCase();
      return inputAdditionalPlate;
    };

    const payload = {
      data: {
        routePlanId,
        routeId,
        field: source,
        licensePlate: parsedLicensePlate(),
        trailerLicensePlate: parsedAdditionalPlate()
      }
    };

    return mutate(
      {
        type: 'updateRouteSingleField',
        resource: 'route',
        payload
      },
      {
        onSuccess: () => {
          setDialogOpen(false);
          handleValueChange(parsedLicensePlate(), parsedAdditionalPlate());
          notify(`resources.route.${source}Success`, 'success');
        },
        onFailure: error => {
          setDialogOpen(false);
          notify(error.message, 'error');
        }
      }
    );
  };

  return (
    <>
      <Box>
        <TextInput
          label="resources.route.fields.licensePlate"
          name={source}
          InputProps={{
            disableUnderline: true,
            value: licensePlate
          }}
          InputLabelProps={{ shrink: true }}
          disabled
          variant="outlined"
          data-testid={`${source}-input-dialog__input`}
          inputRef={reference}
          style={{ marginRight: '1rem' }}
          placeholder={placeholderValue}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
        <TextInput
          label="resources.route.fields.trailerLicensePlate"
          name={source}
          InputProps={{
            disableUnderline: true,
            value: additionalPlate
          }}
          InputLabelProps={{ shrink: true }}
          disabled
          variant="outlined"
          data-testid="additionalPlate-input-dialog__input"
          inputRef={reference}
          placeholder={placeholderValue}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />

        {!hideEditButton && (
          <Button
            color="primary"
            onClick={() => setDialogOpen(true)}
            variant="text"
            size="small"
            data-testid={`${source}-input-dialog__button`}
            style={{ marginTop: '0.5rem' }}
          >
            <Edit />
            {`${translate('ra.action.edit')}`}
          </Button>
        )}

        {hideEditButton && hiddenEditButtonToolTip && (
          <Tooltip
            title={
              <Typography component="div">
                <Box>
                  {translate(`resources.route.${hiddenEditButtonToolTip}`)}
                </Box>
              </Typography>
            }
            placement="bottom"
          >
            <Info data-testid="licensePlate-infoIcon" />
          </Tooltip>
        )}
      </Box>
      <SimpleForm
        initialValues={{
          licensePlate,
          trailerLicensePlate: additionalPlate
        }}
        toolbar={
          <Box
            style={{ width: '100%' }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        }
      >
        <LicensePlatesInputDialog
          dialogOpen={dialogOpen}
          licensePlates={{
            licensePlate,
            additionalPlate
          }}
          source="licensePlate"
          name={translate('resources.route.fields.licensePlate')}
          placeholderDialog={placeholderDialog}
          closeDialog={() => setDialogOpen(false)}
          onConfirm={onConfirm}
        />
      </SimpleForm>
    </>
  );
};

export default LicensePlatesInput;

LicensePlatesInput.propTypes = {
  name: PropTypes.string,
  routeId: PropTypes.string.isRequired,
  routePlanId: PropTypes.string.isRequired,
  placeholderValue: PropTypes.string,
  hideEditButton: PropTypes.bool,
  hiddenEditButtonToolTip: PropTypes.string,
  source: PropTypes.string,
  placeholderDialog: PropTypes.string,
  reference: PropTypes.element.isRequired,
  transformDataToUpperCase: PropTypes.bool
};

LicensePlatesInput.defaultProps = {
  placeholderValue: undefined,
  hideEditButton: false,
  hiddenEditButtonToolTip: undefined,
  source: undefined,
  name: undefined,
  placeholderDialog: undefined,
  transformDataToUpperCase: false
};
