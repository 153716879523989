export const weekdays = [
  {
    id: 2,
    name: 'Segunda-feira',
    shortName: 'Seg',
    momentId: 1,
    firstLetter: 'S',
    googleId: 'MONDAY'
  },
  {
    id: 3,
    name: 'Terça-feira',
    shortName: 'Ter',
    momentId: 2,
    firstLetter: 'T',
    googleId: 'TUESDAY'
  },
  {
    id: 4,
    name: 'Quarta-feira',
    shortName: 'Qua',
    momentId: 3,
    firstLetter: 'Q',
    googleId: 'WEDNESDAY'
  },
  {
    id: 5,
    name: 'Quinta-feira',
    shortName: 'Qui',
    momentId: 4,
    firstLetter: 'Q',
    googleId: 'THURSDAY'
  },
  {
    id: 6,
    name: 'Sexta-feira',
    shortName: 'Sex',
    momentId: 5,
    firstLetter: 'S',
    googleId: 'FRIDAY'
  },
  {
    id: 7,
    name: 'Sábado',
    shortName: 'Sáb',
    momentId: 6,
    firstLetter: 'S',
    googleId: 'SATURDAY'
  },
  {
    id: 1,
    name: 'Domingo',
    shortName: 'Dom',
    momentId: 0,
    firstLetter: 'D',
    googleId: 'SUNDAY'
  }
];

export const travelPlanStates = [
  'ROUTE_STATE_ARCHIVED',
  'ROUTE_STATE_PLANNED',
  'ROUTE_STATE_PUBLISHED',
  'ROUTE_STATE_ACTIVE',
  'ROUTE_STATE_COMPLETED',
  'ROUTE_STATE_PENDING_REVIEW',
  'ROUTE_STATE_CANCELLED'
];

export const travelPlanNotDeclaredStates = [
  'ROUTE_STATE_ARCHIVED',
  'ROUTE_STATE_PLANNED',
  'ROUTE_STATE_PENDING_REVIEW'
];

export const loadingType = [
  { id: 1, mixed: true, name: 'Carga misturada (+)' },
  { id: 2, mixed: false, name: 'Carga separada (#)' }
];

export const REGIONS = [
  { id: 'Regional 1', name: 'Regional 1' },
  { id: 'Regional 2', name: 'Regional 2' },
  { id: 'Regional 3', name: 'Regional 3' },
  { id: 'Regional 4', name: 'Regional 4' },
  { id: 'Regional 5', name: 'Regional 5' },
  { id: 'Regional 6', name: 'Regional 6' }
];

export const recurrenceStates = {
  RECURRENCE_SCHEDULE_STATE_ACTIVE: 'RECURRENCE_SCHEDULE_STATE_ACTIVE',
  RECURRENCE_SCHEDULE_STATE_PLANNED: 'RECURRENCE_SCHEDULE_STATE_PLANNED',
  RECURRENCE_SCHEDULE_STATE_ENDED: 'RECURRENCE_SCHEDULE_STATE_ENDED',
  RECURRENCE_SCHEDULE_STATE_ARCHIVED: 'RECURRENCE_SCHEDULE_STATE_ARCHIVED'
};

export default {
  weekdays
};
