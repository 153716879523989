import {
  BRZ_DATE_FORMAT,
  BRZ_TIME_FORMAT,
  BRZ_DATETIME_FORMAT,
  convertUtcToBrz,
  convertDurationToTimeString
} from '../utils/date-format';
import facilityAdapter from './facility-adapter';
import { convertMoneyProtoToDecimal } from '../utils/currency-format';
import portugueseMessages from '../config/portuguese-messages';

const parseAfterTransfer = parsedTransfer => {
  return {
    arrivalTimeFormatted:
      parsedTransfer.arrivalTimeFormatted && parsedTransfer.transfers
        ? parsedTransfer.arrivalTimeFormatted.concat(
            `|${parsedTransfer.transfers}`
          )
        : parsedTransfer.arrivalTimeFormatted,
    travelDuration:
      parsedTransfer.travelDuration && parsedTransfer.transfers
        ? parsedTransfer.travelDuration.concat(`|${parsedTransfer.transfers}`)
        : parsedTransfer.travelDuration,
    unloadingDuration:
      parsedTransfer.unloadingDuration && parsedTransfer.transfers
        ? parsedTransfer.unloadingDuration.concat(
            `|${parsedTransfer.transfers}`
          )
        : parsedTransfer.unloadingDuration
  };
};

const parseTransfers = (routeTransfers, isAfter) => {
  if (routeTransfers === undefined || routeTransfers.length === 0)
    return undefined;

  return routeTransfers.map(transfer => {
    const parsedTransfer = {
      transfers: transfer.destination
        ? facilityAdapter.fromServer(transfer.destination).longName
        : undefined,
      travelDuration: transfer.travelDuration
        ? convertDurationToTimeString(transfer.travelDuration)
        : undefined,
      arrivalTimeFormatted: transfer.arrivalTime
        ? convertUtcToBrz(transfer.arrivalTime, BRZ_DATETIME_FORMAT)
        : undefined,
      unloadingDuration: transfer.unloadingDuration
        ? convertDurationToTimeString(transfer.unloadingDuration)
        : undefined
    };

    /*
    In order to present the data in the proper way and to mantain the same type for all attributes (string),
    in some cases (arrival time, travel duration and unloading duration) when the change is for the after Route
    it is necessary the addition of more data beside just the attribute, this added data must be able to be
    apparted when the frontend is handling it, therefore the string is separeted by a |
    */
    if (isAfter) {
      return {
        ...parsedTransfer,
        ...parseAfterTransfer(parsedTransfer)
      };
    }

    return parsedTransfer;
  });
};

const parseReturnRoute = returnRoute => {
  if (returnRoute === undefined) return returnRoute;
  return returnRoute
    ? portugueseMessages.ra.boolean.true
    : portugueseMessages.ra.boolean.false;
};

const parseRoute = (route = {}, isAfter = false) => {
  // Similar to the Route Adapter, but only with the data that should be displayed in the changelog
  return {
    'origin.id': route.origin
      ? facilityAdapter.fromServer(route.origin).longName
      : undefined,
    originArrivalTime: route.arrivalTime
      ? convertUtcToBrz(route.arrivalTime, BRZ_DATETIME_FORMAT)
      : undefined,
    dock: route.dock ? route.dock : undefined,
    loadingStartTime: route.loadingStartTime
      ? convertUtcToBrz(route.loadingStartTime, BRZ_DATETIME_FORMAT)
      : undefined,
    loadingDuration: route.loadingDuration
      ? convertDurationToTimeString(route.loadingDuration)
      : undefined,
    releaseVehicleDuration: route.releaseVehicleDuration
      ? convertDurationToTimeString(route.releaseVehicleDuration)
      : undefined,
    transfers: route.transfers
      ? parseTransfers(route.transfers, isAfter)
      : undefined,
    merchandiseValue: route.merchandiseValue
      ? Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(convertMoneyProtoToDecimal(route.merchandiseValue))
      : undefined,
    vehicleType: route.vehicleType ? route.vehicleType : undefined,
    carrierId: route.carrierName ? route.carrierName : undefined,
    returnTransferRoute: parseReturnRoute(route.returnRoute)
  };
};

const isDatetimeLocalSpanNeededForKey = key =>
  ['arrivalTimeFormatted', 'travelDuration', 'unloadingDuration'].includes(key);

const parseChangesTransferBefore = beforeAttr => {
  const changes = [];

  beforeAttr.forEach(transfer => {
    Object.keys(transfer).forEach(transferKey => {
      changes.push({
        key: transferKey,
        before: transfer[transferKey],
        after: undefined,
        isTransfer: true
      });
    });
  });

  return changes;
};

const parseChangesTransferAfter = afterAttr => {
  const changes = [];

  afterAttr.forEach(transfer => {
    Object.keys(transfer).forEach(transferKey => {
      changes.push({
        key: transferKey,
        before: undefined,
        after: transfer[transferKey],
        isTransfer: true,
        isDateTimeLocalSpanNeeded:
          isDatetimeLocalSpanNeededForKey(transferKey) &&
          transfer[transferKey].includes('|')
      });
    });
  });

  return changes;
};

const parseChangesTransferBeforeAndAfter = (beforeAttr, afterAttr) => {
  const changes = [];

  const allKeys = Object.keys(Object.assign({}, ...beforeAttr, ...afterAttr));

  for (
    let index = 0;
    index < Math.max(beforeAttr.length, afterAttr.length);
    index += 1
  ) {
    allKeys.forEach(transferKey => {
      const before = beforeAttr[index]
        ? beforeAttr[index][transferKey]
        : undefined;
      const after = afterAttr[index]
        ? afterAttr[index][transferKey]
        : undefined;

      if (before !== after) {
        changes.push({
          key: transferKey,
          before,
          after,
          isTransfer: true,
          isDateTimeLocalSpanNeeded:
            after &&
            typeof after === 'string' &&
            after.includes('|') &&
            isDatetimeLocalSpanNeededForKey(transferKey)
        });
      }
    });
  }

  return changes;
};

const parseChanges = (routeBefore, routeAfter) => {
  const keys = new Set([
    ...Object.keys(routeBefore),
    ...Object.keys(routeAfter)
  ]);
  const changes = [];

  keys.forEach(keyName => {
    const beforeAttr = routeBefore[keyName];
    const afterAttr = routeAfter[keyName];

    if (keyName !== 'transfers' && beforeAttr !== afterAttr) {
      changes.push({
        key: keyName,
        before: beforeAttr,
        after: afterAttr
      });
    }

    if (keyName === 'transfers') {
      if (beforeAttr === undefined && afterAttr) {
        changes.push(...parseChangesTransferAfter(afterAttr));
      } else if (beforeAttr && afterAttr === undefined) {
        changes.push(...parseChangesTransferBefore(beforeAttr));
      } else if (beforeAttr && afterAttr) {
        changes.push(
          ...parseChangesTransferBeforeAndAfter(beforeAttr, afterAttr)
        );
      }
    }
  });
  return changes;
};

const convertFromServer = changelog => {
  /*
    First we parse both Route objects separately, the before and the after, to extract the data in the proper way.
    Then we parse both parsed Routes together, in order to group the changes in an object with the
    changed property, its value before and its value after.
  */
  const changeMap = parseChanges(
    changelog.before ? parseRoute(changelog.before) : {},
    changelog.after ? parseRoute(changelog.after, true) : {}
  );

  return {
    operation: changelog.operation,
    routeId: changelog.routeId,
    routePlanId: changelog.routePlanId,
    changeReason: changelog.changeReason,
    changeReasonDescription: changelog.changeReasonDescription,
    modifiedDateHuman: convertUtcToBrz(changelog.modifiedTime, BRZ_DATE_FORMAT),
    modifiedTimeHuman: convertUtcToBrz(changelog.modifiedTime, BRZ_TIME_FORMAT),
    modifiedByUserNickname: changelog.modifiedByUser,
    changedKeysNames: Array.from(new Set(changeMap.map(item => item.key))),
    changeMap
  };
};

export default {
  fromServer: data => {
    if (Array.isArray(data)) {
      return data.map(changelog => convertFromServer(changelog));
    }
    return [convertFromServer(data)];
  }
};
