import * as React from 'react';
import { Route } from 'react-router-dom';
import RouteCreate from './view/templates/_react-admin/route/route-create';
import RouteEdit from './view/templates/_react-admin/route/route-edit';
import RecurrenceCreate from './view/templates/_react-admin/recurrence/create';

export default [
  <Route
    exact
    path="/transfer-plans/:routePlanId/routes/create"
    component={RouteCreate}
  />,
  <Route
    exact
    path="/transfer-plans/:routePlanId/routes/:routeId"
    component={RouteEdit}
  />,
  <Route
    exact
    path="/pickup-plans/:routePlanId/routes/create"
    component={RouteCreate}
  />,
  <Route
    exact
    path="/pickup-plans/:routePlanId/routes/:routeId"
    component={RouteEdit}
  />,
  <Route
    exact
    path="/transfer-recurrences/recurrence/create"
    component={RecurrenceCreate}
  />,
  <Route
    exact
    path="/pickup-recurrences/recurrence/create"
    component={RecurrenceCreate}
  />
];
