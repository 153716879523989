import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  login: {
    '& .MuiGrid-container > div': {
      display: 'none',

      '&:last-child': {
        display: 'block'
      }
    }
  }
}));

export default useStyles;
