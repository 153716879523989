/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ScheduleInfoBox from '../../../../molecules/schedule/schedule-info-box';
import style, { titleInfoBoxStyle } from './style';
import BackButton from '../../../../theme/back-button.component';
import InformChangesDialog from '../../../../atoms/inform-changes-dialog';

const Header = ({ record, title }) => {
  const translate = useTranslate();
  const history = useHistory();

  const onConfirmInformChangesDialog = () => {
    // Add code to run when leaving the form without saving current changes
  };

  return (
    <>
      <BackButton
        onClick={history.goBack}
        navigationPath="back_to_recurrences_list"
      />
      <InformChangesDialog
        onConfirm={onConfirmInformChangesDialog}
        onClose={history.goBack}
      />
      <div style={style.header}>
        <div>
          <Typography
            component="h1"
            variant="h4"
            style={titleInfoBoxStyle.title}
          >
            {translate(title)}
          </Typography>
          <Typography style={titleInfoBoxStyle.info}>
            <span style={titleInfoBoxStyle.field}>{`${translate(
              'resources.recurrence.id'
            )}`}</span>
            <span style={titleInfoBoxStyle.value}>{` ${record.id ||
              translate('resources.recurrence.toDefine')}`}</span>
          </Typography>
        </div>
        <ScheduleInfoBox
          routeDurationHuman={record.routeDurationHuman}
          routeDistanceInKm={record.routeDistanceInKm}
        />
      </div>
    </>
  );
};

Header.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    routeDistanceInKm: PropTypes.number,
    routeDurationHuman: PropTypes.string
  }),
  title: PropTypes.string.isRequired
};

Header.defaultProps = {
  record: {}
};

export default Header;
