import React from 'react';
import ReactDOM from 'react-dom';
import { AmplifyConfigure } from '@loggi/authentication-lib';
import sentryInit from './sentry/sentry-init';
import App from './view';
import awsConfig from './config/awsconfig';
import { firebaseInit } from './firebase';

AmplifyConfigure(awsConfig);
firebaseInit();
sentryInit();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
