import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, regex, TextInput, useTranslate } from 'react-admin';

import DialogActions from '@material-ui/core/DialogActions';
import { useFormState } from 'react-final-form';
import { Box } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { dialogButtonMakeStyles } from '../../../theme/transport-planner-theme';

const LicensePlatesInputDialog = ({
  dialogOpen,
  licensePlates,
  name,
  source,
  placeholderDialog,
  closeDialog,
  onConfirm
}) => {
  const translationPath = `dialogs.input_dialog`;
  const specificTranslationPath = `dialogs.input_dialog.${source}`;
  const buttonStyles = dialogButtonMakeStyles();
  const translate = useTranslate();

  const [inputLicensePlate, setInputLicensePlate] = useState(
    licensePlates.licensePlate
  );
  const [inputAdditionalPlate, setInputAdditionalPlate] = useState(
    licensePlates.trailerLicensePlate
  );

  const validateRegex = regex(
    /^[A-z0-9]{7}$/,
    `${specificTranslationPath}.review_valid_to_continue`
  );

  const onSaveClick = () => {
    onConfirm(inputLicensePlate, inputAdditionalPlate);
  };

  const handleLicensePlate = event => {
    setInputLicensePlate(event.target.value);
  };

  const handleAdditionalPlate = event => {
    setInputAdditionalPlate(event.target.value);
  };

  return (
    <Dialog open={dialogOpen} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {translate(`${specificTranslationPath}.title`)}
      </DialogTitle>
      <DialogContent style={{ width: '33rem' }}>
        <Box>
          <TextInput
            label={name}
            source={source}
            variant="outlined"
            validate={validateRegex}
            value={licensePlates.licensePlate}
            onChange={handleLicensePlate}
            placeholder={placeholderDialog}
            data-testid={`${source}-input-dialog__dialog__input`}
            style={{ marginRight: '1rem', width: '16rem' }}
            InputProps={{
              disableUnderline: true
            }}
          />
          <TextInput
            label="resources.route.fields.trailerLicensePlate"
            source="trailerLicensePlate"
            variant="outlined"
            validate={validateRegex}
            value={licensePlates.trailerLicensePlate}
            onChange={handleAdditionalPlate}
            placeholder={placeholderDialog}
            data-testid="additionalPlate-input-dialog__dialog__input"
            style={{ width: '16rem' }}
            InputProps={{
              disableUnderline: true
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          className={`${buttonStyles}}`}
          onClick={() => closeDialog()}
          size="medium"
          label={`${translationPath}.sub_action`}
        />
        <Button
          className={`${buttonStyles}}`}
          variant="contained"
          size="medium"
          color="primary"
          label={`${translationPath}.action`}
          onClick={onSaveClick}
          disabled={useFormState().hasValidationErrors}
        />
      </DialogActions>
    </Dialog>
  );
};

export default LicensePlatesInputDialog;

LicensePlatesInputDialog.propTypes = {
  name: PropTypes.string,
  source: PropTypes.string,
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  licensePlates: PropTypes.shape({
    licensePlate: PropTypes.string,
    trailerLicensePlate: PropTypes.string
  }).isRequired,
  placeholderDialog: PropTypes.string
};

LicensePlatesInputDialog.defaultProps = {
  source: undefined,
  name: undefined,
  placeholderDialog: ''
};
