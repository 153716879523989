import React from 'react';
import { Admin, Resource, useGetIdentity } from 'react-admin';
import { hotjar } from 'react-hotjar';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import { ThemeProvider } from '@material-ui/core/styles';
import { createBrowserHistory as createHistory } from 'history';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import 'moment/locale/pt-br';
import TagManager from 'react-gtm-module';
import RoutePlanList from './templates/_react-admin/route-plan/route-plan-list';
import RoutePlanShow from './templates/_react-admin/route-plan/route-plan-show';
import transportPlannerAPI from '../api/transport-planner-api';
import theme from './theme/transport-planner-theme';
import customRoutes from '../custom-routes';
import portugueseMessages from '../config/portuguese-messages';
import RouteEdit from './templates/_react-admin/route/route-edit';
import authProvider, {
  hasRecurrenceReaderPermission
} from '../config/auth-provider';
import Login from './pages/login';
import PlanningList from './templates/_react-admin/planning';

const history = createHistory();
const i18nProvider = polyglotI18nProvider(() => portugueseMessages, 'pt-br');
const hotjarSiteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID);
const hotjarTrackerVersion = process.env.REACT_APP_HOTJAR_TRACKER_VERSION;
const gtmId = process.env.REACT_APP_GTM_ID;

if (hotjarSiteId) {
  hotjar.initialize(hotjarSiteId, hotjarTrackerVersion);
}

const App = () => {
  const userId = useGetIdentity();
  if (gtmId) {
    const tagManagerArgs = {
      gtmId,
      dataLayer: {
        userId
      }
    };
    TagManager.initialize(tagManagerArgs);
  }

  return (
    <ThemeProvider theme={theme}>
      <Admin
        dataProvider={transportPlannerAPI}
        initialState={{ admin: { ui: { sidebarOpen: false } } }}
        theme={theme}
        history={history}
        loginPage={Login}
        authProvider={authProvider}
        customRoutes={customRoutes}
        i18nProvider={i18nProvider}
      >
        {permissions => [
          !hasRecurrenceReaderPermission(permissions) && (
            <Resource
              name="transfer-plans"
              list={RoutePlanList}
              options={{ allowPickups: false }}
              show={RoutePlanShow}
              icon={LocalShippingIcon}
            />
          ),
          !hasRecurrenceReaderPermission(permissions) && (
            <Resource
              name="pickup-plans"
              list={RoutePlanList}
              options={{ allowPickups: true }}
              show={RoutePlanShow}
              icon={StoreMallDirectoryIcon}
            />
          ),
          hasRecurrenceReaderPermission(permissions) && (
            <Resource
              name="transfer-recurrences"
              list={PlanningList}
              icon={LocalShippingIcon}
              options={{ allowPickups: false }}
            />
          ),
          hasRecurrenceReaderPermission(permissions) && (
            <Resource
              name="pickup-recurrences"
              list={PlanningList}
              icon={StoreMallDirectoryIcon}
              options={{ allowPickups: true }}
            />
          ),
          <Resource name="route" edit={RouteEdit} />,
          <Resource name="facilities" />,
          <Resource name="carriers" />,
          <Resource name="vehiclesTypes" />,
          <Resource name="frequencies" />
        ]}
      </Admin>
    </ThemeProvider>
  );
};

export default App;
