const style = {
  main: {
    margin: 'auto 0 3.5rem',
    width: '100%'
  },
  left: {
    display: 'flex',
    float: 'left',
    alignItems: 'center'
  },
  right: {
    float: 'right'
  },
  title: {
    paddingInlineEnd: '1rem',
    paddingInlineStart: '0.5rem'
  }
};

export default style;
