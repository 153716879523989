import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormState } from 'react-final-form';
import { useTranslate } from 'react-admin';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';

const InformChangesDialog = ({ onConfirm, onClose }) => {
  const translate = useTranslate();
  const translationPath = 'dialogs.awareness.changes_done';

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const formState = useFormState();
  const isModified = Object.keys(formState.dirtyFields).length > 0;
  const [handleback, setHandleBack] = useState(false);

  const saveRoute = () => {
    setOpen(false);

    if (onConfirm) {
      onConfirm(formState.values);
    }
  };

  const discardChanges = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  useEffect(() => {
    history.block(() => {
      if (isModified && !formState.submitSucceeded) {
        setOpen(true);
        setHandleBack(true);
        return handleback;
      }
      return true;
    });
  }, [history, isModified, handleback, formState]);

  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle>{translate(`${translationPath}.title`)}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography component="span">
              <Box>
                {translate(`${translationPath}.first_info.start`)}
                <Box component="strong">{` ${translate(
                  `${translationPath}.first_info.end`
                )} `}</Box>
              </Box>
              <Box>&nbsp;</Box>
              <Box>
                {translate(`${translationPath}.second_info.start`)}
                <Box component="strong">{` ${translate(
                  'ra.action.save'
                ).toLocaleLowerCase()} `}</Box>
                {translate(`${translationPath}.second_info.end`)}
              </Box>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="medium"
            color="primary"
            icon={<div />}
            onClick={discardChanges}
            data-testid="not_save_changes_button"
          >
            {translate('ra.action.not_save_modifications')}
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={saveRoute}
            data-testid="save_changes_button"
          >
            {translate('ra.action.save_and_inclued')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
InformChangesDialog.propTypes = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
};

InformChangesDialog.defaultProps = {
  onConfirm: undefined,
  onClose: undefined
};

export default InformChangesDialog;
