import { makeStyles } from '@material-ui/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles({
  text: {
    fontFamily: 'Open Sans',
    color: colors.smoke[700],
    marginTop: 8,
    marginBottom: 8,
    maxWidth: 256
  },
  bold: {
    fontWeight: 600
  },
  root: {
    display: 'block',
    marginBottom: 8
  },
  link: {
    background: 'none!important',
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    border: 'none',
    color: colors.blue['500'],
    padding: '0!important',
    cursor: 'pointer'
  },
  transshipmentBox: {
    display: 'inline-flex'
  },
  inlineBlock: {
    display: 'inline-flex',
    marginRight: '1rem',
    '&  .Mui-disabled.Mui-disabled': {
      color: 'inherit'
    }
  },
  autocomplete: {
    '& > *': {
      marginBottom: 16
    }
  },
  dialog: {
    minWidth: 736
  }
});

export default useStyles;
