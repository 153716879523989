import { colors } from '@loggi/mar';

const style = {
  selectDayArea: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingBottom: '2rem',
    width: '100%'
  },
  dayGroupButton: {
    marginRight: '0.5rem'
  },
  baseDayButton: {
    borderRadius: '100%',
    marginRight: '0.5rem',
    height: '2.5rem',
    width: '2.5rem'
  },
  dayButtonNotSelected: {
    border: `0.125rem solid ${colors.smoke[100]}`,
    color: colors.smoke[800]
  },
  dayButtonSelected: {
    border: `0.125rem solid ${colors.blue[500]}`,
    background: colors.blue[500],
    color: colors.root[0]
  },
  dayButtonSelectedDisabled: {
    border: `0.125rem solid ${colors.smoke[500]}`,
    background: colors.smoke[500],
    color: colors.root[0]
  }
};

export default style;
