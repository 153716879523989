import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Edit, useNotify } from 'react-admin';
import queryString from 'query-string';

import RouteForm from './route-form';
import { pages } from '../../../../config/portuguese-messages';

export default function RouteEdit(props) {
  const history = useHistory();
  const notify = useNotify();

  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);
  const pendingReview = JSON.parse(parsedQueryString.toReview || false);

  const {
    match: {
      params: { routePlanId, routeId },
      path
    }
  } = props;

  function convertValuesToServer(data) {
    return {
      ...data,
      id: routeId,
      routePlanId
    };
  }

  const resource = path.split('/')[1];
  const returnToRoutePlan = useCallback(() => {
    notify(
      `ra.notification.${pendingReview ? 'included_in_schedule' : 'updated'}`,
      'success',
      { smart_count: 1 }
    );
    history.push(`/${resource}/${routePlanId}/show`);
  }, [history, pendingReview, notify, resource, routePlanId]);

  /* eslint-disable react/prop-types */
  const { to, staticContext, ...rest } = props;

  const customProps = {
    ...rest,
    id: `${routePlanId}%${routeId}`,
    basePath: `/${resource}/${routePlanId}/routes/`,
    resource: 'route'
  };

  return (
    <Edit
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...customProps}
      title={pages.editRoute}
      transform={convertValuesToServer}
      undoable={false}
      onSuccess={returnToRoutePlan}
    >
      <RouteForm
        convertValuesToServer={convertValuesToServer}
        routePlanId={routePlanId}
        routeId={routeId}
        parentResource={resource}
        reasonDialogContentKey="edit_route"
        sourceReasonId="updateReasonId"
        sourceReasonDescription="updateReasonDescription"
      />
    </Edit>
  );
}

RouteEdit.propTypes = {
  match: PropTypes.shape({
    params: {
      routePlanId: PropTypes.string.isRequired,
      routeId: PropTypes.string.isRequired
    },
    path: PropTypes.string.isRequired
  }).isRequired
};
