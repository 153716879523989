/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import { TextInput, usePermissions, useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { useRemoteConfig } from '@loggi/components/src/one/remote-config';
import { Box, Divider, Typography } from '@material-ui/core';
import { Map, WatchLater } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import routeFormStyles, { routeAlertStyles, totalBoxStyles } from '../styles';
import StateDot from '../../../../atoms/state-dot';
import { globalStyles } from '../../../../theme/transport-planner-theme';
import { convertUtcToBrz } from '../../../../../utils/date-format';
import {
  hasLicensePlateWritePermission,
  hasMerchandiseValueWritePermission,
  hasWritePermission
} from '../../../../../config/auth-provider';
import InformChangesDialog from '../../../../atoms/inform-changes-dialog';
import BackButton from '../../../../theme/back-button.component';
import SimilarRoutesInfo from '../../../../molecules/route/similar-routes-info';

const RouteFormModificationInfo = ({
  record,
  onClickDisplayChangelog = {}
}) => {
  const styles = routeFormStyles();
  const translate = useTranslate();
  const style = routeAlertStyles();

  return (
    <Box mx={2}>
      <Typography className={styles.typographyFontSize} component="span">
        <span className={styles.typographyBold}>
          {translate('resources.route.lastModifiedTime')}
        </span>
        {translate('resources.route.editedAndCreated', {
          date: record.lastModifiedDateFormatted,
          time: convertUtcToBrz(record.lastModifiedTime, 'HH:mm'),
          by: record.lastModifiedByUser
        })}
        {'| '}
      </Typography>

      <button
        className={`${styles.typographyFontSize} ${style.link}`}
        type="button"
        onClick={onClickDisplayChangelog}
      >
        {translate('resources.route.header_span_text.display_changelog')}
      </button>
    </Box>
  );
};

RouteFormModificationInfo.propTypes = {
  record: {
    arrivalTime: PropTypes.string.isRequired,
    latestArrivalTime: PropTypes.string,
    weekday: PropTypes.number,
    originArrivalTime: PropTypes.string,
    routePlanDate: PropTypes.string,
    state: PropTypes.string
  }.isRequired,
  onClickDisplayChangelog: PropTypes.func.isRequired
};

const RouteFormInfo = ({ state, externalId }) => {
  const styles = routeFormStyles();
  const projectStyles = globalStyles();
  const translate = useTranslate();

  return (
    <Box mr={2} className={styles.routeFormHeader}>
      <Typography
        component="h1"
        variant="h5"
        title={translate('resources.route.title')}
      >
        {translate('resources.route.title')}
      </Typography>
      <Box mr={2}>
        <p className={!state ? 'no-border' : null}>{`ID: ${
          !externalId ? translate('resources.route.toDefine') : externalId
        }`}</p>

        {state && (
          <StateDot
            state={state}
            boxClassName={`${projectStyles.stateDotBox}`}
          />
        )}
      </Box>
    </Box>
  );
};

RouteFormInfo.propTypes = {
  state: PropTypes.string,
  externalId: PropTypes.string
};

RouteFormInfo.defaultProps = {
  state: undefined,
  externalId: undefined
};

const MessageText = ({
  displayChangelog = false,
  text,
  onClickDisplayChangelog = {},
  limitedFormAccess,
  scrollTo
}) => {
  const style = routeAlertStyles();
  const styles = routeFormStyles();
  const translate = useTranslate();
  const { permissions } = usePermissions();

  const SinglePermissionButton = useCallback(
    field => (
      <button
        className={`${styles.typographyFontSizeHeaderBox} ${style.link}`}
        type="button"
        onClick={() => scrollTo(field)}
      >
        {translate(`resources.route.fields.${field}`)}
      </button>
    ),
    [style.link, styles.typographyFontSizeHeaderBox, scrollTo, translate]
  );

  const LimitedFormAccessBox = useCallback(
    content => (
      <Box>
        <span className={style.message}>
          {translate(
            'resources.route.header_span_text.limited_permissions.main'
          )}
          &nbsp;
        </span>
        {content}
      </Box>
    ),
    [translate, style.message]
  );

  if (limitedFormAccess) {
    if (
      hasLicensePlateWritePermission(permissions) &&
      !hasMerchandiseValueWritePermission(permissions)
    ) {
      return LimitedFormAccessBox(SinglePermissionButton('licensePlate'));
    }

    if (
      !hasLicensePlateWritePermission(permissions) &&
      hasMerchandiseValueWritePermission(permissions)
    ) {
      return LimitedFormAccessBox(SinglePermissionButton('merchandiseValue'));
    }

    const ContentPermission = (
      <>
        {SinglePermissionButton('licensePlate')}
        <span>&nbsp;</span>
        <span className={style.message}>
          {translate(
            'resources.route.header_span_text.limited_permissions.and'
          )}
          &nbsp;
        </span>
        {SinglePermissionButton('merchandiseValue')}
      </>
    );
    return LimitedFormAccessBox(ContentPermission);
  }

  if (displayChangelog) {
    return (
      <>
        <Box>
          <span className={style.message}>{translate(text)}</span>
        </Box>
        <Box>&nbsp;</Box>

        {typeof onClickDisplayChangelog === 'function' && (
          <button
            className={`${style.message} ${style.link}`}
            type="button"
            onClick={onClickDisplayChangelog}
          >
            {translate('resources.route.header_span_text.display_changelog')}
          </button>
        )}
      </>
    );
  }
  return (
    <Box>
      <span className={style.message}>{translate(text)}</span>
    </Box>
  );
};

MessageText.propTypes = {
  displayChangelog: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onClickDisplayChangelog: PropTypes.func.isRequired,
  limitedFormAccess: PropTypes.bool.isRequired,
  scrollTo: PropTypes.func.isRequired
};

const MessageHeader = ({
  routeState,
  onClickDisplayChangelog,
  limitedFormAccess,
  scrollTo
}) => {
  const style = routeAlertStyles();
  const translatePath = 'resources.route.header_span_text';

  const spanText = useMemo(() => {
    if (limitedFormAccess) {
      return <MessageText limitedFormAccess scrollTo={scrollTo} />;
    }

    switch (routeState) {
      case 'ROUTE_STATE_CANCELLED':
        return <MessageText text={`${translatePath}.canceled_edit_disabled`} />;

      case 'ROUTE_STATE_COMPLETED':
        return (
          <MessageText text={`${translatePath}.completed_edit_disabled`} />
        );

      case 'ROUTE_STATE_PENDING_REVIEW':
        return (
          <MessageText
            displayChangelog
            text={`${translatePath}.pending_review`}
            onClickDisplayChangelog={onClickDisplayChangelog}
          />
        );

      default:
        return <div />;
    }
  }, [routeState, onClickDisplayChangelog, limitedFormAccess, scrollTo]);

  const showMessageHeader = [
    'ROUTE_STATE_CANCELLED',
    'ROUTE_STATE_COMPLETED',
    'ROUTE_STATE_PENDING_REVIEW'
  ].includes(routeState);

  if (!showMessageHeader && !limitedFormAccess) return <div />;

  return (
    <Box mr={2} className={style.inlineBlock}>
      <ErrorIcon className={style.icon} fontSize="small" />
      {spanText}
    </Box>
  );
};

MessageHeader.propTypes = {
  routeState: PropTypes.string.isRequired,
  onClickDisplayChangelog: PropTypes.func.isRequired,
  limitedFormAccess: PropTypes.bool.isRequired,
  scrollTo: PropTypes.func.isRequired
};

const BoxItem = ({ icon, textFormat, description }) => {
  const style = totalBoxStyles();
  const translate = useTranslate();

  return (
    <Box className={style.boxItem}>
      {icon}
      <TextInput
        source={description}
        className={style.mainContent}
        format={textFormat}
      />
      <Typography component="caption" className={style.description}>
        {translate(`resources.route.fields.${description}`)}
      </Typography>
    </Box>
  );
};

BoxItem.propTypes = {
  icon: PropTypes.element.isRequired,
  textFormat: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired
};

const TotalDurationDistanceHeader = () => {
  const style = totalBoxStyles();
  const distanceFormat = value => (value ? `${value} Km` : '-- Km');
  const durationFormat = value => value || '-h -min';

  return (
    <Box className={style.box}>
      <BoxItem
        icon={<Map className={style.icon} fontSize="small" />}
        textFormat={distanceFormat}
        description="routeDistanceInKm"
      />
      <Divider orientation="vertical" flexItem className={style.divider} />
      <BoxItem
        icon={<WatchLater className={style.icon} fontSize="small" />}
        textFormat={durationFormat}
        description="routeDurationHuman"
      />
    </Box>
  );
};

const RouteFormHeader = ({
  state,
  externalId,
  record,
  routeState,
  onClickDisplayChangelog,
  onConfirm,
  scrollTo,
  allowPickups,
  routeId,
  routePlanId,
  requiresJustification
}) => {
  const styles = routeFormStyles();
  const { permissions } = usePermissions();
  const { value: fsValue } = useRemoteConfig('enable_inform_changes_dialog');
  const enableInformChangesDialog = JSON.parse(fsValue);

  const finishedState = [
    'ROUTE_STATE_CANCELLED',
    'ROUTE_STATE_COMPLETED'
  ].includes(routeState);
  const limitedFormAccess =
    !finishedState &&
    !hasWritePermission(permissions) &&
    (hasLicensePlateWritePermission(permissions) ||
      hasMerchandiseValueWritePermission(permissions));

  const history = useHistory();

  return (
    <>
      <Box mr={2} className={styles.boxStyle}>
        <Box mr={2} style={{ marginBottom: 20 }}>
          <BackButton onClick={history.goBack} />
          {enableInformChangesDialog && (
            <InformChangesDialog
              onConfirm={onConfirm}
              onClose={history.goBack}
            />
          )}
        </Box>

        {record.createdByUser && (
          <RouteFormModificationInfo
            record={record}
            onClickDisplayChangelog={onClickDisplayChangelog}
          />
        )}
      </Box>

      <MessageHeader
        routeState={routeState}
        onClickDisplayChangelog={
          record.createdByUser ? onClickDisplayChangelog : undefined
        }
        limitedFormAccess={limitedFormAccess}
        scrollTo={scrollTo}
      />

      {externalId && (
        <SimilarRoutesInfo
          routeId={routeId}
          routePlanId={routePlanId}
          allowPickups={allowPickups}
          requiresJustification={requiresJustification}
        />
      )}

      <Box className={styles.routeFormBox}>
        <RouteFormInfo state={state} externalId={externalId} />

        <TotalDurationDistanceHeader />
      </Box>
    </>
  );
};

RouteFormHeader.propTypes = {
  state: PropTypes.string,
  externalId: PropTypes.string,
  record: {
    arrivalTime: PropTypes.string.isRequired,
    latestArrivalTime: PropTypes.string,
    weekday: PropTypes.number,
    originArrivalTime: PropTypes.string,
    routePlanDate: PropTypes.string,
    state: PropTypes.string
  }.isRequired,
  routeState: PropTypes.string.isRequired,
  onClickDisplayChangelog: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  scrollTo: PropTypes.func.isRequired,
  allowPickups: PropTypes.bool.isRequired,
  routeId: PropTypes.string,
  routePlanId: PropTypes.string.isRequired,
  requiresJustification: PropTypes.bool
};

RouteFormHeader.defaultProps = {
  state: undefined,
  externalId: undefined,
  routeId: undefined,
  requiresJustification: false
};

export default RouteFormHeader;
