import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useTranslate,
  Button,
  Confirm,
  useMutation,
  useNotify
} from 'react-admin';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import ReasonDialog from '../reason-dialog';

const ArchiveRouteButton = ({
  selectedRoutes,
  isArchivable,
  showButtonIcon,
  onSuccess,
  routePlanId,
  variant,
  size,
  requiresJustification,
  allowPickups,
  archiveDisabled,
  classes,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const routeIds = selectedRoutes;
  const payload = {
    data: { routeIds, routePlanId, archive: isArchivable }
  };

  const [approve, { loading }] = useMutation();

  const archiveRoutes = values => {
    const finalPayload =
      Object.keys(values).length === 0
        ? payload
        : { data: { ...payload.data, ...values } };

    return approve(
      {
        type: 'archiveMany',
        resource: 'route',
        payload: finalPayload
      },
      {
        onSuccess: () => {
          setOpen(false);
          notify('ra.notification.updated', 'info', { smart_count: 1 });
          onSuccess();
        },
        onFailure: () => {
          setOpen(false);
          notify('ra.notification.http_error');
        }
      }
    );
  };

  const config = isArchivable
    ? {
        label: 'resources.route-plans.archive_route',
        reasonDialogContentKey: 'archive_route',
        sourceReasonId: 'archiveReasonId',
        sourceReasonDescription: 'archiveReasonDescription'
      }
    : {
        label: 'resources.route-plans.unarchive_route',
        reasonDialogContentKey: 'unarchive_route',
        sourceReasonId: 'unarchiveReasonId',
        sourceReasonDescription: 'unarchiveReasonDescription'
      };

  const Icon = isArchivable ? ArchiveOutlinedIcon : UnarchiveOutlinedIcon;

  const confirmDialogTitle = isArchivable
    ? translate('ra.message.archive_title', {
        smart_count: selectedRoutes.length
      })
    : translate('ra.message.unarchive_title', {
        smart_count: selectedRoutes.length
      });

  return (
    <>
      <Button
        label={config.label}
        disabled={archiveDisabled || selectedRoutes.length <= 0}
        onClick={() => setOpen(true)}
        startIcon={showButtonIcon && <Icon />}
        variant={variant}
        size={size}
        className={classes}
      />

      {requiresJustification ? (
        <ReasonDialog
          disabled={loading}
          isOpen={open}
          contentKey={config.reasonDialogContentKey}
          reference="reasons/create-route"
          onConfirm={archiveRoutes}
          onClose={() => setOpen(false)}
          sourceReasonId={config.sourceReasonId}
          sourceReasonDescription={config.sourceReasonDescription}
          isInsideRouteForm={false}
          filterValues={{ allowPickups }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      ) : (
        <Confirm
          isOpen={open}
          title={confirmDialogTitle}
          content=""
          onConfirm={() => archiveRoutes({})}
          onClose={() => setOpen(false)}
          CancelIcon={() => {
            return <div />;
          }}
          ConfirmIcon={() => {
            return <div />;
          }}
        />
      )}
    </>
  );
};

ArchiveRouteButton.propTypes = {
  selectedRoutes: PropTypes.arrayOf(PropTypes.string).isRequired,
  isArchivable: PropTypes.bool,
  showButtonIcon: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  routePlanId: PropTypes.string,
  variant: PropTypes.string,
  archiveDisabled: PropTypes.bool,
  requiresJustification: PropTypes.bool,
  size: PropTypes.string,
  allowPickups: PropTypes.bool,
  classes: PropTypes.string
};

ArchiveRouteButton.defaultProps = {
  isArchivable: true,
  showButtonIcon: false,
  routePlanId: undefined,
  variant: undefined,
  size: 'small',
  allowPickups: false,
  archiveDisabled: false,
  classes: undefined,
  requiresJustification: false
};

export default ArchiveRouteButton;
