import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';

const DeclareTextContent = ({ translationPath }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Typography component="span" className={classes.content}>
      <Box>{translate(`${translationPath}.confirmation_content.start`)}</Box>
      <Box>&nbsp;</Box>
      <Box>
        {translate(`${translationPath}.confirmation_content.middle`)}
        <Box component="strong">
          {translate(`${translationPath}.confirmation_content.day`)}
        </Box>
      </Box>
      <Box>{translate(`${translationPath}.confirmation_content.end`)}</Box>
      <Box>&nbsp;</Box>
    </Typography>
  );
};

DeclareTextContent.propTypes = {
  translationPath: PropTypes.string.isRequired
};

export default DeclareTextContent;
