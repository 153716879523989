import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  selectFrequencyContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  selectFrequencyTitle: {
    marginBottom: '8px',
    fontSize: '14px',
    fontFamily: 'Open Sans'
  }
});

export default useStyles;
