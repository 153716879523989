/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import {
  useDataProvider,
  useNotify,
  useTranslate,
  fetchStart,
  fetchEnd
} from 'react-admin';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import { Typography, Box } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import classNames from 'classnames';
import { globalStyles } from '../../../theme/transport-planner-theme';

const CalculateTimesButton = ({
  record,
  transform,
  disabled,
  label,
  allowPickups,
  updateMerchandiseValue,
  ...props
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const form = useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const style = globalStyles();
  const translate = useTranslate();

  const calculate = async () => {
    // defaultFields for `not required fields` on calculate action
    const defaultFields = {
      vehicleType: 'VUC',
      carrierId: '8230f9cb-b707-4a62-85aa-662af1a74509',
      frequency: { id: '22e9e9b9-8260-4e6d-b813-e9395534d8e4' }
    };
    const notRequiredFields = Object.keys(defaultFields);
    const formState = form.getState();
    let formErrors = formState.errors ? Object.keys(formState.errors) : [];
    // merge form values to defaultfields for calculate action
    const calculateValues = {
      ...formState.values,
      ...defaultFields
    };

    // remove not required fields from formErrors
    notRequiredFields.forEach(notRequired => {
      formErrors = formErrors.filter(item => item !== notRequired);
    });

    // if we have more errors, show warning
    if (formErrors.length) {
      notify('ra.message.invalid_form', 'warning');
      return;
    }

    const dataToCalculate = transform
      ? transform(calculateValues)
      : calculateValues;

    dispatch(fetchStart());
    setLoading(true);

    dataProvider
      .calculate({ data: dataToCalculate })
      .then(({ data }) => {
        // Update the inputs and fields data
        Object.assign(record, data);
        Object.entries(data).forEach(([key, value]) => {
          // don't change form's defaultFields with values from API
          if (!notRequiredFields.includes(key)) {
            form.change(key, value);
            if (updateMerchandiseValue && allowPickups) {
              updateMerchandiseValue(record.merchandiseValue);
            }
          }
        });

        notify('resources.route.calculationSuccess', 'success');
      })
      .catch(error => {
        notify(error.message, 'warning');
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  };

  const renderDisabled = loading || disabled;

  return (
    <Box
      className={classNames(
        style.boxTextLinkWithIcon,
        renderDisabled ? 'disabled' : ''
      )}
      style={{ width: '28rem' }}
      onClick={renderDisabled ? null : calculate}
    >
      <RefreshIcon />
      <Typography {...props} component="span" variant="span">
        {translate(`resources.route.${label}`)}
      </Typography>
    </Box>
  );
};

CalculateTimesButton.propTypes = {
  record: PropTypes.object,
  transform: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  allowPickups: PropTypes.bool,
  updateMerchandiseValue: PropTypes.func
};

CalculateTimesButton.defaultProps = {
  record: {},
  disabled: false
};

export default CalculateTimesButton;
