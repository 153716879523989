/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Filter, SelectInput, useTranslate } from 'react-admin';
import { REGIONS } from '../../../../../constants';

const RoutePlanFilter = props => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <SelectInput
        source="region"
        choices={REGIONS}
        allowEmpty={false}
        alwaysOn
        label={translate('resources.route-plans.regional')}
      />
    </Filter>
  );
};

export default RoutePlanFilter;
