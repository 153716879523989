import React from 'react';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer
} from '@material-ui/data-grid';

const CustomColumnToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
};

export default CustomColumnToolbar;
