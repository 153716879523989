import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, useMutation, useNotify } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import ReasonDialog from '../reason-dialog';

const CancelRouteButton = ({
  routePlanId,
  routeId,
  disabled,
  onSuccess,
  variant,
  label,
  classes,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const [mutate, { loading }] = useMutation();

  const cancelRoute = values => {
    const payload = {
      data: {
        routePlanId,
        routeId,
        ...values
      }
    };

    mutate(
      { type: 'cancel', resource: 'route', payload },
      {
        onSuccess: () => {
          setOpen(false);
          notify('resources.route.notification.cancelled', 'info');
          onSuccess();
        },
        onFailure: error => {
          notify(error.message, 'warning');
        }
      }
    );
  };

  return (
    <>
      <Button
        label={label}
        disabled={disabled}
        onClick={() => setOpen(true)}
        startIcon={<DeleteIcon />}
        variant={variant}
        size="small"
        className={classes}
      />

      <ReasonDialog
        disabled={loading}
        isOpen={open}
        contentKey="cancel_route"
        reference="reasons/cancel-route"
        onConfirm={cancelRoute}
        onClose={() => setOpen(false)}
        sourceReasonId="cancelReasonId"
        sourceReasonDescription="cancelReasonDescription"
        type="destructive"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </>
  );
};

CancelRouteButton.propTypes = {
  routePlanId: PropTypes.string.isRequired,
  routeId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  classes: PropTypes.string
};

CancelRouteButton.defaultProps = {
  variant: 'text',
  classes: undefined
};

export default CancelRouteButton;
