import { colors } from '@loggi/mar';

const style = {
  main: {
    margin: '4.5rem auto',
    minWidth: '90rem',
    display: 'grid'
  },
  tab: {
    minWidth: '14rem',
    minHeight: '4rem'
  },
  tabBoxContent: {
    border: `0.125rem solid ${colors.smoke[100]}`,
    minHeight: '20rem',
    background: colors.root[0]
  }
};

export default style;
