import React, { useMemo, useState } from 'react';
import { Button, useTranslate, useDataProvider, useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import ReasonDialog from '../reason-dialog/index';
import useStyles from '../reason-dialog/styles';
import { dialogButtonMakeStyles } from '../../theme/transport-planner-theme';
import { weekdays } from '../../../constants';

export const WarningDuplicationBox = () => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Box mr={2} className={classes.warningBox}>
      <Box>
        <ErrorIcon className={classes.warningIcon} fontSize="small" />
      </Box>
      <Box className={classes.warningTitle}>
        <span className={classes.warningLabel}>
          {translate('dialogs.mass_duplication.warning')}
        </span>
        <span className={classes.warningDescription}>
          {translate('dialogs.mass_duplication.warning_description')}
        </span>
      </Box>
    </Box>
  );
};

const MassDuplicationDialog = ({
  isOpen,
  onClose,
  onSuccess,
  startWeekday,
  selectedRoutes,
  routes,
  routePlanId,
  allowPickups
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const buttonStyles = dialogButtonMakeStyles();
  const translationPath = `dialogs.mass_duplication`;

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [weekday, setWeekday] = useState();
  const [error, setError] = useState('');

  const [openReason, setOpenReason] = useState(false);

  const availableWeekdays = weekdays.reduce((filter, wkday) => {
    if (wkday.id > startWeekday) filter.push(wkday.id);
    return filter;
  }, []);

  const weekdaysToShow = useMemo(
    () => weekdays.filter(day => availableWeekdays.includes(day.id)),
    [availableWeekdays]
  );

  const cloneMultiple = value => {
    const payload = {
      data: {
        routeIds: selectedRoutes,
        routePlanId,
        weekday,
        ...value
      }
    };

    dataProvider
      .duplicateMany('route', payload)
      .then(() => {
        notify('ra.notification.updated', 'info', {
          smart_count: selectedRoutes.length
        });
        if (onSuccess) onSuccess();
      })
      .catch(() => {
        notify('ra.notification.http_error');
      });
  };

  const handleChange = event => {
    setError('');
    const selectedWeekday = event.target.value;
    const isAnyRouteBeforeWeekday = selectedRoutes.reduce(
      (isBeforeWeekday, s) => {
        const selectedRouteWeekday =
          parseInt(routes.find(r => r.id === s).weekday, 10) + 1;
        return isBeforeWeekday || selectedRouteWeekday > selectedWeekday;
      },
      false
    );

    if (isAnyRouteBeforeWeekday) {
      setError(translate('ra.validation.selectValidTravelPlans'));
    } else {
      setWeekday(selectedWeekday);
    }
  };

  // Dulplicate => after insert the day
  const onDuplicateClick = () => {
    if (weekday) {
      onClose();
      setOpenReason(true);
      setWeekday(weekday);
    } else {
      setError(translate('ra.validation.selectDay'));
    }
  };

  // Confirm => after insert a reason
  const onConfirmClick = value => {
    setOpenReason(false);
    cloneMultiple(value);
  };

  const handleOnClose = () => {
    setWeekday(undefined);
    setError(undefined);
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {translate(`${translationPath}.title`)}
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText>
            {translate(`${translationPath}.content`)}
          </DialogContentText>

          <FormControl size="small" variant="outlined" fullWidth error={error}>
            <InputLabel id="label">
              {translate(`${translationPath}.select_label`)}
            </InputLabel>
            <Select
              data-testid="weekday"
              labelId="label"
              label={translate(`${translationPath}.select_label`)}
              value={weekday}
              onChange={handleChange}
              variant="outlined"
            >
              {weekdaysToShow.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
          <WarningDuplicationBox />
        </DialogContent>
        <DialogActions>
          <Button
            className={`${buttonStyles}}`}
            onClick={handleOnClose}
            size="medium"
            label={`${translationPath}.sub_action`}
            id="mass_duplication_sub_action"
          />
          <Button
            className={`${buttonStyles}}`}
            variant="contained"
            size="medium"
            color="primary"
            label={`${translate('dialogs.mass_duplication.action')} (${
              selectedRoutes.length
            })`}
            icon={<div />}
            onClick={onDuplicateClick}
            disabled={error !== undefined && error !== ''}
            id="mass_duplication_action"
          />
        </DialogActions>
      </Dialog>

      <ReasonDialog
        isOpen={openReason}
        contentKey="mass_duplication_reason"
        reference="reasons/create-route"
        onConfirm={onConfirmClick}
        onClose={() => setOpenReason(false)}
        sourceReasonId="duplicateReasonId"
        sourceReasonDescription="duplicateReasonDescription"
        isInsideRouteForm={false}
        filterValues={{ allowPickups }}
      />
    </>
  );
};

MassDuplicationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  startWeekday: PropTypes.number.isRequired,
  selectedRoutes: PropTypes.arrayOf(PropTypes.string).isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  routePlanId: PropTypes.string.isRequired,
  allowPickups: PropTypes.bool.isRequired
};

MassDuplicationDialog.defaultProps = {
  onSuccess: undefined
};

export default MassDuplicationDialog;
