/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback
} from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  usePermissions,
  useTranslate,
  ArrayInput
} from 'react-admin';
import { Divider, Typography } from '@material-ui/core';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useRemoteConfig } from '@loggi/components/src/one/remote-config';
import RouteFormHeader from './route-form-header';
import RouteFormFooterToolbar from './route-form-footer-toolbar';
import RouteFormAwarenessDialog from './route-form-awareness-dialog';
import routeFormStyles from '../styles';
import AutocompleteInputRemote from '../../../../atoms/autocomplete-input-remote';
import AutocompleteInput from '../../../../atoms/autocomplete-input';
import ArrivalTimeInput from '../../../../atoms/arrival-time-input';
import CalculateTimesButton from '../../../../molecules/route/calculate-times-button';
import CurrencyFormatInput from '../../../../atoms/currency-format-input';
import DockInput from '../../../../atoms/dock-input';
import ReasonDialog from '../../../../atoms/reason-dialog';
import ReturnRouteInput from '../../../../molecules/route/return-route-input';
import SelectFrequencyInput from '../../../../atoms/select-frequency-input';
import {
  hasWritePermission,
  hasLicensePlateWritePermission,
  hasMerchandiseValueWritePermission
} from '../../../../../config/auth-provider';
import {
  isValidTimeString,
  convertUtcToBrz,
  convertDateTimeToStringAtEndOfWeek,
  convertDateTimeToStringAtStartOfWeek,
  addDaysToDateTime,
  isTimeEqualOrAfter
} from '../../../../../utils/date-format';
import ChangelogSidebar from './route-form-changelog/route-form-changelog-sidebar';
import InputWithDialog from '../../../../atoms/input-with-dialog';
import portugueseMessages from '../../../../../config/portuguese-messages';
import DraggableFormIterator from '../../../../atoms/draggle-form-iterator/DraggableFormIterator';
import TransferDestinationInput from '../../../../molecules/route/transfer-destination-input';
import AdditionalInformationButton from '../../../../atoms/additional-information-button';
import LoadingOrderInfo from '../../../../molecules/route/loading-order-info';
import LicensePlatesInput from '../../../../molecules/route/license-plates-input';
import MerchandiseInputDialog from '../../../../molecules/route/merchandise-input-dialog';

const getShowAwarenessDialogStatus = (isFormDisabled, routeState) =>
  !isFormDisabled &&
  routeState === 'ROUTE_STATE_PENDING_REVIEW' &&
  (!localStorage.getItem('do-not-show-awareness-dialog') ||
    (localStorage.getItem('do-not-show-awareness-dialog') &&
      localStorage.getItem('do-not-show-awareness-dialog').includes('false')));

const getIsRecordEditable = record => !record.editable && record.state;
const getIsFormDisabled = (isRecordEditable, writePermission) =>
  !writePermission || isRecordEditable;
const getIsLicensePlateEditable = (record, licensePlateWritePermission) =>
  licensePlateWritePermission && record?.vehicle?.licensePlateEditable;
const getLicensePlateHideButton = (isRecordEditable, isLicensePlateEditable) =>
  !isLicensePlateEditable || isRecordEditable;
const getMerchandiseValueHideButton = (
  isRecordEditable,
  merchandiseValueWritePermission
) => !merchandiseValueWritePermission || isRecordEditable;
const getReasonDialogReference = reasonDialogContentKey =>
  reasonDialogContentKey === 'create_route'
    ? 'reasons/create-route'
    : 'reasons/update-route';
const getParsedRequiresJustification = parsedQueryString =>
  JSON.parse(parsedQueryString.requiresJustification || false);
const getMerchandiseValueInitialState = record =>
  record?.merchandiseValue || '';

export default function RouteForm(props) {
  const styles = routeFormStyles();
  const {
    record,
    convertValuesToServer,
    routePlanId,
    routeId,
    parentResource,
    reasonDialogContentKey,
    sourceReasonId,
    sourceReasonDescription,
    save
  } = props;
  const { arrivalTime, state: routeState } = record;
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);
  const allowPickups = JSON.parse(parsedQueryString.allowPickups);
  const requiresJustification = getParsedRequiresJustification(
    parsedQueryString
  );

  const translate = useTranslate();

  const { permissions } = usePermissions();

  const { value: fsValue } = useRemoteConfig('enable_loading_order');
  const enableLoadingOrder = JSON.parse(fsValue);

  const { value: licensePlatesFS } = useRemoteConfig('enable_license_plates');
  const enableLicensePlates = JSON.parse(licensePlatesFS);

  const [reasonDialogFields, setReasonDialogFields] = useState({});

  if (arrivalTime) {
    record.latestArrivalTime = convertUtcToBrz(arrivalTime);
  }

  const validateDuration = value =>
    !value || isValidTimeString(value) ? undefined : 'HH:mm';

  const validateTimeAfter = useCallback(
    value => {
      const minValueTransfer = '00:03';

      return !value || isTimeEqualOrAfter(value, minValueTransfer)
        ? undefined
        : translate('resources.route.fields.minValueTransfer', {
            min: minValueTransfer
          });
    },
    [translate]
  );

  const [docks, setDocks] = useState([]);
  const [facilities, setFacilities] = useState([]);

  const {
    record: { routePlanDate }
  } = props;

  const isRecordEditable = getIsRecordEditable(record);
  const isFormDisabled = getIsFormDisabled(
    isRecordEditable,
    hasWritePermission(permissions)
  );
  const isLicensePlateEditable = getIsLicensePlateEditable(
    record,
    hasLicensePlateWritePermission(permissions)
  );

  const saveRoute = value => {
    save({ ...reasonDialogFields, ...value });
  };

  const datesRange = useMemo(() => {
    const weekDay = routePlanDate
      ? `${routePlanDate}T00:00`
      : record.originArrivalTime;
    return {
      min: convertDateTimeToStringAtStartOfWeek(weekDay),
      max: convertDateTimeToStringAtEndOfWeek(weekDay)
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showAwarenessDialog = getShowAwarenessDialogStatus(
    isFormDisabled,
    routeState
  );
  const [openAwarenessDialog, setOpenAwarenessDialog] = useState(
    showAwarenessDialog
  );

  // Due to showAwarenessDialog loading time
  useEffect(() => setOpenAwarenessDialog(showAwarenessDialog), [
    showAwarenessDialog
  ]);

  const openAwarenessDialogActionClick = checked => {
    localStorage.setItem('do-not-show-awareness-dialog', checked);
    setOpenAwarenessDialog(false);
  };

  const [openChangelog, setOpenChangelog] = useState(false);
  const toogleChangelogVisibility = () => setOpenChangelog(!openChangelog);

  const merchandiseValueRef = useRef();
  const licensePlateRef = useRef();
  const scrollTo = view => {
    const scroll = ref => {
      if (ref && ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };
    if (view === 'merchandiseValue') scroll(merchandiseValueRef);
    if (view === 'licensePlate') scroll(licensePlateRef);
  };

  const [merchandiseValue, setMerchandiseValue] = useState(
    getMerchandiseValueInitialState(record)
  );

  const updateMerchandiseValue = value => {
    setMerchandiseValue(value);
  };

  return (
    <>
      {routeState && (
        <ChangelogSidebar
          isOpen={openChangelog}
          onClickCloseChangelog={toogleChangelogVisibility}
          record={record}
          routeId={routeId}
        />
      )}

      <RouteFormAwarenessDialog
        isOpen={openAwarenessDialog}
        type="review_route"
        onActionClick={openAwarenessDialogActionClick}
        onClickClose={() => setOpenAwarenessDialog(false)}
      />

      <SimpleForm
        {...props}
        variant="outlined"
        save={
          requiresJustification ? values => setReasonDialogFields(values) : save
        }
        toolbar={
          <RouteFormFooterToolbar
            isSaveDisabled={isFormDisabled}
            routePlanId={routePlanId}
            routeId={routeId}
            requiresJustification={requiresJustification}
            parentResource={parentResource}
            allowPickups={allowPickups}
          />
        }
        key={`form_${record?.lastModifiedTime || 0}`}
        submitOnEnter={false}
      >
        <RouteFormHeader
          state={record.state}
          externalId={record.externalId}
          routeState={routeState}
          onClickDisplayChangelog={toogleChangelogVisibility}
          scrollTo={scrollTo}
          onConfirm={
            requiresJustification
              ? values => setReasonDialogFields(values)
              : save
          }
          allowPickups={allowPickups}
          requiresJustification={requiresJustification}
          routeId={routeId}
          routePlanId={routePlanId}
        />

        {record.latestArrivalTime && (
          <TextInput
            style={{ display: 'none' }}
            source="latestArrivalTime"
            disabled={isFormDisabled}
          />
        )}
        <div />

        {/* Origin Divider */}
        <Typography
          component="h6"
          variant="h6"
          className={styles.groupTitleDivider}
        >
          {translate('resources.route.fields.origin.id')}
        </Typography>

        {/* Origin */}
        <AutocompleteInput
          source="origin.id"
          options={facilities}
          defaultValue="cc8e328f-87b9-6f60-4b37-b72a799e7603" // Cajamar
          optionText={choice => choice.longName}
          shouldRenderSuggestions={value => value.trim().length >= 2}
          validate={required()}
          filterValues={{ allowPickups }}
          onSelectedValue={facility => setDocks(facility.docks)}
          formClassName={styles.inlineBlock}
          className={styles.autocomplete}
          disabled={isFormDisabled}
        />

        {/* Dock */}
        <DockInput
          source="dock"
          docks={docks}
          formClassName={styles.inlineBlock}
          disabled={isFormDisabled}
        />
        <div />

        {/* Origin Arrival Time */}
        <ArrivalTimeInput
          source="originArrivalTime"
          disabled={isFormDisabled}
          defaultValue={`${routePlanDate}T00:00`}
          inputProps={datesRange}
          allowPickups={allowPickups}
          onFacilityUpdated={data => setFacilities(data)}
        />

        {/* Loading Start */}
        <TextInput
          source="loadingStartTime"
          type="datetime-local"
          formClassName={styles.inlineBlock}
          defaultValue={`${routePlanDate}T00:00`}
          disabled={isFormDisabled}
          inputProps={{
            ...datesRange,
            max: addDaysToDateTime(datesRange.max, 1)
          }}
        />

        {/* Loading Duration */}
        <TextInput
          source="loadingDuration"
          formClassName={styles.inlineBlock}
          defaultValue="00:30"
          validate={[required('HH:mm'), validateDuration]}
          style={{ width: 210 }}
          disabled={isFormDisabled}
        />

        {/* Loading End */}
        <TextInput
          source="loadingEndTimeFormatted"
          formClassName={styles.inlineBlock}
          disabled
        />

        {/* Documentation Time */}
        <TextInput
          source="releaseVehicleDuration"
          defaultValue="00:00"
          formClassName={styles.inlineBlock}
          validate={[required('HH:mm'), validateDuration]}
          style={{ width: 210 }}
          disabled={isFormDisabled}
        />

        {/* Origin Departure */}
        <TextInput
          source="originDepartureTimeFormatted"
          formClassName={styles.inlineBlock}
          disabled
        />

        {/* Transfers Divider */}
        <Divider
          className={`${styles.groupDivider} ${
            styles.groupDividerSmallerTopMargin
          }`}
        />
        <Typography
          component="h6"
          variant="h6"
          className={`${styles.groupTitleDivider} ${
            styles.groupTitleDividerNoMargin
          }`}
        >
          {translate('resources.route.fields.transfers')}
        </Typography>

        <ArrayInput source="transfers" label="">
          <DraggableFormIterator
            resetNextRowFields={[
              'travelDuration',
              'arrivalTimeFormatted',
              'departureTimeFormatted'
            ]}
            disabled={isFormDisabled}
            textAddNewItem="resources.route.new_destination"
          >
            {/* Destination */}
            <TransferDestinationInput
              source="destination"
              facilities={facilities}
              formClassName={styles.inlineBlock}
              className={styles.container}
              optionText={choice => choice.longName}
              shouldRenderSuggestions={value => value.trim().length >= 2}
              validate={required()}
              filterValues={{ allowPickups }}
              disabled={isFormDisabled}
              allowPickups={allowPickups}
            />

            {/* Travel Duration */}
            <TextInput
              source="travelDuration"
              formClassName={styles.inlineBlock}
              validate={[validateDuration, validateTimeAfter]}
              style={{ width: 150 }}
              disabled={isFormDisabled}
            />

            {/* Destination Arrival */}
            <TextInput
              source="arrivalTimeFormatted"
              formClassName={styles.inlineBlock}
              style={{ width: 180 }}
              disabled
            />

            {/* Unloading Duration */}
            <TextInput
              source="unloadingDuration"
              defaultValue="00:30"
              validate={[required('HH:mm'), validateDuration]}
              formClassName={styles.inlineBlock}
              style={{ width: 230 }}
              disabled={isFormDisabled}
            />

            {/* Destiantion Departure */}
            <TextInput
              source="departureTimeFormatted"
              formClassName={styles.inlineBlock}
              style={{ width: 180 }}
              disabled
            />
          </DraggableFormIterator>
        </ArrayInput>

        {/* Calculate Times Button */}
        <CalculateTimesButton
          source="routes"
          formClassName={styles.inlineBlock}
          transform={convertValuesToServer}
          disabled={isFormDisabled}
          label="calculate_times"
          allowPickups={allowPickups}
          updateMerchandiseValue={updateMerchandiseValue}
        />

        {/* Loading Order */}
        {enableLoadingOrder && !allowPickups && (
          <>
            <Divider className={styles.groupDividerSmallerTopMargin} />

            <LoadingOrderInfo
              source="loadingOrderSummary"
              disabled={isFormDisabled}
              convertValuesToServer={convertValuesToServer}
            />

            <AdditionalInformationButton
              record={record}
              disabled={isFormDisabled}
            />
          </>
        )}

        {/* Complementary Information Divider */}
        <Divider className={styles.groupDivider} />
        <Typography
          component="h6"
          variant="h6"
          className={styles.groupTitleDivider}
        >
          {translate('resources.route.complementary_information')}
        </Typography>

        {/* Frequency */}
        <SelectFrequencyInput
          source="frequency.id"
          disabled={isFormDisabled}
          formClassName={styles.inlineBlock}
          allowPickups={allowPickups}
        />

        {/* Vehicle */}
        <AutocompleteInputRemote
          source="vehicleType"
          reference="vehiclesTypes"
          formClassName={styles.inlineBlock}
          className={styles.container}
          optionText={choice => choice.name}
          shouldRenderSuggestions={value => value.trim().length >= 2}
          validate={required()}
          disabled={isFormDisabled}
        />

        {/* Carrier */}
        <AutocompleteInputRemote
          source="carrierId"
          reference="carriers"
          formClassName={styles.inlineBlock}
          className={styles.container}
          validate={required()}
          disabled={isFormDisabled}
          optionText={choice => choice.name}
          shouldRenderSuggestions={value => value.trim().length >= 2}
        />

        <div />
        {/* License Plate */}
        {record.state && enableLicensePlates && (
          <LicensePlatesInput
            name={translate('resources.route.fields.licensePlate')}
            routeId={routeId}
            routePlanId={routePlanId}
            source="licensePlate"
            placeholderValue={
              portugueseMessages.input_with_dialog.licensePlate.placeholder_text
            }
            placeholderDialog={
              portugueseMessages.input_with_dialog.licensePlate
                .placeholder_dialog
            }
            hideEditButton={getLicensePlateHideButton(
              isRecordEditable,
              isLicensePlateEditable
            )}
            hiddenEditButtonToolTip={
              isLicensePlateEditable ? null : 'licensePlateTooltip'
            }
            transformDataToUpperCase
            reference={licensePlateRef}
          />
        )}

        {record.state && !enableLicensePlates && (
          <InputWithDialog
            routeId={routeId}
            routePlanId={routePlanId}
            resource="licensePlate"
            name={translate('resources.route.fields.licensePlate')}
            formClassName={styles.inlineBlock}
            defaultValue={record?.vehicle?.licensePlate || ''}
            placeholderValue={
              portugueseMessages.input_with_dialog.licensePlate.placeholder_text
            }
            placeholderDialog={
              portugueseMessages.input_with_dialog.licensePlate
                .placeholder_dialog
            }
            hideEditButton={getLicensePlateHideButton(
              isRecordEditable,
              isLicensePlateEditable
            )}
            hiddenEditButtonToolTip={
              isLicensePlateEditable ? null : 'licensePlateTooltip'
            }
            validationPattern={
              portugueseMessages.dialogs.input_dialog.licensePlate
                .validation_pattern
            }
            transformDataToUpperCase
            reference={licensePlateRef}
          />
        )}

        {/* Merchandise Value */}

        {record.state && (
          <MerchandiseInputDialog
            routeId={routeId}
            routePlanId={routePlanId}
            resource="merchandiseValue"
            name={translate('resources.route.fields.merchandiseValue')}
            formClassName={styles.inlineBlock}
            defaultValue={merchandiseValue}
            placeholderValue={
              portugueseMessages.input_with_dialog.merchandiseValue
                .placeholder_text
            }
            placeholderDialog={
              portugueseMessages.input_with_dialog.merchandiseValue
                .placeholder_dialog
            }
            hideEditButton={getMerchandiseValueHideButton(
              isRecordEditable,
              hasMerchandiseValueWritePermission(permissions)
            )}
            validationPattern={
              portugueseMessages.dialogs.input_dialog.merchandiseValue
                .validation_pattern
            }
            reference={merchandiseValueRef}
            inputComponentType={CurrencyFormatInput}
            useConvertBetweenDecimalAndMoneyProto
            usePlaceholderDialogOrValue
            convertValuesToServer={convertValuesToServer}
          />
        )}

        <div />

        <ReturnRouteInput
          description="returnRouteDescription"
          source="returnRoute"
          allowPickups={allowPickups}
          disabled={isFormDisabled}
        />
      </SimpleForm>

      {Object.keys(reasonDialogFields).length > 0 && requiresJustification && (
        <ReasonDialog
          isOpen={!!reasonDialogFields}
          contentKey={reasonDialogContentKey}
          reference={getReasonDialogReference(reasonDialogContentKey)}
          filterValues={{ allowPickups }}
          onConfirm={saveRoute}
          onClose={() => setReasonDialogFields({})}
          sourceReasonId={sourceReasonId}
          sourceReasonDescription={sourceReasonDescription}
        />
      )}
    </>
  );
}

RouteForm.propTypes = {
  record: {
    arrivalTime: PropTypes.string.isRequired,
    latestArrivalTime: PropTypes.string,
    weekday: PropTypes.number,
    originArrivalTime: PropTypes.string,
    routePlanDate: PropTypes.string,
    state: PropTypes.string,
    requiresJustification: PropTypes.bool,
    loadingOrderSummary: PropTypes.string
  }.isRequired,
  convertValuesToServer: PropTypes.func.isRequired,
  routePlanId: PropTypes.string.isRequired,
  routeId: PropTypes.string,
  parentResource: PropTypes.string.isRequired,
  reasonDialogContentKey: PropTypes.string.isRequired,
  sourceReasonId: PropTypes.string.isRequired,
  sourceReasonDescription: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired
};

RouteForm.defaultProps = {
  routeId: ''
};
