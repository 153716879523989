/* eslint-disable react/forbid-prop-types */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { Box, Link as MuiLink, Typography, Chip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { recordPropType } from '../constants';
import { chipStyles, routePlanHeaderStyles } from '../styles';
import StateDot from '../../../../atoms/state-dot';
import LastModifiedTimeInfo from '../../../../atoms/last-modified-time-info';
import BackButton from '../../../../theme/back-button.component';
import { globalStyles } from '../../../../theme/transport-planner-theme';
import { formatDateProto } from '../../../../../utils/date-format';

const RoutePlanShowHeader = ({ record, resource }) => {
  const styles = routePlanHeaderStyles();
  const chipCellStyles = chipStyles();
  const projectStyles = globalStyles();
  const translate = useTranslate();
  const { goBack, push } = useHistory();

  const returnToRoutePlanList = () => {
    push({ pathname: `/${resource}` });
  };

  const renderCellWithTag = useCallback(
    type => {
      return (
        <Chip
          className={chipCellStyles[type]}
          label={translate(`resources.route-plans.${type}`)}
          size="small"
        />
      );
    },
    [chipCellStyles, translate]
  );

  return (
    <>
      <Box mr={2}>
        <BackButton onClick={returnToRoutePlanList} />
      </Box>
      <Box marginBottom={1} className={styles.header}>
        <Typography
          component="h1"
          variant="h5"
          title={translate(`resources.${resource}.backToRouterPlans`)}
          onClick={() => goBack()}
        >
          <MuiLink
            component="strong"
            color="inherit"
            style={{ cursor: 'pointer' }}
          >
            {`${translate('resources.route-plans.week')} ${formatDateProto(
              record.activationDate,
              'DD/MM/YYYY'
            )}`}
          </MuiLink>
        </Typography>

        <LastModifiedTimeInfo modifiedTime={record.lastModifiedTime} />
      </Box>

      <Box
        marginBottom={1}
        display="flex"
        alignItems="center"
        className={styles.weekStateBox}
      >
        <Typography component="h2" variant="subtitle1" color="textSecondary">
          {`${translate('resources.route-plans.fields.routeId')} ${
            record.externalId
          }`}
        </Typography>

        <Box mx={2}>
          <Typography component="span" variant="h6">
            {' | '}
          </Typography>
        </Box>

        <StateDot
          state={record.activationState}
          boxClassName={projectStyles.stateDotBox}
        />

        {record.showDeclaredLabel && renderCellWithTag('declared')}
      </Box>
    </>
  );
};

RoutePlanShowHeader.propTypes = {
  record: recordPropType.isRequired,
  resource: PropTypes.string.isRequired
};

export default RoutePlanShowHeader;
