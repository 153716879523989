// Gets facility Full Name with Route Code if it's defined
const getFacilityLongName = facility => {
  let name = '';

  if (facility.routingCode) {
    name = `${facility.routingCode} - ${facility.name}`;
  } else {
    name = facility.name;
  }

  return name;
};

// Gets facility Routing Code or Name
const getFacilityShortName = facility => {
  let name = '';

  if (facility.routingCode) {
    name = facility.routingCode;
  } else {
    name = facility.name;
  }

  return name;
};

const convertFromServer = facility => ({
  ...facility,
  longName: getFacilityLongName(facility),
  shortName: getFacilityShortName(facility),
  loadingOrder: facility.loadingOrder?.map(item => ({
    facility: convertFromServer(item.facility),
    mixed: item.mixed || false
  }))
});

export default {
  fromServer: data => {
    if (Array.isArray(data)) {
      return data.map(plan => convertFromServer(plan));
    }

    return convertFromServer(data);
  },
  toServer: facility => ({
    ...facility,
    externalId: facility.id.toString()
  })
};
