import { Button, useTranslate } from 'react-admin';
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {
  Box,
  FormControl,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';

import { Info } from '@material-ui/icons';
import CalculateTimesButton from '../calculate-times-button';
import styles from './styles';
import { dialogButtonMakeStyles } from '../../../theme/transport-planner-theme';

const MerchandiseDialog = ({
  isOpen,
  onClose,
  onConfirm,
  name,
  defaultValue,
  disabled,
  validationPattern,
  resource,
  inputComponentType,
  convertValuesToServer,
  ...props
}) => {
  const translate = useTranslate();
  const buttonStyles = dialogButtonMakeStyles();
  const translationPath = `dialogs.input_dialog`;
  const specificTranslationPath = `dialogs.input_dialog.${resource}`;

  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const handleChange = event => {
    setError(undefined);
    setInputValue(event.target.value);
  };

  const onSaveClick = () => {
    if (!inputValue) {
      setError(
        translate(`${specificTranslationPath}.insert_valid_to_continue`)
      );
      return;
    }
    if (validationPattern) {
      const pattern = new RegExp(validationPattern);
      if (!pattern.test(inputValue)) {
        setError(
          translate(`${specificTranslationPath}.review_valid_to_continue`)
        );
        return;
      }
    }
    onConfirm(inputValue);
  };

  const handleOnClose = () => {
    setError(undefined);
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="form-dialog-title"
        style={styles.dialog}
      >
        <DialogTitle id="form-dialog-title">
          {translate(`${specificTranslationPath}.title`)}
        </DialogTitle>
        <DialogContent>
          <FormControl size="small" variant="outlined" error={!!error}>
            <Box>
              <TextField
                style={styles.dialogInput}
                error={!!error}
                label={name}
                variant="outlined"
                value={inputValue}
                placeholder={defaultValue}
                disabled={disabled}
                onChange={handleChange}
                data-testid={`${resource}-input-dialog__dialog__input`}
                InputProps={{
                  inputComponent: inputComponentType
                }}
                autoFocus
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
              <Tooltip
                title={
                  <Typography component="div">
                    <Box>
                      {translate(
                        `dialogs.input_dialog.merchandiseValue.tooltip`
                      )}
                    </Box>
                  </Typography>
                }
                placement="top-start"
              >
                <Info
                  style={styles.infoIcon}
                  data-testid="merchandiseValue-infoIcon"
                />
              </Tooltip>
              {error && <FormHelperText>{error}</FormHelperText>}
            </Box>
            <Box>
              <CalculateTimesButton
                source="routes"
                transform={convertValuesToServer}
                disabled={disabled}
                label="update_value_based_on_spreadsheet"
              />
            </Box>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className={`${buttonStyles}}`}
            onClick={handleOnClose}
            size="medium"
            label={`${translationPath}.sub_action`}
          />
          <Button
            className={`${buttonStyles}}`}
            variant="contained"
            size="medium"
            color="primary"
            label={translate(`${translationPath}.action`)}
            icon={<div />}
            onClick={onSaveClick}
            disabled={(error !== undefined && error !== '') || disabled}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

MerchandiseDialog.propTypes = {
  name: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  validationPattern: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  inputComponentType: PropTypes.element.isRequired,
  convertValuesToServer: PropTypes.func.isRequired
};

MerchandiseDialog.defaultProps = {
  defaultValue: undefined,
  disabled: false
};

export default MerchandiseDialog;
