import React from 'react';
import { Signin } from '@loggi/authentication';
import { AmplifyAuthProvider } from '@loggi/authentication-lib';
import { SnackbarProvider } from 'notistack';
import useStyles from './styles';

const Login = () => {
  const classes = useStyles();
  return (
    <section className={classes.login}>
      <AmplifyAuthProvider>
        <SnackbarProvider
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top'
          }}
          autoHideDuration={6000}
          maxSnack={3}
        >
          <Signin
            showAppleSignIn={false}
            showCreateAccount={false}
            showForgotPassword={false}
          />
        </SnackbarProvider>
      </AmplifyAuthProvider>
    </section>
  );
};

export default Login;
