import { defaultRouteFilters } from '../../constants';

const defaultFiltersValues = ({ filters, record, startingDay }) => {
  const filtersModified = filters;
  Object.keys(filters).forEach(key => {
    const value = filtersModified[key];
    if (key === 'arrivalWeekdayName') {
      // don't reset the arrivalWeekdayName if there are more plans to be reviewed
      if (
        filters.state.length === 1 &&
        filters.state[0] === 'ROUTE_STATE_PENDING_REVIEW' &&
        record.totalRoutesPendingReview > 0
      ) {
        filtersModified.arrivalWeekdayName = value || [];
      } else {
        filtersModified.arrivalWeekdayName =
          value.length > 0 ? value : [startingDay.id];
      }
    } else if (
      key === 'state' &&
      value?.length === 1 &&
      value?.[0] === 'ROUTE_STATE_PENDING_REVIEW' &&
      record.totalRoutesPendingReview === 0
    ) {
      filtersModified.state = defaultRouteFilters.state;
    }
  });
  if (!filtersModified.arrivalWeekdayName) {
    filtersModified.arrivalWeekdayName = [startingDay.id];
  }
  return filtersModified;
};

export default defaultFiltersValues;
