import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import style from './style';
import { REGIONS } from '../../../../constants';

const HeaderToolbar = ({ name, onChangeFilter, defaultFilter }) => {
  const translate = useTranslate();

  return (
    <div style={style.main}>
      <div style={style.left}>
        <Typography component="h1" variant="h4" style={style.title}>
          {translate(`resources.${name}.name`, { smart_count: 2 })}
        </Typography>
        <Form
          // eslint-disable-next-line no-empty-function
          onSubmit={() => {
            // Intentional leaving this function empty since we won't have the default submit behavior
          }}
          render={() => (
            <SelectInput
              source="region"
              choices={REGIONS}
              allowEmpty={false}
              alwaysOn
              label="resources.route-plans.regional"
              defaultValue={defaultFilter}
              onChange={event => onChangeFilter(event.target.value)}
            />
          )}
        />
      </div>
    </div>
  );
};

HeaderToolbar.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  defaultFilter: PropTypes.string.isRequired
};

export default HeaderToolbar;
