import React, { useState } from 'react';
import { Button, useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import dropdownInputStyles from './styles';
import ArchiveRouteButton from '../../../atoms/archive-route-button';
import { recordPropType } from '../../../templates/_react-admin/route-plan/constants';
import CancelRouteButton from '../../../atoms/cancel-route-button';
import DeleteRouteButton from '../../../atoms/delete-route-button';
import CloneRouteButton from '../../../atoms/clone-route-button';
import { convertDateTimeToStringAtStartOfWeek } from '../../../../utils/date-format';

const RouteActionsMenu = ({
  title,
  record,
  routeId,
  routePlanId,
  parentResource,
  buttonSize,
  requiresJustification,
  allowPickups
}) => {
  const style = dropdownInputStyles();
  const translate = useTranslate();
  const { goBack } = useHistory();

  const IconMore = ExpandMoreIcon;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSuccess = () => {
    goBack();
  };

  return (
    <>
      <Button
        aria-controls="dropdown-menu"
        label={translate(title)}
        onClick={handleClick}
        endIcon={<IconMore className={style.icon} />}
        className={style.button}
        size={buttonSize}
      />
      <Menu
        id="dropdown-menu"
        keepMounted
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        classes={{ paper: style.modal }}
      >
        <MenuItem onClick={() => handleClose()}>
          <CloneRouteButton
            routes={[{ ...record, id: routeId }]}
            routePlanId={routePlanId}
            allowPickups={allowPickups}
            requiresJustification={requiresJustification}
            active={false}
            selectedRoutes={[routeId]}
            resource={parentResource}
            classes={style.archive}
            routePlanDate={convertDateTimeToStringAtStartOfWeek(
              record.arrivalTime,
              'YYYY-MM-DD'
            )}
          />
        </MenuItem>
        {(record.archivable || record.archived) && (
          <MenuItem onClick={() => handleClose()}>
            <ArchiveRouteButton
              onSuccess={onSuccess}
              selectedRoutes={[routeId]}
              requiresJustification={requiresJustification}
              classes={style.archive}
              routePlanId={routePlanId}
              isArchivable={record.archivable}
            />
          </MenuItem>
        )}

        {record.deletable && (
          <MenuItem onClick={() => handleClose()}>
            <DeleteRouteButton
              onSuccess={onSuccess}
              selectedRoutes={[routeId]}
              classes={style.delete}
              label={translate('resources.route.delete_route')}
              routePlanId={routePlanId}
            />
          </MenuItem>
        )}

        {record.cancelable && (
          <MenuItem onClick={() => handleClose()}>
            <CancelRouteButton
              onSuccess={onSuccess}
              selectedRoutes={[routeId]}
              classes={style.cancel}
              label={translate('resources.route.cancel_route')}
              routePlanId={routePlanId}
              routeId={routeId}
              parentResource={parentResource}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

RouteActionsMenu.propTypes = {
  title: PropTypes.string.isRequired,
  record: recordPropType.isRequired,
  routePlanId: PropTypes.string.isRequired,
  routeId: PropTypes.string.isRequired,
  parentResource: PropTypes.string.isRequired,
  buttonSize: PropTypes.string,
  allowPickups: PropTypes.bool.isRequired,
  requiresJustification: PropTypes.bool.isRequired
};

RouteActionsMenu.defaultProps = {
  buttonSize: 'small'
};

export default RouteActionsMenu;
