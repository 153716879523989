import React, { useEffect, useMemo, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Collapse, TextField, Button } from '@material-ui/core';
import {
  Add as AddIcon,
  Close as CloseIcon,
  HighlightOff as ClearIcon
} from '@material-ui/icons';
import PropTypes from 'prop-types';

import SelectOptions from '../../../../atoms/select-options-input';
import { defaultRouteFilters, recordPropType } from '../constants';
import {
  collapseStyles,
  multiAutocompleteStyle,
  routePlanShowStyles
} from '../styles';
import FilterAutocompleteInput from '../../../../atoms/filter-autocomplete-input';
import Api from '../../../../../api/transport-planner-api';
import {
  travelPlanStates,
  weekdays,
  travelPlanNotDeclaredStates
} from '../../../../../constants';

const ActionFilters = ({
  changeFilter,
  clearFilters,
  filters,
  record,
  closeFilter
}) => {
  const translate = useTranslate();
  const styles = routePlanShowStyles();
  const collapseClass = collapseStyles();
  const multiAutocompleteClasses = multiAutocompleteStyle();
  const [filtersOptions, setFiltersOptions] = useState({
    carriers: [],
    facilities: [],
    vehiclesTypes: [],
    frequencies: []
  });
  const [additionalFiltersOpen, setAdditionalFiltersOpen] = useState(false);

  const stateValues = useMemo(
    () =>
      travelPlanStates.map(state => ({
        id: state,
        name: translate(`state.${state}`)
      })),
    [translate]
  );

  const stateNotDeclaredValues = useMemo(
    () =>
      travelPlanNotDeclaredStates.map(state => ({
        id: state,
        name: translate(`state.${state}`)
      })),
    [translate]
  );

  // get all the filters options
  useEffect(() => {
    (async () => {
      const carriers = (await Api.getList('carriers', {})).data;
      const facilities = (await Api.getList('facilities', {
        filter: { allowPickups: record.allowPickups }
      })).data;
      const vehiclesTypes = (await Api.getList('vehiclesTypes', {})).data;
      const frequencies = (await Api.getList('frequencies', {
        filter: { allowPickups: record.allowPickups }
      })).data;
      setFiltersOptions({ carriers, facilities, vehiclesTypes, frequencies });
    })();
  }, [record.allowPickups, setFiltersOptions]);

  const returnRouteOptions = useMemo(
    () => [
      { id: '0', name: translate('ra.boolean.false') },
      { id: '1', name: translate('ra.boolean.true') }
    ],
    [translate]
  );

  const returnRouteLabel = record.allowPickups
    ? translate('resources.route-plans.fields.returnPickupRoute')
    : translate('resources.route-plans.fields.returnTransferRoute');

  const additionalFiltersSelected = useMemo(() => {
    let filtersSelectedCount = 0;
    if (filters.origin?.length > 0) filtersSelectedCount += 1;
    if (filters.transfer?.length > 0) filtersSelectedCount += 1;
    return filtersSelectedCount ? `(${filtersSelectedCount})` : undefined;
  }, [filters.origin, filters.transfer]);

  return (
    <div className={styles.allFiltersGrid}>
      <div className={styles.mainFiltersGrid}>
        <TextField
          id="id-filter"
          label={translate('resources.route-plans.fields.routeId')}
          variant="outlined"
          size="small"
          name="routeId"
          defaultValue={filters.routeId}
          onChange={value => changeFilter('routeId', value.target.value)}
          fullWidth
        />
        <SelectOptions
          label={translate('resources.route-plans.fields.originArrivalInfo')}
          name="arrivalWeekdayName"
          options={weekdays}
          onChange={changeFilter}
          defaultIdValue={filters.arrivalWeekdayName}
          optionTextProp="shortName"
          multiple
          closeFilter={closeFilter}
        />
        <FilterAutocompleteInput
          label={translate('resources.route-plans.fields.carrierName')}
          name="carrier"
          options={filtersOptions.carriers}
          onChange={changeFilter}
          defaultIdValue={filters.carrier}
        />
        <SelectOptions
          label={translate('resources.route-plans.fields.state')}
          name="state"
          options={record.declared ? stateValues : stateNotDeclaredValues}
          onChange={changeFilter}
          defaultIdValue={filters.state}
          multiple
        />
        <FilterAutocompleteInput
          label={translate('resources.route-plans.fields.vehicleType')}
          name="vehicleType"
          options={filtersOptions.vehiclesTypes}
          onChange={changeFilter}
          defaultIdValue={filters.vehicleType}
        />
        <SelectOptions
          label={translate('resources.route-plans.fields.frequency')}
          name="frequency"
          options={filtersOptions.frequencies}
          onChange={changeFilter}
          defaultIdValue={filters.frequency}
          multiple
        />
        <SelectOptions
          label={returnRouteLabel}
          name="returnRoute"
          options={returnRouteOptions}
          onChange={changeFilter}
          defaultIdValue={filters.returnRoute}
        />
        <div className={styles.moreFiltersBox}>
          <Button
            color="primary"
            onClick={() => setAdditionalFiltersOpen(!additionalFiltersOpen)}
            variant="text"
            size="small"
            className={
              additionalFiltersSelected ? styles.selectedFiltersButton : ''
            }
          >
            {additionalFiltersOpen ? (
              <CloseIcon
                fontSize="small"
                className={styles.moreFiltersButton}
              />
            ) : (
              <AddIcon className={styles.moreFiltersButton} />
            )}
            {`${translate(
              'resources.route-plans.filters'
            )} ${additionalFiltersSelected || ''}`}
          </Button>

          <Button
            color="primary"
            variant="text"
            size="small"
            onClick={clearFilters}
          >
            <ClearIcon className={styles.clearFiltersButton} />
            {translate('ra.action.clear')}
          </Button>
        </div>
      </div>
      <Collapse in={additionalFiltersOpen} classes={collapseClass}>
        <FilterAutocompleteInput
          label={translate('resources.route-plans.fields.origin')}
          name="origin"
          options={filtersOptions.facilities}
          onChange={changeFilter}
          classes={multiAutocompleteClasses}
          optionTextProp="longName"
          defaultIdValue={filters.origin}
          multiple
        />
        <FilterAutocompleteInput
          label={translate('resources.route-plans.fields.transfers')}
          name="transfer"
          options={filtersOptions.facilities}
          onChange={changeFilter}
          optionTextProp="longName"
          defaultIdValue={filters.transfer}
          classes={multiAutocompleteClasses}
          multiple
        />
      </Collapse>
    </div>
  );
};

ActionFilters.propTypes = {
  changeFilter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    routeId: PropTypes.string,
    arrivalWeekdayName: PropTypes.arrayOf(PropTypes.number).isRequired,
    origin: PropTypes.arrayOf(PropTypes.string),
    transfer: PropTypes.arrayOf(PropTypes.string),
    carrier: PropTypes.string,
    state: PropTypes.arrayOf(PropTypes.string),
    vehicleType: PropTypes.string,
    frequency: PropTypes.arrayOf(PropTypes.string),
    returnRoute: PropTypes.string
  }),
  record: recordPropType.isRequired,
  closeFilter: PropTypes.func.isRequired
};

ActionFilters.defaultProps = {
  filters: {
    defaultRouteFilters
  }
};

export default ActionFilters;
