/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import style from '../style';

const TabSchedules = props => {
  return (
    <div {...props} style={style.tabBoxContent} data-testid="tab-schedules" />
  );
};

export default TabSchedules;
