import React, { useEffect, useState } from 'react';
import { useDataProvider, useTranslate, Loading } from 'react-admin';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { useRemoteConfig } from '@loggi/components/src/one/remote-config';
import { Box, Typography, Divider } from '@material-ui/core';
import SelectOptions from '../../../atoms/select-options-input';

const SimilarRoutesInfo = ({
  routeId,
  routePlanId,
  allowPickups,
  requiresJustification
}) => {
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const history = useHistory();

  const [similarRoutesInfo, setSimilarRoutesInfo] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { value: fsValue } = useRemoteConfig('enable_similar_routes_info');
  const enableSimilarRoutesInfo = JSON.parse(fsValue);
  const showSimilarRoutesInfo =
    enableSimilarRoutesInfo && similarRoutesInfo && similarRoutesInfo.total > 0;

  const translationPath = 'resources.route';

  const onChangeSelectOptions = (field, value) => {
    const scheduling = similarRoutesInfo.data.find(r => r[field] === value);

    return history.push(
      `${
        scheduling.id
      }?&allowPickups=${allowPickups}&requiresJustification=${requiresJustification}&pendingReview=${
        scheduling.pendingReview
      }`
    );
  };

  useEffect(() => {
    if (enableSimilarRoutesInfo && !error && !loading && !similarRoutesInfo) {
      setLoading(true);
      const payload = { data: { routeId, routePlanId } };
      dataProvider
        .getRoutes('similarRoutes', payload)
        .then(result => setSimilarRoutesInfo(result))
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  }, [
    enableSimilarRoutesInfo,
    error,
    loading,
    similarRoutesInfo,
    routeId,
    routePlanId,
    dataProvider
  ]);

  if (loading) return <Loading />;
  if (!showSimilarRoutesInfo || error) return null;

  return (
    <div style={{ marginRight: '1rem' }}>
      <Typography
        component="h1"
        variant="h4"
        style={{ fontWeight: 700, marginBottom: '1.5rem' }}
      >
        {translate(`${translationPath}.similarRoutesInfo.title`)}
      </Typography>
      <Box
        style={{
          background: colors.smoke[50],
          borderRadius: '0.5rem',
          padding: '2rem',
          marginBottom: '2.5rem',
          lineHeight: '2rem'
        }}
      >
        <Typography
          component="span"
          style={{ fontWeight: 700, fontSize: '1.125rem' }}
        >
          {translate(`${translationPath}.similarRoutesInfo.boxTitle`)}
        </Typography>
        <br />
        <Typography component="span" style={{ fontSize: '0.875rem' }}>
          {translate(`${translationPath}.similarRoutesInfo.boxContentLine1`)}
          <br />
          {translate(`${translationPath}.similarRoutesInfo.boxContentLine2`)}
        </Typography>
      </Box>
      <Typography
        component="span"
        style={{ fontSize: '0.875rem', fontWeight: 700 }}
      >
        {translate(`${translationPath}.similarRoutesInfo.selectorText`)}
      </Typography>
      <Box style={{ width: '16rem', marginBottom: '1rem', marginTop: '1rem' }}>
        <SelectOptions
          label={translate(`${translationPath}.fields.weekday`)}
          name="id"
          options={similarRoutesInfo.data}
          onChange={onChangeSelectOptions}
          optionTextProp="arrivalWeekdayFullName"
        />
      </Box>
      <Divider
        style={{ backgroundColor: colors.smoke[200], marginTop: '2.5rem' }}
      />
    </div>
  );
};

SimilarRoutesInfo.propTypes = {
  routeId: PropTypes.string.isRequired,
  routePlanId: PropTypes.string.isRequired,
  allowPickups: PropTypes.bool.isRequired,
  requiresJustification: PropTypes.bool.isRequired
};

export default SimilarRoutesInfo;
