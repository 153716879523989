import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextInput, useMutation } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Chip, Grid } from '@material-ui/core';
import moment from 'moment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { colors } from '@loggi/mar';
import { convertBrzToUtc } from '../../../utils/date-format';

const ArrivalTimeInput = props => {
  const {
    source,
    defaultValue,
    allowPickups,
    onFacilityUpdated,
    ...otherProps
  } = props;
  const selectedValue = useFormState().values[source] || defaultValue;

  const [fetchFacilities] = useMutation(
    {
      type: 'getList',
      resource: 'facilities',
      payload: {
        filter: {
          loadingOrderDate: convertBrzToUtc(selectedValue),
          allowPickups
        }
      }
    },
    {
      onSuccess: ({ data }) => {
        if (onFacilityUpdated) {
          onFacilityUpdated(data);
        }
      }
    }
  );

  useEffect(() => {
    fetchFacilities();
  }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={1}>
      <Grid item style={{ marginTop: 8 }}>
        <Chip
          size="medium"
          icon={<EventAvailableIcon />}
          style={{
            backgroundColor: colors.smoke['50']
          }}
          label={selectedValue ? moment(selectedValue).format('ddd') : ''}
        />
      </Grid>
      <Grid item>
        <TextInput
          source={source}
          type="datetime-local"
          defaultValue={defaultValue}
          onBlur={fetchFacilities} //
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      </Grid>
    </Grid>
  );
};

ArrivalTimeInput.propTypes = {
  source: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  allowPickups: PropTypes.bool.isRequired,
  onFacilityUpdated: PropTypes.func
};

ArrivalTimeInput.defaultProps = {
  onFacilityUpdated: undefined
};

export default ArrivalTimeInput;
