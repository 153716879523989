import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Create, useNotify } from 'react-admin';
import RouteForm from './route-form';
import { pages } from '../../../../config/portuguese-messages';

export default function RouteCreate(props) {
  const history = useHistory();
  const notify = useNotify();
  const {
    match: {
      params: { routePlanId },
      path
    }
  } = props;

  function convertValuesToServer(data) {
    return {
      ...data,
      routePlanId
    };
  }

  const resource = path.split('/')[1];
  const returnToRoutePlan = () => {
    notify('ra.notification.created', 'success', { smart_count: 1 });
    history.push(`/${resource}/${routePlanId}/show`);
  };

  const customProps = {
    ...props,
    basePath: `/create`,
    resource: 'route'
  };

  return (
    <Create
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...customProps}
      title={pages.createRoute}
      transform={convertValuesToServer}
      onSuccess={returnToRoutePlan}
    >
      <RouteForm
        convertValuesToServer={convertValuesToServer}
        routePlanId={routePlanId}
        parentResource={resource}
        reasonDialogContentKey="create_route"
        sourceReasonId="createReasonId"
        sourceReasonDescription="createReasonDescription"
      />
    </Create>
  );
}

RouteCreate.propTypes = {
  match: PropTypes.shape({
    params: {
      routePlanId: PropTypes.string.isRequired
    },
    path: PropTypes.string.isRequired
  }).isRequired
};
