import * as Sentry from '@sentry/react';
import sentryConfig from './sentry-config';

const sentryInit = () => {
  if (process.env.REACT_APP_ENV_SENTRY) {
    Sentry.init(sentryConfig);
  }
};

export default sentryInit;
