/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useQueryWithStore, Loading, Error, SelectInput } from 'react-admin';
import PropTypes from 'prop-types';

const SelectInputRemote = ({
  source,
  reference,
  optionText,
  optionValue,
  filterValues,
  defaultValueFunction,
  ...props
}) => {
  const payload = { filter: filterValues };
  const { loaded, error, data } = useQueryWithStore({
    type: 'getList',
    resource: reference,
    payload
  });

  if (!loaded) return <Loading />;
  if (error) return <Error />;

  const defaultValue = defaultValueFunction
    ? defaultValueFunction(data)
    : undefined;

  return (
    <SelectInput
      source={source}
      choices={data}
      optionValue={optionValue}
      optionText={optionText}
      initialValue={defaultValue}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

SelectInputRemote.propTypes = {
  source: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  optionText: PropTypes.string,
  optionValue: PropTypes.string,
  filterValues: PropTypes.object,
  defaultValueFunction: PropTypes.func
};

SelectInputRemote.defaultProps = {
  optionText: 'name',
  optionValue: 'id',
  filterValues: {},
  defaultValueFunction: undefined
};

export default SelectInputRemote;
