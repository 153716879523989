/* eslint-disable react/jsx-props-no-spreading, react/forbid-prop-types */
import React, { useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { ValidationError } from 'ra-core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslate } from 'react-admin';
import DraggableFormInput from './DraggableFormInput';
import { useStyles } from './styles';
import { globalStyles } from '../../theme/transport-planner-theme';

const sanitizeProps = ({ classes, ...props }) => props;

const DraggableFormIterator = React.forwardRef(
  (
    { fields, resetNextRowFields, meta: { error, submitFailed }, ...props },
    ref
  ) => {
    const classes = useStyles();
    const projectStyles = globalStyles();
    const translate = useTranslate();

    // we need to keep the nextId and a list of assigned ids(string)
    const [ids, setIds] = useState(fields.map((_, index) => `${index + 1}`));
    const [nextId, setNextId] = useState(ids.length + 1);

    const removeField = index => () => {
      setIds(ids.filter((_, idx) => idx !== index));
      fields.remove(index);
    };

    const addField = () => {
      setIds(idsValue => [...idsValue, `${nextId}`]);
      setNextId(nextId + 1);
      fields.push({});
    };

    const onDragEnd = result => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const startIndex = result.source.index;
      const endIndex = result.destination.index;

      // dropped at same location
      if (startIndex === endIndex) {
        return;
      }

      const idsArray = [...ids];
      const [removed] = idsArray.splice(startIndex, 1);
      idsArray.splice(endIndex, 0, removed);
      setIds(idsArray);

      // if we move to the end of the list, there is no next line to update
      if (endIndex < fields.value.length - 1) {
        let finalIndex = endIndex;

        // if we move from top to bottom, it needs to increment the index.
        if (startIndex < endIndex) {
          finalIndex += 1;
        }
        // clear the next row fields.
        resetNextRowFields.forEach(field => {
          fields.value[finalIndex][field] = ''; // eslint-disable-line no-param-reassign
        });
      }
      // clear the current row fields.
      resetNextRowFields.forEach(field => {
        fields.value[startIndex][field] = ''; // eslint-disable-line no-param-reassign
      });
      fields.move(startIndex, endIndex);
    };

    useImperativeHandle(ref, () => ({
      onDragEndRef: result => {
        onDragEnd(result);
      }
    }));

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <>
              <ul className={classes.root} ref={provided.innerRef}>
                {submitFailed && typeof error !== 'object' && error && (
                  <FormHelperText error>
                    <ValidationError error={error} />
                  </FormHelperText>
                )}
                <TransitionGroup>
                  {fields.map((member, index) => (
                    <CSSTransition
                      key={ids[index]}
                      timeout={500}
                      classNames="fade"
                    >
                      <DraggableFormInput
                        id={ids[index]}
                        index={index}
                        member={member}
                        onRemove={removeField}
                        {...sanitizeProps(props)}
                      />
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </ul>
              <div className={classes.actionsToolbar}>
                <Button
                  color="primary"
                  className={projectStyles.addButtonWithIcon}
                  variant="outlined"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={addField}
                >
                  {translate(props.textAddNewItem)}
                </Button>
              </div>
            </>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

DraggableFormIterator.propTypes = {
  children: PropTypes.node.isRequired,
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  resetNextRowFields: PropTypes.arrayOf(PropTypes.string),
  textAddNewItem: PropTypes.string
};

DraggableFormIterator.defaultProps = {
  resetNextRowFields: [],
  textAddNewItem: 'ra.action.add'
};

export default DraggableFormIterator;
