import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, maxLength, TextInput, useTranslate } from 'react-admin';
import CommentIcon from '@material-ui/icons/Comment';
import { Box, Typography } from '@material-ui/core';

import additionalInformationStyle from './styles';

const AdditionalInformationButton = ({ record, disabled }) => {
  const styles = additionalInformationStyle();
  const translate = useTranslate();

  const [openObservations, setOpenObservations] = useState(false);
  const [observations, setObservations] = useState(
    record.additionalInformation
  );
  const [countWords, setCountWords] = useState(0);

  useEffect(() => {
    if (observations !== undefined) {
      setOpenObservations(true);
    }
  }, [observations]);

  const handleTextarea = event => {
    setObservations(event.target.value);
    setCountWords(event.target.value.length);
  };

  const validateObservations = [maxLength(140)];

  return (
    <Box className={styles.container}>
      {!openObservations && !disabled ? (
        <Button
          startIcon={<CommentIcon fontSize="default" color="primary" />}
          className={styles.observationsButton}
          size="small"
          label="resources.route.additionalInformation.addObservations"
          onClick={() => setOpenObservations(!openObservations)}
        />
      ) : (
        <Box className={styles.textareaContainer}>
          <TextInput
            multiline
            className={styles.textarea}
            source="additionalInformation"
            onChange={value => handleTextarea(value)}
            defaultValue={observations}
            disabled={disabled}
            label=""
            rowsMax={5}
            placeholder={translate(
              'resources.route.additionalInformation.addExtraInfo'
            )}
            InputProps={{ disableUnderline: true, className: styles.textInput }}
            validate={validateObservations}
          />
          <Box className={styles.containerTextareaNote}>
            <Typography
              variant="body1"
              className={styles.textareaNote}
              paragraph={false}
              noWrap
            >
              <Box
                dangerouslySetInnerHTML={{
                  __html: translate(
                    'resources.route.additionalInformation.observationsNote'
                  )
                }}
              />
            </Typography>

            <Typography
              className={styles.textareaNote}
              paragraph={false}
              noWrap
            >
              {countWords}{' '}
              {translate('resources.route.additionalInformation.maxCharacters')}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

AdditionalInformationButton.propTypes = {
  record: PropTypes.shape({
    additionalInformation: PropTypes.string,
    editable: PropTypes.bool
  }).isRequired,
  disabled: PropTypes.bool.isRequired
};

export default AdditionalInformationButton;
