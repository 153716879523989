import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { backButtonStyle } from './transport-planner-theme';

const BackButton = ({ navigationPath, onClick }) => {
  const translate = useTranslate();

  return (
    <>
      <div style={backButtonStyle.root}>
        <ArrowBack
          onClick={onClick}
          style={backButtonStyle.icon}
          data-testid="back-button-icon"
        />
        {navigationPath && (
          <Typography style={backButtonStyle.text}>
            {translate(`ra.navigation.${navigationPath}`)}
          </Typography>
        )}
      </div>
    </>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  navigationPath: PropTypes.string
};

BackButton.defaultProps = {
  navigationPath: undefined
};

export default BackButton;
