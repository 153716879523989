const filterRoutesById = (routes, routeId) =>
  routes.filter(route =>
    routeId
      ? route.externalId?.startsWith(routeId.trim()) ||
        route.infologId?.startsWith(routeId.trim())
      : true
  );

const filterRoutesByOrigin = (routes, origin) =>
  routes.filter(route =>
    origin.length > 0 ? origin.includes(route.origin.id) : true
  );

const filterRoutesByTransfer = (routes, transfer) =>
  routes.filter(route =>
    transfer.length > 0
      ? route.transfers.some(item => transfer.includes(item.destination.id))
      : true
  );

const filterRoutesByCarrier = (routes, carrier) =>
  routes.filter(route => (carrier ? route.carrierId === carrier : true));

const filterRoutesByState = (routes, state) =>
  routes.filter(route =>
    state.length > 0 ? state.includes(route.state) : true
  );

const filterRoutesByVehicleType = (routes, vehicleType) =>
  routes.filter(route =>
    vehicleType ? route.vehicleType === vehicleType : true
  );

const filterRoutesByFrequency = (routes, frequency) =>
  routes.filter(route =>
    frequency.length > 0 ? frequency.includes(route.frequency?.id) : true
  );

const filterRoutesByReturnRoute = (routes, returnRoute) =>
  routes.filter(route => {
    switch (returnRoute) {
      case '1':
        return route.returnRoute;
      case '0':
        return !route.returnRoute;
      default:
        return true;
    }
  });

const filterRoutes = ({ routes, filters }) => {
  const routesFilteredById = filterRoutesById(routes, filters.routeId);
  const routesFilteredByOrigin = filterRoutesByOrigin(
    routesFilteredById,
    filters.origin
  );
  const routesFilteredByTransfer = filterRoutesByTransfer(
    routesFilteredByOrigin,
    filters.transfer
  );
  const routesFilteredByCarrier = filterRoutesByCarrier(
    routesFilteredByTransfer,
    filters.carrier
  );
  const routesFilteredByState = filterRoutesByState(
    routesFilteredByCarrier,
    filters.state
  );
  const routesFilteredByFrequency = filterRoutesByFrequency(
    routesFilteredByState,
    filters.frequency
  );
  const routesFilteredByVehicleType = filterRoutesByVehicleType(
    routesFilteredByFrequency,
    filters.vehicleType
  );
  return filterRoutesByReturnRoute(
    routesFilteredByVehicleType,
    filters.returnRoute
  );
};

export default filterRoutes;
