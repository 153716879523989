import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const useStyles = makeStyles(() => ({
  dialog: {
    '& .description-input': {
      marginTop: '0px',
      marginBottom: '22px!important'
    }
  },
  content: {
    color: colors.smoke[700]
  }
}));

export default useStyles;
