import { colors } from '@loggi/mar';

const style = {
  root: {
    paddingTop: '4rem',
    display: 'flex',
    justifyContent: 'space-between'
  },
  formMain: {
    padding: '2rem'
  },
  header: {
    width: '100%',
    paddingTop: '4rem',
    display: 'flex',
    justifyContent: 'space-between'
  },
  recurrenceArea: {
    paddingTop: '3.5rem',
    width: '100%'
  },
  recurrenceTitle: {
    fontWeight: 700,
    paddingBottom: '2.5rem'
  }
};

export const titleInfoBoxStyle = {
  title: {
    fontWeight: 700
  },
  info: {
    margin: '0.5rem 0',
    fontSize: '0.875rem',
    fontWeight: 600
  },
  field: {
    color: colors.smoke[900]
  },
  value: {
    color: colors.smoke[700]
  }
};

export default style;
